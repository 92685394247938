import React from "react";
import Tooltip from '@mui/material/Tooltip';

export interface DisabledItemProps {
    className?: string;
    title?: string;
    children: React.ReactElement;
  }
  
export default function (props: DisabledItemProps) {
    const { children, title, className } = props;

    const disableClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
    }

    return (
        <Tooltip title={title || "Coming soon"}>
            <div className={`${className || ''} flex opacity-50 bg-[#FFFFFF] cursor-pointer`} onClick={disableClick}>
                {children && children}
            </div>
        </Tooltip>
    );
}
