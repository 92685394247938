import Icon from '@mui/material/Icon';
import Home from 'pages/home';
import Admin from 'pages/admin';
import OnBoard from 'pages/onboard';
import CoreTeam from 'pages/core_team';
import Project from 'pages/project';
import Skill from 'pages/skill';
import Role from 'pages/role';
import CreateRole from 'pages/role/Role';
import NewAppWrapper from 'new-app/wrapper';
import ElasticTalentFinder from 'new-app/pages/elasticTalentFinder';
import ElasticTalentFinderPlus from 'new-app/pages/elasticTalentFinderPlus';
import ExchangeRate from 'new-app/pages/exchangeRate';
import ManageAssessements from 'new-app/pages/manageAssessements';
import UserProfileEdit from 'new-app/pages/profile/userProfile';
import SkillCategory from "new-app/pages/skillCategory";
import Login from 'pages/auth/Login';
import Logout from 'pages/auth/Logout';
import Financials from 'pages/financials';
import Analysis from 'pages/analysis';
import Histogram from 'pages/analysis/Histogram';
import AddSkill from 'pages/elastic_board/AddSkill';
import AddRole from 'pages/elastic_board/AddRole';
import ReviewSkill from 'pages/elastic_board/ReviewSkill';
import ManageTeam from 'pages/project/ManageTeam';
import ExternalUserBoard from 'pages/external_board/externalUser';
import { Authenticator } from '@aws-amplify/ui-react';
import Roles from 'new-app/pages/roles';
import ExternalTalentFinder from 'new-app/pages/externalTalentFinder';
import TechRadar from 'pages/tech-radar';
import { CurrencyExchangeIcon, AssessmentIcon } from 'assets/icons';

const routes = [
  {
    name: 'Login',
    key: 'login',
    component: <Login />,
    route: '/login',
  },
  {
    name: 'SignIn',
    key: 'signin',
    component: <Login />,
    route: '/signin',
  },
  {
    type: 'collapse',
    name: 'Home',
    key: 'home',
    component: (
      <Authenticator.Provider>
        <Home />
      </Authenticator.Provider>
    ),
    icon: <Icon fontSize="medium">home</Icon>,
    route: '/home',
    noCollapse: true,
    disabled: true,
  },

  {
    type: 'collapse',
    name: 'OnBoard',
    key: 'onboard',
    disabled: false,
    component: (
      <Authenticator.Provider>
        <OnBoard />
      </Authenticator.Provider>
    ),
    icon: <Icon fontSize="medium">rocket_launch</Icon>,
    route: '/onboard',
    noCollapse: true,
    internalRoute: true,
  },
  {
    type: "collapse",
    name: "Tech Radar",
    key: "tech_radar",
    disabled: process.env.REACT_APP_TECH_RADAR_LINK  !== 'true',
    component: (
      <Authenticator.Provider>
        <TechRadar />
      </Authenticator.Provider>
    ),
    icon: <Icon fontSize="medium">data_usage</Icon>,
    route: "/tech_radar",
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Elastic Talent Finder',
    key: 'elastic_talent_board',
    component: (
      <Authenticator.Provider>
        <NewAppWrapper>
          <ElasticTalentFinder />
        </NewAppWrapper>
      </Authenticator.Provider>
    ),
    icon: <Icon fontSize="medium">search</Icon>,
    route: '/elastic_talent_board',
    noCollapse: true,
    disabled: true,
  },
  {
    type: 'collapse',
    name: 'Elastic Talent Finder+',
    key: 'elastic_talent_board_plus',
    component: (
      <Authenticator.Provider>
        <NewAppWrapper disableSpace={true}>
          <ElasticTalentFinderPlus />
        </NewAppWrapper>
      </Authenticator.Provider>
    ),
    icon: <Icon fontSize="medium">zoom_in</Icon>,
    route: '/elastic_talent_board_plus',
    noCollapse: true,
  },
  {
    name: 'Elastic Talent Finder+',
    key: 'elastic_talent_board_plus',
    disabled: false,
    component: (
      <Authenticator.Provider>
        <NewAppWrapper disableSpace={true}>
          <UserProfileEdit />
        </NewAppWrapper>
      </Authenticator.Provider>
    ),
    route: '/elastic_talent_board_plus/:id/edit',
    internalRoute: true,
  },
  {
    type: 'collapse',
    name: 'Elastic Team Roll Off',
    key: 'elastic_team_roll_off',
    component: <Financials />,
    icon: <Icon fontSize="medium">paid</Icon>,
    route: '/elastic_team_roll_off',
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Admin',
    key: 'admin',
    component: (
      <Authenticator.Provider>
        <Admin />
      </Authenticator.Provider>
    ),
    icon: <Icon fontSize="medium">manage</Icon>,
    route: '/admin',
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Exchange Rate',
    key: 'exchange_rate',
    disabled: false,
    component: (
      <Authenticator.Provider>
        <div className='bg-white w-full h-full min-h-[100vh]'>
          <NewAppWrapper>
            <ExchangeRate />
          </NewAppWrapper>
        </div>
      </Authenticator.Provider>
    ),
    icon: <CurrencyExchangeIcon />,
    route: '/exchange_rate',
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Manage Assessments',
    key: 'manage_assessements',
    disabled: false,
    component: (
      <Authenticator.Provider>
        <div className='bg-white w-full h-full min-h-[100vh]'>
          <NewAppWrapper>
            <ManageAssessements />
          </NewAppWrapper>
        </div>
      </Authenticator.Provider>
    ),
    icon: <AssessmentIcon />,
    route: '/manage_assessements',
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'New Applicants',
    key: 'external_talent_board',
    component: (
      <Authenticator.Provider>
        <NewAppWrapper>
          <ExternalTalentFinder />
        </NewAppWrapper>
      </Authenticator.Provider>
    ),
    icon: <Icon fontSize="medium">summarize</Icon>,
    route: '/external_talent_board',
    internalRoute: true,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Projects',
    key: 'projects',
    component: <Project />,
    icon: <Icon fontSize="medium">calendar_month</Icon>,
    route: '/projects',
    noCollapse: true,
    internalRoute: true,
  },
  {
    name: 'Manage Team',
    key: 'project_edit',
    component: <ManageTeam />,
    route: '/projects/:id/manage_team',
    internalRoute: true,
  },
  {
    name: 'Talent Edit',
    key: 'talent_edit',
    component: <OnBoard onePage={true} />,
    route: '/elastic_talent_board/:id/edit',
    internalRoute: true,
  },
  {
    name: 'Talent View',
    key: 'talent_view',
    component: <OnBoard onePage={true} readonly={true} />,
    route: '/elastic_talent_board/:id/view',
    internalRoute: true,
  },
  {
    name: 'External Talent View',
    key: 'external_talent_view',
    component: <ExternalUserBoard />,
    route: '/external_talent_board/:id/view',
    internalRoute: true,
  },
  {
    name: 'Add Skills',
    key: 'add_skills',
    component: <AddSkill />,
    route: '/elastic_talent_board/:id/add_skills',
    internalRoute: true,
  },
  {
    name: 'Add Roles',
    key: 'add_roles',
    component: <AddRole />,
    route: '/elastic_talent_board/:id/add_roles',
    internalRoute: true,
  },
  {
    name: 'Review Skills',
    key: 'review_skills',
    component: <ReviewSkill />,
    internalRoute: true,
    route: '/elastic_talent_board/:id/review_skills',
  },
  {
    type: 'collapse',
    name: 'Analysis',
    key: 'analysis',
    component: <Analysis />,
    icon: <Icon fontSize="medium">pie_chart</Icon>,
    route: '/analysis',
    noCollapse: true,
    internalRoute: true,
  },
  {
    name: 'Total Skilled Engineers',
    key: 'total_skilled_engineers',
    component: <Histogram />,
    route: '/analysis/total_skilled_engineers',
    internalRoute: true,
  },
  {
    name: 'Assigned Roles',
    key: 'assigned_roles',
    component: <Histogram />,
    route: '/analysis/assigned_roles',
    internalRoute: true,
  },
  {
    name: 'Claimed Skills',
    key: 'claimed_skills',
    component: <Histogram />,
    route: '/analysis/claimed_skills',
    internalRoute: true,
  },
  {
    name: 'Project Assignments',
    key: 'project_assignments',
    component: <Histogram />,
    route: '/analysis/project_assignments',
    internalRoute: true,
  },
  {
    name: 'External Registrants',
    key: 'external_registrants',
    component: <Histogram />,
    route: '/analysis/external_registrants',
    internalRoute: true,
  },
  {
    name: 'Goals Accomplished',
    key: 'goals_accomplished',
    component: <Histogram />,
    route: '/analysis/goals_accomplished',
    internalRoute: true,
  },
  {
    type: 'collapse',
    name: 'Roles',
    key: 'roles',
    component: (
      <NewAppWrapper>
        <Roles />
      </NewAppWrapper>
    ),
    icon: <Icon fontSize="medium">group</Icon>,
    route: '/roles',
    noCollapse: true,
    internalRoute: true,
  },
  {
    name: 'Create Role',
    key: 'create_roles',
    component: <CreateRole />,
    route: '/roles/create',
    internalRoute: true,
  },
  {
    name: 'Edit Role',
    key: 'edit_roles',
    component: <CreateRole />,
    route: '/roles/edit/:_id',
    internalRoute: true,
  },
  {
    type: "collapse",
    name: "Skill Categories",
    key: "skill_categories",
    component: <NewAppWrapper><SkillCategory /></NewAppWrapper>,
    icon: <Icon fontSize="medium">category</Icon>,
    route: "/skill_categories",
    noCollapse: true,
    internalRoute: true,
    disabled: false,
  },
  {
    type: 'collapse',
    name: 'Skills',
    key: 'skills',
    component: <Skill />,
    icon: <Icon fontSize="medium">workspace_premium</Icon>,
    route: '/skills',
    noCollapse: true,
    internalRoute: true,
  },
  {
    type: 'collapse',
    name: 'Core Team',
    key: 'core_team',
    component: <CoreTeam />,
    icon: <Icon fontSize="medium">security</Icon>,
    route: '/core_team',
    noCollapse: true,
    internalRoute: true,
  },
  {
    type: 'collapse',
    name: 'Logout',
    key: 'logout',
    component: <Logout />,
    icon: <Icon fontSize="medium">logout</Icon>,
    route: '/logout',
    noCollapse: true,
    internalRoute: true,
  },
];

export default routes;
