import moment, { unitOfTime } from 'moment';

export interface AvailabilityProps {
  date: Date;
}

export default function (props: AvailabilityProps) {
  if (!props.date) {
    return <span className="flex">N/A</span>;
  }
  const currentDate = new Date();
  if (moment(props.date).isSameOrBefore(currentDate, 'day')) {
    return (
      <span className="flex shrink text-availability-success justify-center">
        Available Now
      </span>
    );
  }
  // get timespan in days (86400000 miliseconds in a day)
  const timespan = (props.date.getTime() - currentDate.getTime()) / 86400000;
  let timeUnit: unitOfTime.Diff = 'days';
  if (timespan <= 1) {
    timeUnit = 'hours'
  } else if (timespan < 7) {
    timeUnit = 'days';
  } else if (timespan < 30) {
    timeUnit = 'weeks';
  } else if (timespan < 365) {
    timeUnit = 'months';
  } else {
    timeUnit = 'years';
  }
  const timeLeft = Math.round(moment(props.date).diff(currentDate, timeUnit, true));
  const availabilityText = timeLeft + ' ' + (timeLeft <= 1 ? timeUnit.replace('s', '') : timeUnit);
  return (
    <span className="flex flex-col justify-center min-w-[104px]">
      <span className="flex justify-center">{availabilityText}</span>
      <span className="flex justify-center text-xs text-text-mild-contrast">
        {props.date.toDateString()}
      </span>
    </span>
  );
}
