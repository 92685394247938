import {useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {EventType, logEvent} from "../../context/analytics";

function Logout(): JSX.Element {
  useEffect(() => {
    logEvent(EventType.logout);
    Auth.signOut();
  }, []);
  return <Navigate to="/login" replace={true} />;
}

export default Logout;
