import { useContext } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Card from './Card';
import links from './links';
import { authContext, authLogic } from 'context/auth';
import { permissionRoute } from 'context/constants';
import { adminRole } from 'constants/index';

function Home(): JSX.Element {
  const { authUser } = useContext(authContext);
  const allowedLinks = links.filter((link) => {
    return authUser && authLogic(link.key, authUser['cognito:groups']) && !link.disabled && link.key !== 'logout';
  });

  const renderEmpty = () => {
    return (
      <div className="flex h-screen justify-center items-center">
        <h1 className="mx-10">
          Sorry, you don't have access to Distributed Command. For help please
          contact Distributed team at command@distributed.com
        </h1>
      </div>
    );
  };
  const renderLinks = () => {
    const links = allowedLinks.map((link) => (
      <Grid item xs={12} md={6} lg={3} key={link.title}>
        <MDBox mb={1.5} mt={1.5}>
          <Card image={link.image} title={link.title} link={link.link} />
        </MDBox>
      </Grid>
    ));

    return (
      <MDBox my={3}>
        <Grid container spacing={3}>
          {links}
        </Grid>
      </MDBox>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {allowedLinks.length === 0 ? renderEmpty() : renderLinks()}
    </DashboardLayout>
  );
}

export default Home;
