import React from 'react';
import Heading from './heading';

export interface ModalProps {
  title: string;
  children: React.ReactNode;
  onCloseClick?: () => void;
}

export default function (props: ModalProps) {
  return (
    <div
      className="block fixed left-0 top-0 w-full h-full bg-semi-transparent p-10"
      style={{ zIndex: 20000 }}
    >
      <div className="block m-auto max-w-screen-xl bg-white rounded-lg p-8">
        <div className="flex flex-col gap-8">
          <div className="flex flex-row">
            <div className="flex grow">
              <Heading text={props.title} />
            </div>
            <div className="flex shrink">
              <button
                className="material-icons"
                onClick={() => {
                  if (props.onCloseClick) props.onCloseClick();
                }}
              >
                close
              </button>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}
