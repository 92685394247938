export const permissionList = [
  {
    label: 'Global Administrator',
    key: 'global_admin',
    caption: 'Unrestricted access to all features',
  },
  {
    label: 'Manage Core Team Permissions',
    key: 'manage_core_team',
    caption: 'Manage access control for Core Team members',
  },
  {
    label: 'Manage Elastic Team Members',
    key: 'manage_elastic_team',
    caption: 'Onboard, edit, delete and manage Elastic Team members',
  },
  {
    label: 'Manage Global Settings',
    key: 'manage_global_settings',
    caption:
      'Configure data elements and control features for the Elastic Team app and the Customer app',
  },
  {
    label: 'Manage Projects',
    key: 'manage_project',
    caption: 'Add, edit and delete projects',
  },
  {
    label: 'Manage Role and Skill Definitions',
    key: 'manage_role_skills',
    caption:
      'Create, edit and delete role & skill definitions that can be assigned to (or claimed by) Elastic Team members',
  },
  {
    label: 'Manage User/Project Assignments',
    key: 'manage_user_projects',
    caption: 'Add, remove or edit the assignment of users to projects',
  },
];

type TPermissionRoute = {
  [route: string]: string[];
};
export const permissionRoute: TPermissionRoute = {
  // Route Key To Permission Key
  home: [],
  onboard: ['manage_elastic_teams'],
  elastic_talent_board: ['manage_elastic_teams'],
  elastic_talent_board_plus: ['manage_elastic_teams'],
  exchange_rate: ['manage_elastic_teams'],
  manage_assessements: ['manage_elastic_teams'],
  tech_radar: ['manage_elastic_teams'],
  external_talent_board: ['manage_elastic_teams'],
  projects: ['manage_user_projects', 'manage_projects'],
  project_edit: ['manage_user_projects'],
  talent_edit: ['manage_elastic_teams'],
  talent_view: ['manage_elastic_teams'],
  external_talent_view: ['manage_elastic_teams'],
  add_skills: ['manage_elastic_teams'],
  add_roles: ['manage_elastic_teams'],
  review_skills: ['manage_elastic_teams'],
  analysis: ['manage_elastic_teams'],
  total_skilled_engineers: [],
  elastic_team_financials: [],
  assigned_roles: [],
  claimed_skills: [],
  project_assignments: [],
  external_registrants: [],
  goals_accomplished: [],
  elastic_team_roll_off: ['manage_elastic_teams'],
  roles: ['manage_role_skills'],
  create_roles: ['manage_role_skills'],
  edit_roles: ['manage_role_skills'],
  skills: ['manage_role_skills'],
  core_team: ['core_team', 'manage_core_teams'],
  skill_categories: ["manage_role_skills", "core_team", "manage_core_teams"],
};
