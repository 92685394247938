import classnames from 'classnames';
import { User } from 'new-app/models/user';
import Availability from './availability';
import Modal from './modal';
import Table from './table';

export interface TalentComparisonProps {
  users: User[];
  isOpen?: boolean;
  onCloseClick?: () => void;
}

export default function (props: TalentComparisonProps) {
  function getTableKey(user: User) {
    return `${user.firstName} ${user.lastName}`;
  }

  const headings = ['Talent', ...props.users.map((user) => getTableKey(user))];
  const data: Array<Map<string, JSX.Element>> = [];

  function buildLabel(icon: string, text: string) {
    return (
      <div className="flex items-center gap-2">
        <span className="flex material-icons">{icon}</span>
        <span>{text}</span>
      </div>
    );
  }

  data.push(
    new Map([
      ['Talent', buildLabel('email', 'email')],
      ...(props.users.map((user) => {
        return [getTableKey(user), <span>{user.email}</span>];
      }) as [string, JSX.Element][]),
    ]),
    new Map([
      ['Talent', buildLabel('business_center', 'Roles')],
      ...(props.users.map((user) => {
        return [
          getTableKey(user),
          <span>{user.roles.map((role) => role.name).join(', ')}</span>,
        ];
      }) as [string, JSX.Element][]),
    ]),
    new Map([
      ['Talent', buildLabel('insights', 'Skills')],
      ...(props.users.map((user) => {
        return [
          getTableKey(user),
          <span>{user.skills.map((skill) => skill.name).join(', ')}</span>,
        ];
      }) as [string, JSX.Element][]),
    ]),
    new Map([
      ['Talent', buildLabel('location_on', 'Location')],
      ...(props.users.map((user) => {
        return [getTableKey(user), <span>{user.place.location}</span>];
      }) as [string, JSX.Element][]),
    ]),
    new Map([
      ['Talent', buildLabel('language', 'Language')],
      ...(props.users.map((user) => {
        return [getTableKey(user), <span>{user.place.language}</span>];
      }) as [string, JSX.Element][]),
    ]),
    new Map([
      ['Talent', buildLabel('badge', 'Status')],
      ...(props.users.map((user) => {
        return [
          getTableKey(user),
          <span
            className={classnames({
              'text-availability-success': user.approved,
            })}
          >
            {user.approved ? 'Approved For Projects' : 'Not Approved'}
          </span>,
        ];
      }) as [string, JSX.Element][]),
    ]),
    new Map([
      ['Talent', buildLabel('folder_copy', 'Completed Projects')],
      ...(props.users.map((user) => {
        return [
          getTableKey(user),
          <span>
            {
              user.projectDetail.filter(
                (project) => Date.parse(project.endDate) <= Date.now()
              ).length
            }
          </span>,
        ];
      }) as [string, JSX.Element][]),
    ]),
    new Map([
      ['Talent', buildLabel('calendar_month', 'Availability')],
      ...(props.users.map((user) => {
        return [
          getTableKey(user),
          <Availability date={new Date(Date.parse(user.availablity))} />,
        ];
      }) as [string, JSX.Element][]),
    ]),
    new Map([
      ['Talent', buildLabel('local_atm', 'Pay Rate')],
      ...(props.users.map((user) => {
        let payRate = '-';
        if (user.paymentInfo && user.paymentInfo.length > 0) {
          const paymentInfo = user.paymentInfo.sort(
            (a, b) => Date.parse(a.paymentDate) - Date.parse(b.paymentDate)
          )[0];
          payRate = `${paymentInfo.amount} ${paymentInfo.currency} per ${paymentInfo.frequency}`;
        }
        return [getTableKey(user), <span>{payRate}</span>];
      }) as [string, JSX.Element][]),
    ])
  );

  if (!props.isOpen) {
    return null;
  }
  return (
    <Modal
      title="Talent Comparison"
      onCloseClick={() => {
        if (props.onCloseClick) props.onCloseClick();
      }}
    >
      <div className="flex overflow-y-auto max-h-max-modal">
        <Table headings={headings} data={data} />
      </div>
    </Modal>
  );
}
