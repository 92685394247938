import { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { authContext } from 'context/auth';
import { permissionRoute } from 'context/constants';
import { AppBar, Autocomplete, Icon, Tab, Tabs } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';
import axios from 'utils/axios';
import { useBackdrop } from 'context/backdrop';
import { UserTypes } from 'interfaces/enums';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { toast } from 'react-toastify';
import { dialogRender } from 'utils/confirmDialog';
import { confirm } from 'react-confirm-box';
import EditTeamMember from './EditTeamMember';
import MDTypography from 'components/MDTypography';

export interface ProjectTeamUser {
  email: string;
  firstName: string;
  lastName: string;
  userID: string;
  userType: UserTypes;
  startDate?: string;
  endDate?: string;
}

function ManageTeam(): JSX.Element {
  const { id } = useParams();
  const { fetching } = useBackdrop();
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
    setSelectedUser(null);
  };
  const [projectName, setProjectName] = useState('');
  const [elasticMembers, setElasticMembers] = useState([]);
  const [coreMembers, setCoreMembers] = useState([]);
  const [elasticUsers, setElasticUsers] = useState([]);
  const [coreUsers, setCoreUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [openEdit, setOpenEdit] = useState(false);
  const [editingTeamMember, setEditingTeamMember] = useState(undefined);

  function mapToTableData(user: ProjectTeamUser, projectId: string) {
    function prettyDateDisplay(val: string): string {
      if (!val) return 'N/A';

      return new Date(Date.parse(val)).toLocaleDateString('en');
    }

    return {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      startDate: prettyDateDisplay(user.startDate),
      endDate: prettyDateDisplay(user.endDate),
      actions: (
        <>
          <MDButton iconOnly color="info" onClick={() => handleOpenEdit(user)}>
            <Icon>edit</Icon>
          </MDButton>
          <MDButton
            iconOnly
            color="primary"
            onClick={() => deleteMember(user, dialogRender)}
          >
            <Icon>delete</Icon>
          </MDButton>
        </>
      ),
    };
  }

  const handleOpenEdit = (member: any) => {
    setOpenEdit(true);
    setEditingTeamMember(member);
  };

  const getUserOptions = async () => {
    try {
      const { data } = await axios.post(`/user/options`, {
        fields: ['_id', 'email'],
      });
      setElasticUsers(data.elasticUsers);
      setCoreUsers(data.coreUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const getProject = async () => {
    try {
      fetching(true);
      const { data } = await axios.get(`/project/${id}`);
      setProjectName(data.name);

      const elasticUsers = data.team
        .filter((usr: ProjectTeamUser) => usr.userType === UserTypes.ENGINEER)
        .map((usr: ProjectTeamUser) => mapToTableData(usr, id));

      const coreUsers = data.team
        .filter((usr: ProjectTeamUser) => usr.userType === UserTypes.CORE)
        .map((usr: ProjectTeamUser) => mapToTableData(usr, id));

      setElasticMembers(elasticUsers);
      setCoreMembers(coreUsers);
    } catch (error) {
      console.log(error);
    } finally {
      fetching(false);
    }
  };

  const addMember = async () => {
    try {
      fetching(true);
      await axios.post('/project/add_member', {
        user_id: selectedUser._id,
        project_id: id,
      });
      toast.success('The Team Member has been added successfully!');
      getProject();
    } catch (error: any) {
      toast.warn(error.response.data.message);
    } finally {
      fetching(false);
    }
  };
  const deleteMember = async (user: any, options: any) => {
    const result = await confirm(
      'Are you sure to delete this Member?',
      options
    );
    if (result) {
      try {
        fetching(true);
        await axios.post(`/project/delete_team_member`, {
          user_id: user.userID,
          project_id: id,
        });
        toast.success('The Team Member has been deleted successfully!');
        getProject();
      } catch (error) {
      } finally {
        fetching(false);
      }
    }
  };

  const saveMember = async (startDate: any, endDate: any) => {
    try {
      await axios.post('/project/save_team_member', {
        project_id: id,
        user_id: editingTeamMember.userID,
        startDate,
        endDate,
      });
      toast.success('A Team Member has been edited successfully');
    } catch (error) {}
  };

  useEffect(() => {
    getProject();
    getUserOptions();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDTypography>{projectName}</MDTypography>
        <AppBar position="static">
          <Tabs
            orientation="horizontal"
            value={tabValue}
            onChange={handleSetTabValue}
          >
            <Tab
              label="Elastic Members"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  group
                </Icon>
              }
            />
            <Tab
              label="Core Team Members"
              icon={
                <Icon fontSize="small" sx={{ mt: -0.25 }}>
                  contact_page
                </Icon>
              }
            />
          </Tabs>
        </AppBar>
      </MDBox>
      <MDBox display="flex" mb={3} alignItems="center">
        <Autocomplete
          fullWidth
          options={tabValue === 0 ? elasticUsers : coreUsers}
          getOptionLabel={(option) => option.email || ''}
          isOptionEqualToValue={(option: any, value: any) =>
            option._id === value._id
          }
          value={selectedUser || null}
          onChange={(e: any, value) => setSelectedUser(value)}
          renderInput={(params) => (
            <MDInput
              {...params}
              label="Choose Users For This Project"
              variant="standard"
              size="medium"
            />
          )}
        />
        <MDBox ml={3}>
          <MDButton iconOnly color="info" onClick={addMember}>
            <Icon>add</Icon>
          </MDButton>
        </MDBox>
      </MDBox>

      <MDBox>
        <DataTable
          canSearch
          table={{
            columns: [
              { Header: 'Email', accessor: 'email' },
              { Header: 'First Name', accessor: 'firstName' },
              { Header: 'Last Name', accessor: 'lastName' },
              { Header: 'Start Date', accessor: 'startDate' },
              { Header: 'End Date', accessor: 'endDate' },
              { Header: 'Actions', accessor: 'actions', width: '10%' },
            ],
            rows: tabValue === 0 ? elasticMembers : coreMembers,
          }}
        />
      </MDBox>
      <EditTeamMember
        teamMember={editingTeamMember}
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        save={saveMember}
      />
    </DashboardLayout>
  );
}

export default ManageTeam;
