import Skeleton from '@mui/material/Skeleton';
import { UserIcon } from 'assets/icons';

const CustomSkeleton = (props: any) => {
  const { style = {}, ...restProps } = props;
  return (
    <Skeleton {...restProps} style={{ borderRadius: '2px', background: '#D7DBE6', padding: '13.4px 0', ...style }} />
  )
}

export default () => {
  var height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;
  const skeletonCounts = Math.ceil((height - 380) / 64);
  return (
    <div className='flex flex-col'>
      <div className="flex flex-col rounded-lg">
        <table >
          <tbody>
            <tr className='h-10 bg-[#ebedf0]'>
              <th className="flex justify-center items-center h-10">
                <CustomSkeleton width={79} height={16} />
              </th>
              <th><CustomSkeleton width={79} height={16} /></th>
              <th><CustomSkeleton width={79} height={16} style={{ marginLeft: 40 }} /></th>
              <th><CustomSkeleton width={56} height={16} /></th>
              <th><CustomSkeleton width={56} height={16} /></th>
              <th><CustomSkeleton width={79} height={16} /></th>
              <th><CustomSkeleton width={16} height={16} /></th>
            </tr>
            {new Array(skeletonCounts || 9).fill('').map((i, index) => {
              return (
                <tr className='border-b-2 bg-white border-[#D7DBE6] h-16' key={index}>
                  <td>
                    <div className="flex flex-row items-center justify-center gap-x-4">
                      <CustomSkeleton width={16} height={16} />
                      <Skeleton variant="circular" style={{ background: '#D7DBE6' }}>
                        <UserIcon />
                      </Skeleton>
                      <CustomSkeleton width={111} height={16} />
                    </div>
                  </td>
                  <td><CustomSkeleton width={95} height={16} /></td>
                  <td><CustomSkeleton width={208} height={16} /></td>
                  <td><CustomSkeleton width={56} height={16} /></td>
                  <td><CustomSkeleton width={56} height={16} /></td>
                  <td><CustomSkeleton width={110} height={16} /></td>
                  <td><CustomSkeleton width={16} height={16} /></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col gap-3 border-b-2 border-[#D7DBE6] bg-white rounded-lg mt-2">
        <div className="flex flex-row justify-around items-center h-[56px]">
          <div className="flex flex-row justify-around items-center gap-x-3">
            <CustomSkeleton width={111} height={16} />
            <CustomSkeleton width={111} height={16} />
          </div>
          <div className="flex flex-row justify-around items-center gap-x-3">
            <CustomSkeleton width={87} height={16} />
            <CustomSkeleton width={38} height={16} />
            <CustomSkeleton width={38} height={16} />
            <CustomSkeleton width={38} height={16} />
            <CustomSkeleton width={38} height={16} />
            <CustomSkeleton width={87} height={16} />
          </div>
          <div className="flex flex-row justify-around items-center gap-x-3">
            <CustomSkeleton width={111} height={16} />
            <CustomSkeleton width={111} height={16} />
          </div>
        </div>
      </div>
    </div>
  )
}
