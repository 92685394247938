import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputComponent from 'new-app/components/input';
import ButtonComponent from 'new-app/components/button';

export interface ShortlistsPopupProps {
    open: boolean;
    onSave: (data: any) => void;
    handleClose: () => void;
    onAddShortlist: () => void;
    anchorEl: any;
    createMode?: boolean;
    isLoading?: boolean;
    shortlists?: any;
}

export default function ShortlistsPopup(props: ShortlistsPopupProps) {
    const [shortlistName, setShortlistName] = useState('');

    useEffect(() => {
        if (!props.open) {
            setTimeout(() => {
                setShortlistName('');
            }, 300)
        }
    }, [props.open]);


    const onChange = (e: any) => {
        setShortlistName(e.target.value);
    }

    const onSave = (shortlistId?: string) => {
        if (!props.isLoading) {
            props.onSave({shortlistName, shortlistId});
        }
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.handleClose}
            classes={{
                paper: '!p-0 !min-w-[320px] !cursor-pointer',
            }}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {props.createMode ? (
                <div>
                    <p className="font-inter font-semibold p-4 leading-5 text-[14px] text-cool-900">Create New Shortlist</p>
                    <div className='px-4'>
                        <InputComponent className='!h-12' value={shortlistName} onChange={onChange} placeholder='Enter Shortlist Name' />
                    </div>
                    <div className='flex items-center left-0 bottom-0 w-full h-[72px] flex flex-row justify-end gap-x-4 pr-4'>
                        <ButtonComponent className='!bg-grey-100 hover:!bg-grey-200 active:!bg-grey-400 !text-[#666666] text-[14px] !h-8' onClick={props.handleClose} label='Cancel' />
                        <ButtonComponent className='!h-8 hover:!bg-marine-600 active:!bg-marine-800' disabled={!shortlistName} isLoading={props.isLoading} onClick={onSave} label='Save' />
                    </div>
                </div>
            ) : (
                <div>
                    <p className="font-inter font-semibold p-4 leading-5 text-[14px] text-cool-900">Add to Shortlist</p>
                    <span className='bg-[#E6E6E6] h-[1px] block w-full' />
                    <div className='py-2'>
                        {props.shortlists && props.shortlists.map((item: any) => {
                            const { name, id,} = item;
                            return (
                                <MenuItem
                                    key={id}
                                    classes={{ root: '!font-inter !px-[16px] !py-[6px] !rounded-none hover:!text-[#144FE7] hover:!bg-[#F2F5FE] !font-normal !leading-5 !text-[14px] !text-[#555E6D]'}}
                                    onClick={() => onSave(id)}
                                >{name}</MenuItem>
                            )
                        })}
                        {props.shortlists && props.shortlists.length === 0 && (
                            <p className='!font-inter !px-[16px] !py-[6px] !rounded-none hover:!text-[#144FE7] hover:!bg-[#F2F5FE] !font-normal !leading-5 !text-[14px] !text-[#555E6D]'>
                                You currently have no shortlists
                            </p>
                        )}
                    </div>
                    <span className='bg-[#E6E6E6] h-[1px] block w-full' />
                    <p className="font-inter p-4 font-normal leading-5 text-[14px] text-[#3168F4]" onClick={props.onAddShortlist}>+ New Shortlist</p>
                </div>
            )}
           
        </Menu>
    );
}