import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { CloseIcon1 } from 'assets/icons';
import Input from 'new-app/components/input';
import { SearchIcon } from 'assets/icons';
import {
  Autocomplete,
  Box
} from '@mui/material';
import Button from 'new-app/components/button';
import { Assessment } from 'new-app/models/assessement';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}


interface DialogProps {
  assessment?: Assessment | null;
  skills?: any;
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleChange: (event: any, field: string) => void;
  onSave: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 3, pb: 1 }} classes={{ root: '!text-[18px] !font-poppins !leading-6 !font-semibold !text-cool-900'}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon1 />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function NewDialog(props: DialogProps): JSX.Element {
  const { assessment, open, handleClose, skills, isLoading, handleChange, onSave } = props;
  const [saving, setSaving] = useState(false);

  const handleSave = () => {
    try {
      setSaving(true);
      onSave();
    } finally {
      setSaving(false);
    }
  };

  const isDisabled = !assessment?.name ||
    !assessment?.testId || !assessment?.link ||
    !assessment?.skillIds ||
    ( Array.isArray(assessment?.skillIds) && assessment.skillIds.length === 0);

  return (
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {assessment?.id ? 'Edit Test' : 'Add New Test'}
        </BootstrapDialogTitle>
        <DialogContent classes={{ root: '!p-6 !pt-0 md:!min-w-[520px] !min-w-full'}}>
          <div className='flex flex-col gap-4 mt-4'>
              <Input
                label="Test Name"
                value={assessment?.name || ''}
                onChange={(e: any) => handleChange(e.target.value, 'name')}
              />
              <Input
                style={{ width: '100%' }}
                label="Test ID"
                value={assessment?.testId || ''}
                onChange={(e: any) => handleChange(e.target.value, 'testId')}
              />
              <Input
                rows={5}
                style={{ width: '100%' }}
                label="Description"
                value={assessment?.description || ''}
                onChange={(e: any) =>
                  handleChange(e.target.value, 'description')
                }
              />
              <Input
                label="Invite Link"
                value={assessment?.link || ''}
                onChange={(e: any) =>
                  handleChange(e.target.value, 'link')
                }
              />
            <div className='flex flex-col w-full'>
              <label className={'text-cool-700 font-inter font-normal text-[14px] leading-5 mb-2'}>Skills</label>
              <Autocomplete
                onChange={(_, values: any, action, details: any) => {
                  handleChange(values.map((skills: any) => skills.id), 'skillIds')
                }}
                multiple
                size="small"
                options={skills}
                value={skills.filter((skillOption: any) =>
                  (assessment?.skillIds || []).includes(skillOption?.id)
                )}
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <Input
                    icon={<SearchIcon />}
                    {...params} label="" placeholder="Start Typing..."
                  />
                )}
                renderTags={() => null}
                clearIcon={null}
                renderOption={(props, option: any) => (
                  <Box
                    component="li"
                    {...props} sx={{ fontSize: '14px', fontFamily: 'Inter'}}
                  >
                    {option.label}
                  </Box>
                )}
              />
            </div>
            <div className='flex flex-row flex-wrap gap-2'>
              {skills.filter((skillOption: any) =>
                  (assessment?.skillIds || []).includes(skillOption?.id)
                ).map((skill: any) => {
                  return (
                    <span
                      key={skill.id}
                      className='bg-cool-50 rounded border cursor-pointer border-cool-200 py-[5px] px-3 flex flex-row items-center gap-1 font-inter font-normal text-[14px] leading-5 text-cool-700'>
                      {skill.label}
                      <CloseIcon1 onClick={() => {
                        handleChange(assessment?.skillIds.filter((skillId: any) => skillId !== skill.id), 'skillIds')
                      }}/>
                    </span>
                  )
                })}
            </div>
          </div>
        </DialogContent>
        <div className='flex justify-end gap-2 p-6 pt-0'>
            <Button
                className='!bg-grey-100 hover:!bg-grey-200 active:!bg-grey-400 !text-[#666666] text-[14px] !h-10'
                label='Cancel'
                onClick={handleClose}
            />
            <Button
                className='!h-10 hover:!bg-marine-600 active:!bg-marine-800'
                label='Save'
                onClick={handleSave}
                disabled={isDisabled}
                isLoading={isLoading}
            />
        </div>
      </BootstrapDialog>
  );
}

NewDialog.defaultProps = {
  project: undefined,
};
export default NewDialog;
