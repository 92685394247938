import styled from "styled-components";

const RadarContents = styled.svg``;
const OptionIcon = styled.svg`
    stroke: white;
    &:hover {
        stroke: #FF007A;
    }
`;

const OptionButton = styled.button`
    display: block;
    width: 24px;
    height: 24px;
    margin: 1px 2px;
    color: rgb(255; 255; 255);
    transition: color 200ms ease 0s;
    background: none;
    padding: 0px;
    border: 0px;
    outline: 0px;
    cursor: pointer
`;

export { RadarContents, OptionButton, OptionIcon };
