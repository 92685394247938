/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import * as Sentry from '@sentry/react';
import * as Tracing from '@sentry/tracing';

import { Analytics } from "./context/analytics";

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from 'context';
import './index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_TOKEN,
  integrations: [new Tracing.BrowserTracing()],
  tracesSampleRate:
    Number.parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? '0') ??
    0,
  beforeSend(event) {
    if (event.user) {
      delete event.user.email;
      delete event.user.ip_address;
      delete event.user.username;
    }
    return event;
  },
});

ReactDOM.render(
  <BrowserRouter>
    <Analytics />
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
