import {useRef, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import { DropDownIcon } from 'assets/icons';

import InputBase from '@mui/material/InputBase';

export interface SelectItems {
  title: string | number;
  value: string | number;
  filter?: number;
}

interface Props {
    items: SelectItems[];
    label?: string;
    value: any;
    errors?: any;
    name?: string;
    width?: number | string;
    opacity?: number;
    multiple?: boolean;
    disabled?: boolean;
    onChange?: (value: any) => void;
}

export default function SelectComponent(props: Props) {
    const {errors} = props;
  const [open, setOpen] = useState(false)
  const ref: any = useRef();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '700px',
        width: (ref && ref.current) ? ref.current.clientWidth : (props.width || "auto"),
        padding: 0,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof props.value>) => {
    const {
      target: { value },
    } = event;
    props.onChange(
      typeof value === 'string' && props.multiple ? value.split(',') : value,
    );
    setOpen(false);
  };
  const selectClasses = `outline-0 px-4 py-[10px] !h-10 
    ${(errors && errors[props.name]?.message) ? 'bg-[#FEEFEF] !border-[#EE6C6C]' : ''}
    bg-white !border !border-[#D9D9D9] 
    hover:!border-[#B3B3B3]
    disabled:!bg-[#F7F7F7] 
    placeholder:!text-[#707A89] 
    focus:!border-[#3168F4] 
    ${open ? '!border-[#3168F4]' : ''}
    !rounded !text-cool-900 !font-inter !font-normal !text-[14px] !leading-5 `;
  return (
    <div ref={ref}>
      <FormControl sx={{ m: 0, width: props.width || '100%', height: '100%' }} style={{minWidth: '100%'}} disabled={props.disabled}>
        <Select
          multiple={props.multiple}
          value={props.value || ""}
          open={open}
          autoWidth
          onChange={handleChange}
          input={<InputBase classes={{
            root: selectClasses
          }} />}
          IconComponent={() => <DropDownIcon className={`pointer-events-none transition-all ease-in-out delay-150 absolute right-4 `}/>}
          renderValue={(selected) => props.items.find(i => i.value === selected)?.title}
          MenuProps={MenuProps}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          className='!pl-0'
        >
          {props.items.length === 0 && (
              <MenuItem classes={{
                root: 'hover:!bg-[#F2F5FE] !m-0 !py-3 !rounded-none',
                selected: '!bg-[#D9E3FC]'
              }}>
                <div className='flex flex-row justify-between  w-full items-center'>
                  <div className='flex flex-row jusitfy-start text-left mr-4'>
                    <p className={`font-inter hover:text-[#144FE7] text-[#555E6D] text-[14px] font-normal leading-5`} >No data found</p>
                  </div>
                </div>
              </MenuItem>
          )}
          {props.items.map((item: any, index: number) => {
            const isSelected = props.value === item.value;
            if (item.header) {
              return (
                <ListSubheader sx={{ lineHeight: '30px'}} key={index}>{item.header}</ListSubheader>
              )
            }
            if (!item.value) return;
            return (
              <MenuItem key={index} value={item.value} classes={{
                root: 'hover:!bg-[#F2F5FE] !m-0 !py-3 !rounded-none',
                selected: '!bg-[#D9E3FC]'
              }}>
                <div className='flex flex-row justify-between  w-full items-center'>
                  <div className='flex flex-row jusitfy-start text-left mr-4'>
                    {props.multiple && <Checkbox checked={props.value.includes(item.value)} size='small' />}
                    <p className={`font-inter hover:text-[#144FE7] ${isSelected ? '!text-[#08329E] font-semibold' : ''} text-[#555E6D] text-[14px] font-normal leading-5`} >{item.title}</p>
                  </div>
                  {item.filter && <p className='font-inter text-[#6C6F79] text-[12px] font-medium'>{item.filter}</p>}
                </div>
              </MenuItem>
          )})}
        </Select>
      </FormControl>
    </div>
  );
} 