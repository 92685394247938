import { useEffect, useState } from 'react';
import Dropdown from './dropdown';
import Textfield from './textfield';
import classnames from 'classnames';

export interface PaginationProps {
  limit: number;
  page: number;
  total: number;
  count: number;
  onChange?: (limit: number, page: number) => void;
}

export default function (props: PaginationProps) {
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setLastPage(Math.ceil(props.count / props.limit));
  }, [props.count, props.limit]);

  useEffect(() => {
    setCurrentPage(props.page + 1);
  }, [props.page]);

  return (
    <div className="flex flex-row items-center gap-4  text-sm justify-between">
      <div className="flex items-center gap-4 justify-start basis-56">
        <div className="flex shrink basis-24">
          <Dropdown
            customWidth
            text="limit"
            defaultSelection="10"
            options={new Set(['5', '10', '20', '50'])}
            onChange={(selections) => {
              const selected = Number.parseInt([...selections][0]) ?? 10;
              if (props.onChange) {
                props.onChange(selected, props.page);
              }
            }}
          />
        </div>
        <span className="flex grow text-black text-sm">Entries per page</span>
      </div>
      <div className="flex items-center gap-4 justify-center">
        <span className="flex">Page</span>
        <div className="flex w-20">
          <Textfield
            customWidth
            type="number"
            value={currentPage}
            onChange={(value) => {
              if (props.onChange) {
                props.onChange(props.limit, Math.max(0, (value as number) - 1));
              }
            }}
          />
        </div>
        <span className="flex min-w-100">of {lastPage}</span>
      </div>
      <div className="flex items-center gap-4 justify-end">
        <span className="flex text-black">
          {props.page * props.limit + 1} -{' '}
          {props.page * props.limit + props.limit} of {props.count} entries
        </span>
        <button
          onClick={() => {
            if (props.onChange) {
              props.onChange(props.limit, 0);
            }
          }}
        >
          <span
            className={`${classnames({
              'text-primary': props.page > 0,
              'text-text-mild-contrast': props.page === 0,
            })} material-icons`}
          >
            first_page
          </span>
        </button>
        <button
          onClick={() => {
            if (props.onChange) {
              props.onChange(props.limit, Math.max(0, props.page - 1));
            }
          }}
        >
          <span
            className={`${classnames({
              'text-primary': props.page > 0,
              'text-text-mild-contrast': props.page === 0,
            })} material-icons`}
          >
            navigate_before
          </span>
        </button>
        <button
          onClick={() => {
            if (props.onChange) {
              props.onChange(props.limit, Math.min(lastPage, props.page + 1));
            }
          }}
        >
          <span
            className={`${classnames({
              'text-primary': props.page < lastPage,
              'text-text-mild-contrast': props.page === lastPage,
            })} material-icons`}
          >
            navigate_next
          </span>
        </button>
        <button
          onClick={() => {
            if (props.onChange) {
              props.onChange(props.limit, lastPage - 1);
            }
          }}
        >
          <span
            className={`${classnames({
              'text-primary': props.page < lastPage,
              'text-text-mild-contrast': props.page === lastPage,
            })} material-icons`}
          >
            last_page
          </span>
        </button>
      </div>
    </div>
  );
}
