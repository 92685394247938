import { FC, useContext, useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Radar from "components/Radar";
import { RadarDataContext } from "./radar-data-context";
import { fetchTechRadarSkills } from 'new-app/reducers/skill';
import { TSkill } from 'interfaces';
import BarChart from "./BarChart";
import Styled from './styles'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MDIOSSwitch from "components/MDSwitch";

const Chart = () => {
  const { skills } = useContext(RadarDataContext);

  const setup = {
    rings: [
      100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15,
      10, 5, 0,
    ],
    quadrants: ['Frameworks', 'Techniques', 'Tools', 'Platforms', 'Languages'],
    radiusDiminish: 1,
    width: 550,
    data: skills,
  };

  return (
    <Styled.PieChart>
      <Radar {...setup} />
    </Styled.PieChart>
  );
};

const PieCharDescription = () => {
  return (
    <Styled.PieChartDescription>
      <Styled.PieChartHeader>
        Tech Radar visualises the cumulative competency we have for specific skills across our Community.
        The closer a skill is to the centre, the stronger our competency.
      </Styled.PieChartHeader>
      <div className='bg-[rgba(255, 255, 255, 0.5)]'>
        <div className="font-poppins uppercase flex relative pl-1 w-[180px] mb-[10px] text-white text-[12px] font-light bg-[#808080] rounded">
          Talent Cloud Proficiency
          <span className="bg-[#808080] w-[14px] h-[14px] top-[3px] right-[-5px] rotate-45 absolute"></span>
        </div>
        <Styled.PieChartSkills>
          Skills are separated out into:
          <ul className="text-[13px] font-poppins">
            <Styled.PieChartSkillTools>Tools</Styled.PieChartSkillTools>
            <Styled.PieChartSkillPlatforms>Platforms</Styled.PieChartSkillPlatforms>
            <Styled.PieChartSkillLanguages>Languages</Styled.PieChartSkillLanguages>
            <Styled.PieChartSkillFrameworks>Frameworks</Styled.PieChartSkillFrameworks>
            <Styled.PieChartSkillTechniques>Techniques</Styled.PieChartSkillTechniques>
          </ul>
        </Styled.PieChartSkills>
      </div>
    </Styled.PieChartDescription>
  )
}


const TechRadar: FC = () => {
  const [quad, setQuad] = useState("Languages");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [sourceSkills, setSourceSkills] = useState([]);
  const [core, setCore] = useState(false);
  const [nonCore, setNonCore] = useState(false);

  const [techRadarSkills, setTechRadarSkills] = useState<TSkill[]>([]);

  const getQuadrant = (index: number, numberOfItems:number) => {
    const order = index / Math.floor(numberOfItems/5);
    const quadrants = ['Frameworks', 'Techniques', 'Tools', 'Platforms', 'Languages'];
    return quadrants[Math.ceil(order) + 1]
  }

  useEffect(() => {
    (async () => {
      const skillItems: TSkill[] = await fetchTechRadarSkills();
      if (skillItems) {
        const skills = skillItems.map((i: any, index: any) => {
          return {
            ...i,
            quadrant: !!i.quadrant && i.quadrant !== undefined ? i.quadrant : getQuadrant(index, skillItems.length),
            core: i.core ? 1 : 0,
            ring: parseInt(i.ring),
            uv: i.uv
          }
        });
        setTechRadarSkills(skills);
        setSourceSkills(skills);
      }
    })();
  }, []);

  useEffect(() => {
    if (core && nonCore) {
      return setTechRadarSkills(sourceSkills);
    }
    if (core) {
      const selectedSkills = sourceSkills.filter((skill) => skill.core === 1);
      return setTechRadarSkills(selectedSkills);
    }
    if (nonCore) {
      const selectedSkills = sourceSkills.filter((skill) => skill.core === 0);
      return setTechRadarSkills(selectedSkills);
    }
    setTechRadarSkills(sourceSkills);
  }, [core, nonCore])

  useEffect(() => {
    toggleSelectedSkills();
  }, [techRadarSkills, quad]);

  function toggleSelectedSkills() {
    const selectedSkills = techRadarSkills.filter((skill) => skill.quadrant === quad);
    setSelectedSkills(selectedSkills);
  }

  return (
    <RadarDataContext.Provider
      value={{
        skills: techRadarSkills,
        quad,
        setQuad,
        selectedSkills,
      }}
    >
      <DashboardLayout>
        <DashboardNavbar />
        <Styled.Container>
          <Styled.PieChartContainer>
            <PieCharDescription />
            <Styled.SkillFilterContainer>
              <FormGroup>
                <FormControlLabel
                  control={<MDIOSSwitch sx={{ m: 1 }} checked={core} onChange={(e, value) => setCore(value)}/>}
                  label="Core"
                  classes={{ label: core ? "!text-[12px] !font-bold !text-[#9F9F9F]" : "!text-[12px] !font-medium !text-[#9F9F9F]",
                    root: 'flex items-center'
                  }}
                />
                <FormControlLabel
                  control={<MDIOSSwitch sx={{ m: 1 }} checked={nonCore} onChange={(e, value) => setNonCore(value)} />}
                  label="Non-Core"
                  classes={{ root: 'flex items-center', 
                    label: nonCore ? "!text-[12px] !font-bold !text-[#9F9F9F]" :  "!text-[12px] !font-medium !text-[#9F9F9F]"
                  }}
                />
              </FormGroup>
            </Styled.SkillFilterContainer>
            {/* <Styled.ZoomWrapper></Styled.ZoomWrapper> */}
            <Chart />
          </Styled.PieChartContainer>
          <Styled.LanguageContainer>
            <BarChart />
          </Styled.LanguageContainer>
        </Styled.Container>
      </DashboardLayout>
    </RadarDataContext.Provider>
  );
};

export default TechRadar;

