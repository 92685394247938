import OnBoardLogo from 'assets/images/svg/onboard.svg';
import ProjectLogo from 'assets/images/svg/projects.svg';
import AnalysisLogo from 'assets/images/svg/analysis.svg';
import ElasticFinancialsLogo from 'assets/images/svg/elastic_financial.svg';
import RolesLogo from 'assets/images/svg/roles.svg';
import SkillsLogo from 'assets/images/svg/skills.svg';
import CoreTeamLogo from 'assets/images/svg/core_team.svg';
import Search from 'assets/images/svg/engineer/expert_finder.svg';
import TechRadarLogo from 'assets/images/svg/tech_radar.svg';
import LogoutLogo from 'assets/images/svg/logout_icon.svg';
import CategoryLogo from 'assets/images/svg/engineer/total_skill.svg';

let techRadar;

const links: { key: string; title: string; image: any; link: string, disabled?: boolean }[] = [
  {
    key: 'onboard',
    title: 'OnBoard',
    image: OnBoardLogo,
    link: '/onboard',
    disabled: false,
  },
  {
    key: 'elastic_talent_board',
    title: 'Elastic Talent Finder',
    image: Search,
    link: '/elastic_talent_board',
    disabled: true,
  },
  
  {
    key: 'elastic_talent_board_plus',
    title: 'Elastic Talent Finder+',
    disabled: true,
    image: ProjectLogo,
    link: '/elastic_talent_board_plus',
  },
  {
    key: 'projects',
    title: 'Projects',
    image: ProjectLogo,
    link: '/projects',
    disabled: false,
  },
  {
    key: 'analysis',
    title: 'Analysis',
    image: AnalysisLogo,
    link: '/analysis',
    disabled: false,
  },
  {
    key: 'elastic_team_roll_off',
    title: 'Elastic Team Roll Off',
    image: ElasticFinancialsLogo,
    link: '/elastic_team_roll_off',
    disabled: true,
  },
  {
    key: 'roles',
    title: 'Roles',
    image: RolesLogo,
    link: '/roles',
    disabled: false,
  },
  {
    key: 'skills',
    title: 'Skills',
    image: SkillsLogo,
    link: '/skills',
    disabled: false,
  },
  {
    key: "skill_categories",
    title: "Skill Categories",
    image: CategoryLogo,
    link: "/skill_categories",
    disabled: false,
  },
  {
    key: 'core_team',
    title: 'Core Team',
    image: CoreTeamLogo,
    link: '/core_team',
    disabled: false,
  },
  {
    key: 'external_talent_board',
    title: 'New Applicants',
    image: CoreTeamLogo,
    link: '/external_talent_board',
    disabled: false,
  },
  {
    key: "logout",
    title: "Log out",
    disabled: false,
    image: LogoutLogo,
    link: "/logout",
  }
];

export default links;
