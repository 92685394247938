import { useEffect, useState } from 'react';
import CurrencyRate from './currencyRate';
import { CurrencyResponse } from 'new-app/models/profile';
import { getCurrencies, updateCurrency } from 'new-app/reducers/candidateSearch';
import Notification from 'new-app/components/notification';
import { getCurrencyUnit } from './utils';
import Loader from './loader';
import { CurrencyExchangeIcon } from 'assets/icons';

interface ILoader {
    [key: number]: boolean;
}

export default function ExchangeRate() {
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState<ILoader>({});
    const [currencyList, setCurrencyList] = useState<CurrencyResponse[]>([]);
    const [notification, setNotification] = useState({
        message: '',
        severity: '',
        open: false
      });

    const getCurrencyList = async (init=false) => {
        !init && setLoading(true);
        try {
            const res = await getCurrencies();
            setCurrencyList(res.filter( i => i.code === 'USD' || i.code === 'EUR'));
        } catch {
            setNotification({
                message: 'Failed to fetch exchange rates',
                severity: 'error',
                open: true
            })
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getCurrencyList();
    }, [])

    const onCloseNotfication = () => {
        setNotification({
          message: '',
          severity: '',
          open: false
        })
    }

    const onSave = async (currency: CurrencyResponse, conversionRate: number) => {
        setIsSaving({[currency?.id]: true});
        try {
            await updateCurrency(currency?.id, conversionRate);
            setNotification({
                message: `GBP (£) to ${currency?.code} (${getCurrencyUnit(currency?.code)}) exchange rate has been updated`,
                severity: 'success',
                open: true
            })
        } catch {
            setNotification({
                message: 'Failed to update exchange rate',
                severity: 'error',
                open: true
            })
        } finally {
            setIsSaving({[currency?.id]: false});
            getCurrencyList(true);
        }
    }

    return (
        <div>
            <p className='text-[24px] font-semibold text-cool-900 leading-8 font-poppins'>Company Exchange Rate</p>
            {isLoading ? <Loader /> : (
                <div className='mt-8 flex flex-wrap'>
                    {currencyList.map((item) => (
                        <CurrencyRate
                            rate={item}
                            isLoading={isSaving[item?.id as keyof ILoader]}
                            onSave={onSave}
                        />
                    ))}
                    {currencyList.length === 0 ? (
                        <div className='flex justify-center w-full items-center flex-col gap-6 mt-12'>
                          <CurrencyExchangeIcon color='#707a89' />
                          <p className='text-center text-[22px] text-cool-600 font-poppins font-semibold leading-10'>
                              This is where you manage exchange rate
                          </p>
                          <p className='text-center text-[18px] text-cool-600 font-poppins font-semibold leading-6'>
                              You don’t currently have one. <br />
                          </p>
                      </div>
                    ) : null}
                </div>
            )}
            <Notification
                onClose={onCloseNotfication}
                {...notification}
            />
        </div>
    );
}