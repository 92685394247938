import { useEffect, useState, useMemo } from 'react';
import { Assessment } from 'new-app/models/assessement';
import {
    getTechnicalTests,
    createTechnicalTests,
    updateTechnicalTests,
    deleteTechnicalTest,
} from 'new-app/reducers/technicalTest';
import Notification from 'new-app/components/notification';
import { BigAssessmentIcon, SearchIcon, CloseIcon1, ActionIcon } from 'assets/icons';
import Button from 'new-app/components/button';
import AssessmentDialog from './assessmentDialog'
import { fetchSkills } from 'new-app/reducers/skill';
import Table from 'new-app/components/customTable';
import { ISkillList, ISkillItem } from 'interfaces';
import Input from 'new-app/components/input';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import MenuPopup from './menuPopup';
import CircularProgress from '@mui/material/CircularProgress';
import AssessmentDeleteModal from './deleteAssessmentModal';

interface IObjectKeys {
    [key: string]: any;
}

interface TableHeaderTypes extends IObjectKeys {
    name: string;
    description: string;
    link: string;
    createdAt: string;
    skillIds: string;
    inviteCount: string;
    actions: string;
}

const tableHeaders: TableHeaderTypes = {
    name: 'Test Name',
    description: 'Description',
    link: 'Invite Link',
    createdAt: 'Date Added',
    skillIds: 'Skills',
    inviteCount: 'Count',
    actions: ' '
};
const tabClasses={
    root: '!text-[14px] !normal-case !min-w-fit !font-semibold text-[#555E6D] !font-notosans !leading-5 !pl-0 !pr-0 !mr-4',
    selected: '!text-[#144FE7]'
};

const tabPanelClasses={
    root: '!p-0 !pt-6',
};



export default function ExchangeRate() {
    const [isLoading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [tabValue, setTabValue] = useState('1');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAssessment, setSelectedAssessment] = useState<Assessment | null>(undefined);
    const [assessmentList, setAssessmentList] = useState<Assessment[]>([]);
    const [skills, setSkills] = useState<any[]>([]);
    const [tableSortFilter, setTableSortFilter] = useState(null);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    const [notification, setNotification] = useState({
        message: '',
        severity: '',
        open: false
    });
    const tableHeader = Object.values(tableHeaders).map((heading, index) => {
        return {
          title: heading,
          leftAlign: index <= 2,
        }
    });

    const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, assessement: Assessment) => {
        setAnchorEl(event.currentTarget);
        setSelectedAssessment(assessement);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
        setSelectedAssessment(null);
    };

    const getAllSkills = async () => {
        try {
          const data: ISkillList = await fetchSkills({
            limit: 1000,
          });
          const temp = data.items.map((p: ISkillItem) => ({
            id: p.id,
            label: p.displayName,
          }));
          setSkills(temp);
        } catch (error: any) {
            setNotification({
                message: 'Failed to fetch skills',
                severity: 'error',
                open: true
            })
        }
    };

    const getAssessmentList = async (init=false) => {
        !init && setLoading(true);
        try {
            const res = await getTechnicalTests();
            setAssessmentList(res);
        } catch {
            setNotification({
                message: 'Failed to fetch assessments',
                severity: 'error',
                open: true
            })
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAssessmentList();
        getAllSkills();
    }, [])

    const getSortedData = (sortData: Assessment[], sortFilters: Map<string, number>) => {
        let sortedData = [...sortData]
        if (!sortFilters) {
            return sortedData;
        }
        for (const [tableKey, value] of sortFilters) {
            let key: string = tableKey;
            for (let property in tableHeaders) {
                if (tableHeaders[property as keyof IObjectKeys] === tableKey) {
                    key = property;
                }
            }
            sortedData = sortedData.sort((a, b) => {
                if (typeof a[key as keyof Assessment] === "string") {
                    return value * ((b[key as keyof Assessment] || "").toString().localeCompare((a[key as keyof Assessment] || "").toString()))
                } else if (typeof a[key as keyof Assessment] === "number") {
                    return value * parseInt((b[key as keyof Assessment] || 1).toString()) - value * parseInt((a[key as keyof Assessment] || 1).toString())
                } else if (key === "skillIds") {
                    const skillListA = skills.filter(i => a["skillIds"].includes(i.id)).map(i => i.label);
                    const skillListB = skills.filter(i => b["skillIds"].includes(i.id)).map(i => i.label);
                    return value * ((skillListB.join(',')).localeCompare(skillListA.join(',')))
                } else if (key === "createdAt") {
                    return value === 1 ? new Date(b[key]) > new Date(a[key]) ? 1 : -1 : new Date(a[key]) > new Date(b[key]) ? 1 : -1
                }
                return 1;
            });
        }
        return sortedData;
      }

    const onSortClick = (data: Map<string, number>) => {
        setTableSortFilter(data);
    }

    const handleChange = (value: string, field: string) => {
        setSelectedAssessment({
          ...selectedAssessment,
          [field]: value,
        });
      };

    const onCloseNotfication = () => {
        setNotification({
          message: '',
          severity: '',
          open: false
        });
    }

    const onAddNewTest = () => {
        setOpenDialog(true);
        setSelectedAssessment(null);
    }

    const onSave = async () => {
        setIsSaving(true);
        try {
            if (!!selectedAssessment && selectedAssessment.id) {
                const data = { ...selectedAssessment};
                delete data.id;
                await updateTechnicalTests(selectedAssessment.id, data)
                setNotification({
                    message: `Assessment has been updated successfully`,
                    severity: 'success',
                    open: true
                })
            } else {
                await createTechnicalTests(selectedAssessment);
                setNotification({
                    message: `Assessment has been created successfully`,
                    severity: 'success',
                    open: true
                })
            }
        } catch {
            setNotification({
                message: 'Failed to update assessment',
                severity: 'error',
                open: true
            })
        } finally {
            setIsSaving(false);
            setOpenDialog(false);
            getAssessmentList(true);
        }
    }

    function getTableData() {
        function renderSkills (skillIds: number[]) {
            const skillList = skills.filter(i => skillIds.includes(i.id)).map(i => i.label);
            return skillList.join(',')
        }
        let data = tabValue === '1' ? getSortedData(assessmentList.filter(i => !i.deprecatedAt), tableSortFilter) : getSortedData(assessmentList.filter(i => !!i.deprecatedAt), tableSortFilter) 

        const isIncludesSearch = (item: any) => {
            let isIncludes = false;
            item['skills'] = renderSkills(item?.skillIds);
            for (const i in item) {
                isIncludes = (item[i] || "").toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
                if (isIncludes) break;
            }
            return isIncludes;
        }
        
        if (searchTerm) {
            data = data.filter(i => {
                return isIncludesSearch(i)
            });
        }
        const tableRows = data.map((assessment, index) => {
            function renderActionMenu() {
                if (tabValue !== '1') return;
                return (
                    <div className='flex justify-end pr-4'>
                        <span onClick={(event: any) => onOpenMenu(event, assessment)}>
                            <ActionIcon />
                        </span>
                    </div>
                );
            }
          const tableRow = new Map<string, JSX.Element>([
            [
              tableHeaders.name,
              <div className="flex items-center">
                <span className="flex text-left">{assessment?.name || 'N/A'}</span>
              </div>
            ],
            [
              tableHeaders.description,
              <div className="flex items-center">
                <span className="flex text-left">{assessment?.description || 'N/A'}</span>
              </div>
            ],
            [
              tableHeaders.link,
              <span className="block text-left">
                <a href={assessment?.link ?? ''} target='_blank'>{assessment?.link ?? ''}</a>
                </span>,
            ],
            [
              tableHeaders.createdAt,
              <span className="flex justify-center">{assessment?.createdAt ? moment(assessment?.createdAt).format('DD.MM.YY') : ''}</span>,
            ],
            [
              tableHeaders.skillIds,
              <span className="flex justify-center">{renderSkills(assessment?.skillIds)}</span>,
            ],
            [tableHeaders.inviteCount,<span className="flex justify-center">{assessment?.inviteCount || 0}</span>],
            [tableHeaders.actions, renderActionMenu()],
          ]);
        
          return tableRow;
        });
        return tableRows;
      }
    

    const tableData = useMemo(() => getTableData(), [assessmentList, tableSortFilter, tabValue, searchTerm]);

    const renderEmptyState = () => {
        return (
            <div className='flex justify-center w-full items-center flex-col gap-6 mt-12'>
                <BigAssessmentIcon />
                <p className='text-center text-[24px] text-cool-600 font-poppins font-semibold leading-10'>
                    Manage Assessments
                </p>
                <p className='text-center text-[16px] text-cool-600 font-inter font-normal leading-6'>
                    You don’t currently have one. <br />
                    Create a test in coderbytes and add a record here
                </p>
                <Button
                    label='+ Add New Test'
                    className='!px-6 !py-2 !font-semibold'
                    onClick={onAddNewTest}
                />
            </div>
        )
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const onEdit = () => {
        setOpenDialog(true);
        setAnchorEl(null);
    }

    const onDuplicate = () => {
        setOpenDialog(true);
        const newAssessment = {...selectedAssessment};
        delete newAssessment['id'];
        setSelectedAssessment(newAssessment);
        setAnchorEl(null);
    }

    const onDeprecate = async () => {
        setAnchorEl(null);
        try {
            setIsSaving(true);
            await deleteTechnicalTest(selectedAssessment?.id)
            setNotification({
                message: `Successfully deprecated assessment`,
                severity: 'success',
                open: true
            })
        } catch {
            setNotification({
                message: 'Failed to deprecate assessment',
                severity: 'error',
                open: true
            })
        } finally {
            getAssessmentList(true);
            setIsSaving(false);
        }
    }

    const onDelete = () => {
        setAnchorEl(null);
        setOpenDeleteDialog(true);
    }

    const onDeleteAssessment = async () => {
        try {
            setIsSaving(true);
            await deleteTechnicalTest(selectedAssessment?.id)
            setNotification({
                message: `Successfully deleted assessment`,
                severity: 'success',
                open: true
            })
        } catch {
            setNotification({
                message: 'Failed to delete assessment',
                severity: 'error',
                open: true
            })
        } finally {
            setIsSaving(false);
            setOpenDeleteDialog(false);
            getAssessmentList(true);
        }
    }

    function Loader() {
        return (
            <div className='h-[300px] w-full bg-red flex items-center justify-center' style={{ height: 'calc(100vh - 200px'}}>
                <CircularProgress/>
            </div>
        )
    }

    return (
        <div className='p-2'>
            <div className='flex justify-between items-center'>
                <p className='text-[24px] font-semibold text-cool-900 leading-8 font-poppins'>Manage Assessments</p>
                {assessmentList.length !== 0 && !isLoading ? (
                    <Button
                        label='+ Add New Test'
                        className='!px-6 !py-2 !font-semibold'
                        onClick={onAddNewTest}
                    />
                ) : null}
            </div>
            {isLoading ? <Loader /> : (
                <div className='mt-[14px] w-full'>
                    {assessmentList.length === 0 ? renderEmptyState() : (
                         <TabContext value={tabValue}>
                            <div className='flex justify-between items-center w-full'>
                                <TabList onChange={handleTabChange} aria-label="lab API tabs" classes={{ indicator: '!bg-[#144FE7]', }}>
                                    <Tab label="Active" value="1" classes={tabClasses} />
                                    <Tab label="Deprecated" value="2" classes={tabClasses} />
                                </TabList>
                                <div className='max-w-[320px]'>
                                    <div className='relative'>
                                        <Input
                                            placeholder='Search...'
                                            className='!text-cool-900 !min-w-[320px]'
                                            icon={<SearchIcon />}
                                            value={searchTerm}
                                            onChange={(e: any) => setSearchTerm(e.target.value)}
                                        />
                                        {searchTerm ? (
                                            <span className='absolute cursor-pointer right-4 top-3' onClick={() => setSearchTerm('')}>
                                                <CloseIcon1 />
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <TabPanel value={tabValue} classes={tabPanelClasses}>
                                <div className='h-full' style={{ height: 'calc(100% - 150px)'}}>
                                    <Table
                                        onHoverChangeColor
                                        headings={tableHeader.filter(i => Boolean(i.title))}
                                        data={tableData}
                                        headerClasses='font-inter text-[12px] leading-4 !text-cool-700 !font-semibold'
                                        onSortClick={onSortClick}
                                    />
                                </div>
                            </TabPanel>
                        </TabContext>
                    )}
                </div>
            )}
            <MenuPopup
                open={open}
                handleClose={handleClose}
                anchorEl={anchorEl}
                isDeprecated={tabValue === '2'}
                onEdit={onEdit}
                onDuplicate={onDuplicate}
                onDeprecate={onDeprecate}
                onDelete={onDelete}
            />
            <Notification
                onClose={onCloseNotfication}
                {...notification}
            />
            <AssessmentDialog
                skills={skills}
                assessment={selectedAssessment}
                open={openDialog}
                handleChange={handleChange}
                handleClose={() => setOpenDialog(false)}
                isLoading={isSaving}
                onSave={onSave}
            />
            <AssessmentDeleteModal
                assessment={selectedAssessment}
                isOpen={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onDelete={onDeleteAssessment}
                isLoading={isSaving}
            />
        </div>
    );
}