import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import FormSelect from '../FormSelect';
import axios from 'utils/axios';
import FormField from '../FormField';
import { ProjectTypes, PropositionTypes } from 'interfaces/enums';
import initialValues, {
  FormValueType,
  ProjectDetailType,
} from 'pages/onboard/schemas/initialValues';
import MDButton from 'components/MDButton';
import { FormType } from 'pages/onboard/schemas/form';
import { FormikProps } from 'formik';
import { Button, Icon } from '@mui/material';
import { differenceInDays } from 'date-fns';

export type ProjectProps = {
  formData: FormikProps<FormValueType> & {
    formField: FormType;
    readonly: boolean;
  };
  projects: {
    _id: string;
    name: string;
  }[];
  clients: string[];
};

function fixProjectIdField(projectDetailV: Array<ProjectDetailType>) {
  return projectDetailV.map((item) => {
    if (typeof item.projectId === 'string') {
      return {
        ...item,
        projectId: {
          name: item.name,
          _id: item.refID,
        },
      };
    } else {
      return {
        ...item,
        name: item.projectId.name,
      };
    }
  });
}

function Project({ formData, projects, clients }: ProjectProps): JSX.Element {
  const { formField, values, errors, touched, setFieldValue, readonly } =
    formData;

  const { projectDetail } = formField;
  const { projectDetail: projectDetailVUnsorted } = values;
  const [expanded, setExpanded] = useState(false);

  const projectDetailV = (
    projectDetailVUnsorted as Array<ProjectDetailType>
  ).sort((a: ProjectDetailType, b: ProjectDetailType) => {
    return (
      (Date.parse(b.endDate) - Date.parse(a.endDate)) * 2 +
      (Date.parse(b.startDate) - Date.parse(a.startDate))
    );
  });

  const addProject = () => {
    setFieldValue(projectDetail.name, [
      ...projectDetailV,
      (initialValues[projectDetail.name] as Array<ProjectDetailType>)[0],
    ]);
  };
  const removeProject = () => {
    projectDetailV.pop();
    setFieldValue(projectDetail.name, projectDetailV);
  };
  useEffect(() => {
    if (projectDetailV.length === 0)
      setFieldValue(projectDetail.name, initialValues[projectDetail.name]);
  }, [projectDetailV]);

  const getProjectBackgroundColor = (index: number) => {
    if (index % 2 === 0) {
      return 'auto';
    }
    return '#f0f2f5';
  };
  const getCompletedProjects = () => {
    return projectDetailV
      .filter((project: any) => Date.parse(project.projectEnd) <= Date.now())
      .length.toString();
  };
  const getAggregatedTenure = () => {
    return projectDetailV
      .filter((project) => project.startDate && project.endDate)
      .reduce((total, project) => {
        const startDate = new Date(project.startDate);
        const endDate = new Date(project.endDate);
        const diffDays = differenceInDays(endDate, startDate);
        return total + diffDays;
      }, 0);
  };

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid
          container
          paddingLeft={4}
          paddingRight={4}
          paddingTop={2}
          marginBottom={2}
          style={{
            backgroundColor: '#f0f2f5',
          }}
        >
          <Grid item xs={12} sm={6}>
            <FormField
              disabled={true}
              type="text"
              name="generated"
              label="Total Projects Completed"
              value={getCompletedProjects()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              disabled={true}
              type="text"
              name="generated"
              label="Aggregated Tenure (total days worked on all projects)"
              value={getAggregatedTenure()}
            />
          </Grid>
        </Grid>
        {projectDetailV.map((detail: any, index: number) => {
          if (!expanded && index > 0) return null;
          if (!detail._id || !detail.name) return null;

          return (
            <Grid
              container
              marginLeft={1}
              paddingLeft={2}
              paddingRight={3}
              paddingTop={1}
              paddingBottom={1}
              marginBottom={2}
              spacing={1}
              key={index}
              style={{
                backgroundColor: getProjectBackgroundColor(index),
              }}
            >
              <Grid item xs={12} sm={6}>
                <FormSelect
                  disabled
                  setFieldValue={setFieldValue}
                  value={detail}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option: any) => option.name}
                  name={`${projectDetail.name}.${index}.projectId`}
                  label={projectDetail.projectId.label}
                  options={projects.filter((p) => p._id && p.name)}
                />
              </Grid>
              {detail.projectId && detail._id && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormSelect
                      disabled
                      setFieldValue={setFieldValue}
                      value={detail.projectClient}
                      name={projectDetail.projectClient.name}
                      label={projectDetail.projectClient.label}
                      options={clients}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled
                      type={projectDetail.startDate.type}
                      label={projectDetail.startDate.label}
                      name={`${projectDetail.name}.${index}.${projectDetail.startDate.name}`}
                      value={detail?.startDate?.split('T')[0] ?? null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      disabled
                      type={projectDetail.endDate.type}
                      label={projectDetail.endDate.label}
                      name={`${projectDetail.name}.${index}.${projectDetail.endDate.name}`}
                      value={detail?.endDate?.split('T')[0] ?? null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormSelect
                      disabled
                      setFieldValue={setFieldValue}
                      value={detail.projectType}
                      name={`${projectDetail.name}.${index}.${projectDetail.projectType.name}`}
                      label={projectDetail.projectType.label}
                      options={[ProjectTypes.ONBOARD, ProjectTypes.REMOTE]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormSelect
                      disabled
                      setFieldValue={setFieldValue}
                      value={detail.proposition}
                      name={projectDetail.proposition.name}
                      label={projectDetail.proposition.label}
                      options={Object.values(PropositionTypes)}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
      {projectDetailV.length > 1 && (
        <Grid item xs={12} textAlign="center">
          <Button onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Hide' : 'Show More'}
          </Button>
        </Grid>
      )}
    </MDBox>
  );
}

export default Project;
