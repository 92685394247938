import moment from "moment";

export const availabilityLists = [
    {
        value: moment(new Date()).toISOString(true),
        title: 'Available now',
      },
      {
        value: moment(new Date()).add(7, 'days').toISOString(true),
        title: '1-2 weeks',
      },
      {
        value: moment(new Date()).add(21, 'days').toISOString(true),
        title: '3-4 weeks',
      },
];
  