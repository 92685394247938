import moment from 'moment';
import InputComponent from 'new-app/components/input';
import ButtonComponent from 'new-app/components/button';
import { Shortlist } from 'new-app/models/profile';
import { SmallEditIcon, DeleteIcon, CloseIcon1, MinusIcon } from 'assets/icons';

export interface ShortlistStatusProps {
    onRename: () => void;
    onChange: (name: string) => void;
    onRemove: () => void;
    onDelete: () => void;
    onSave: () => void;
    onRemoveSelection: () => void;
    onSetUpdateMode: () => void;
    selectedShortlist: Shortlist;
    selected: any;
    isUpdateMode: boolean;
    shortListName: string;
    isLoading?: boolean;
}

export default function ShortlistStatus(props: ShortlistStatusProps) {
    const { selected, selectedShortlist, shortListName, isLoading, isUpdateMode, onSave, onRemoveSelection, onRemove, onDelete, onRename, onSetUpdateMode } = props;
    const onChange = (e: any) => {
        props.onChange(e.target.value);
    }
    return (
        <div>
            {isUpdateMode ? (
                <div className='flex justify-between items-center flex-row'>
                    <div className='flex flex-col gap-y-1 w-[640px] max-w-[100%]'>
                        <InputComponent
                            value={shortListName}
                            onChange={onChange}
                            placeholder='Enter shortlist name'
                            className='!h-[48px] !text-cool-900 !text-[14px] !font-normal !py-[14px]'
                        />
                    </div>
                    <div className='flex flex-row gap-4'>
                        <ButtonComponent
                            className='!bg-grey-100 hover:!bg-grey-200 active:!bg-grey-400 !text-[#666666] text-[14px] !h-10'
                            label='Cancel'
                            onClick={onSetUpdateMode}
                        />
                        <ButtonComponent
                            className='!h-10 hover:!bg-marine-600 active:!bg-marine-800'
                            label='Save'
                            onClick={onSave}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            ) : (
                <div className='flex justify-between items-center flex-row'>
                    <div className='flex flex-col gap-y-1'>
                        <p className="text-cool-900 font-semibold leading-5 font-inter text-[16px] mb-2">
                            {selectedShortlist?.name}
                        </p>
                        <div className='flex flex-row gap-4'>
                            <p className="text-cool-600 font-normal font-inter text-[12px]">
                                Created {selectedShortlist?.createdAt ? moment(selectedShortlist?.createdAt).format('MMMM DD, YYYY') : 'N/A'}
                            </p>
                            <p className="text-cool-600 font-normal font-inter text-[12px]">
                                Last modified {selectedShortlist?.updatedAt ? moment(selectedShortlist?.updatedAt).format('MMMM DD, YYYY') : 'N/A'}
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-row gap-4'>
                        <ButtonComponent
                            className='!bg-[white] hover:!bg-marine-50 hover:!border-marine-600 active:!bg-marine-100 active:!border-marine-800 !border !border-marine-500 !text-marine-500 !text-[12px] !h-8'
                            icon={<SmallEditIcon color={'#144FE7'} />}
                            label='Rename'
                            onClick={onRename}
                        />
                        <ButtonComponent
                            className='!bg-error-500 hover:!bg-error-600 active:!bg-error-800 !text-[white] !text-[12px] !h-8'
                            icon={<DeleteIcon color='white' />}
                            label='Delete'
                            onClick={onDelete}
                        />
                    </div>
                </div>
            )}
            {selected.size > 0 ? (
                <div className='flex flex-row justify-between items-center mt-[22px]'>
                    <div className='flex flex-row gap-4 items-center'>
                        <span className='cursor-pointer' onClick={onRemoveSelection}>
                            <MinusIcon />
                        </span>
                        <p className="text-cool-900 font-semibold leading-5 font-inter text-[14px]">
                            {selected.size || 0} {selected.size === 1 ? 'Candidate' : 'Candidates'} Selected
                        </p>
                    </div>
                    <ButtonComponent
                        className='!bg-[white] !border !border-marine-500 !text-marine-500 !text-[12px] !h-8'
                        icon={<CloseIcon1 color={'#144FE7'} />}
                        label='Remove From Shortlist'
                        onClick={onRemove}
                        isLoading={isLoading}
                    />
                </div>
            ) : (
                <div className='flex flex-row justify-between items-center mt-[22px]'>
                    {selectedShortlist?.userIds?.length > 0 ? (
                        <p className="text-cool-900 font-semibold leading-5 font-inter text-[14px]">
                            {selectedShortlist?.userIds?.length || 0} {selectedShortlist?.userIds?.length === 1 ? 'Candidate' : 'Candidates'}
                        </p>
                    ) : (
                        <p className="text-cool-600 font-normal leading-6 font-inter text-[16px]">
                            No candidates have been added to this shortlist.
                        </p>
                    )}
                </div>
            )}
        </div>
    );
}
