import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import MDButton from 'components/MDButton';
import Icon from '@mui/material/Icon';
import DataTable from '../DataTable';
import { useBackdrop } from 'context/backdrop';
import NewDialog from './NewDialog';
import { toast } from 'react-toastify';
import { ISkill, ISkillList, SKillCategoryList, SkillCategoryDetail, ISkillItem } from 'interfaces';
import axios from 'utils/axios';
import { confirm } from 'react-confirm-box';
import { fetchCategories } from 'new-app/reducers/skillCategories';
import { fetchSkills, updateSkills } from 'new-app/reducers/skill';
import MultipleSelectCheckmarks from 'components/MDSelect';
import { styled } from '@mui/material/styles';

const ResetButton = styled('button')`
  border: 1px solid #ced4da;
  padding: 1px 20px;
  height: 36px;
  line-height: 13px;
  maring-top: 4px;
  margin-left: 8px;
  border-radius: 7px;
  &:disabled {
    opacity: 0.6;
  }
` 

const coreItems = [
  {value: 'Core', title: 'Core'},
  {value: 'Non Core', title: 'Non Core'},
];
const visibleItems = [
  {value: 'Visible', title: 'Visible'},
  {value: 'Invisible', title: 'Invisible'},
];

function Skill(): JSX.Element {
  const [openDialog, setOpenDialog] = useState(false);
  const [editingSkill, setEditingSkill] = useState<ISkillItem>(undefined);
  const [skills, setSkills] = useState<ISkillItem[]>([]);
  const [filteredSkills, setFilteredSkills] = useState<ISkillItem[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const { fetching } = useBackdrop();
  const [search, setSearch] = useState('');
  const [filterFlag, setFilterFlag] = useState(false);
  const [coreFilter, setCoreFilter] = useState<string[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<number[]>([]);
  const [visibleFilter, setVisibleFilter] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>();
  const [categoryItems, setCategoryItems] = useState<SkillCategoryDetail[]>();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCore, setSelectedCore] = useState(null);
  const [selectedVisible, setSelectedVisible] = useState(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [actionValue, setActionValue] = useState(null);

  useEffect(() => {
    (async () => {
      const skillCategories: SKillCategoryList = await fetchCategories();
      setCategoryItems(skillCategories.items);
      setCategories(skillCategories.items.map((category) => category.name));
    })();
  }, []);

  const handleOpenNew = () => {
    setOpenDialog(true);
    setEditingSkill(undefined);
  };
  const handleSkillChange = (value: string, field: string) => {
    setEditingSkill({
      ...editingSkill,
      [field]: value,
    });
  };

  const saveSkill = async () => {
    try {
      if (!editingSkill || !editingSkill.name) {
        toast.warn('Please Input Skill Name!');
        return;
      }
      if (editingSkill.category) {
        editingSkill.categoryId = categoryItems.find(i => i.name === editingSkill.category)?.id;
      }
      setSaving(true);
      const params = {
        isCore: !!editingSkill.isCore,
        visible: !!editingSkill.visible,
        ids: [editingSkill.id],
        categoryId: editingSkill.categoryId,
        description: editingSkill.description,
        displayName: editingSkill.displayName,
      }
      await updateSkills(params);
      toast.success('Saved a Skill Successfully!');
      setOpenDialog(false);
      setSearch('');
      getAllSkills();
    } catch (error: any) {
      toast.warn(error.message);
    } finally {
      setSaving(false);
    }
  };

  const updateSkill = async (actionValue: any) => {
    try {
      setSaving(true);
      const params: any = {
        ids: selectedRows,
      };
      if (typeof actionValue === 'number') {
        params['categoryId'] = actionValue
      } else if (typeof actionValue === 'string') {
        if (actionValue === 'Core' || actionValue === 'Non Core') {
          params['isCore'] = actionValue === 'Core'
        }
        if (actionValue === 'Visible' || actionValue === 'Invisible') {
          params['visible'] = actionValue === 'Visible'
        }
      }
      await updateSkills(params);
      toast.success('Updated Skills Successfully!');

    } catch (error: any) {
      toast.warn(error.message);
    } finally {
      setSaving(false);
    }
  }

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setCurrentPage(0);
      getAllSkills();
    }
  };

  const handleSearchChange = (e: any) => {
    let temp = skills;
    setSearch(e.target.value);
    temp = temp.filter(
      (t: ISkillItem) =>
        t.name.includes(e.target.value) ||
        t.description.includes(e.target.value)
    );
    setFilteredSkills(temp);
  };

  const handleEdit = (skill: ISkillItem) => {
    setEditingSkill(skill);
    setOpenDialog(true);
  };

  const onSelectCore = (coreOptions: string[]) => {
    setCoreFilter(coreOptions)
    let temp = skills;
    if (coreOptions.length === 1) {
      setSelectedCore(coreOptions[0] === "Core")
    } else {
      setSelectedCore(null);
    }
  }

  const resetFilter = () => {
    setSelectedCore(null)
    setSelectedCategory(null)
    setSelectedVisible(null)
    setCategoryFilter([])
    setVisibleFilter([])
    setCoreFilter([])
  }
  const onSelectVisible = (visibleOptions: string[]) => {
    setVisibleFilter(visibleOptions)
    if (visibleOptions.length === 1) {
      setSelectedVisible(visibleOptions[0] === "Visible")
    } else {
      setSelectedVisible(null);
    }
  }
  const onSelectCategory = (categoryOptions: number[]) => {
    setCategoryFilter(categoryOptions)
  }

  const handleDelete = async (skill: ISkill, options: any) => {
    const result = await confirm('Are you sure to delete this skill?', options);
    try {
      if (result) {
        await axios.delete(`/skill/delete/${skill._id}`);
        toast.success('Deleted Skill Successfully!');
        getAllSkills();
      }
    } catch (error: any) {
      toast.warn(error.message);
    }
  };

  const getAllSkills = async () => {
    try {
      fetching(true);
      const data: ISkillList = await fetchSkills({
        limit: perPage,
        page: currentPage,
        search: searchKey,
        categoryIds: categoryFilter && categoryFilter.length > 0 ? categoryFilter : null,
        isCore: selectedCore,
        isVisible: selectedVisible
      });
      setCount(data.total);
      const temp = data.items.map((p: ISkillItem) => ({
        id: p.id,
        name: p.name,
        displayName: p.displayName,
        description: p.description,
        isCore: p.isCore ? 'Core' : 'Non Core',
        visible: p.visible ? 'Visible' : 'Invisible',
        category: p.category ? p.category : null,
        actions: (
          <MDBox display="flex" justifyContent="space-between">
            <MDButton
              color="info"
              style={{ marginRight: 3 }}
              iconOnly
              onClick={() => handleEdit(p)}
            >
              <Icon>edit</Icon>
            </MDButton>
          </MDBox>
        ),
      }));
      setSkills(temp);
      setFilteredSkills(temp);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      fetching(false);
    }
  };
  const isResetButtonDisabled = (coreFilter.length === 0 && visibleFilter.length === 0 && categoryFilter.length === 0);

  useEffect(() => {
    getAllSkills();
  }, [perPage, currentPage, selectedCore, selectedVisible, categoryFilter, saving]);

  const handleActionChange = (value: any) => {
    const selectedValue = Array.isArray(value) ? value[0] : value;
    setActionValue(selectedValue);
    updateSkill(selectedValue)
  }

  const Actions = () => {
    const actionItems: any = [
      {header: 'CATEGORY'},
      ...(categoryItems ? categoryItems.map((i: any) => ({value: i.id, title: i.name})) : []),
      {header: 'CORE/NON-CORE'},
      ...(coreItems),
      {header: 'VISIBLE/INVISIBLE'},
      ...(visibleItems)
    ]
    return (
      <div style={{marginLeft: '1rem'}}>
        <MultipleSelectCheckmarks
          items={actionItems}
          opacity={0.6}
          disabled={selectedRows.length === 0}
          label={'Actions'}
          value={actionValue}
          multiple={false}
          onChange={handleActionChange}
        />
      </div>
    )
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid item xs={12} mt={3}>
          <DataTable
            canSearch
            currentPage={currentPage}
            totalCount={count}
            perPage={perPage}
            handleSearch={handleSearch}
            setSearchKey={setSearchKey}
            setPerPage={setPerPage}
            setCurrentPage={setCurrentPage}
            checkboxSelection
            headerActions
            headerActionChildren={<Actions />}
            setSelectedRows={setSelectedRows}
            table={{
              columns: [
                { Header: 'Skill', accessor: 'displayName' },
                { Header: <MultipleSelectCheckmarks
                            items={categoryItems ? categoryItems.map((i: any) => ({value: i.id, title: i.name})) : []}
                            label={'Category'}
                            value={categoryFilter}
                            width={170}
                            onChange={onSelectCategory}
                            multiple
                          />,
                  accessor: 'category', sort: false },
                { Header: <MultipleSelectCheckmarks
                            multiple
                            items={coreItems}
                            label={'Core/Non-Core'}
                            width={150}
                            value={coreFilter}
                            onChange={onSelectCore}
                          />,
                  accessor: 'isCore', sort: false },
                { Header: (
                  <div style={{ display: 'flex',  justifyContent: 'space-between'}}>
                    <MultipleSelectCheckmarks
                      items={visibleItems}
                      multiple
                      label={'Visible/Invisible'}
                      width={150}
                      value={visibleFilter}
                      onChange={onSelectVisible}
                    />
                    <ResetButton
                      onClick={resetFilter}
                      disabled={isResetButtonDisabled}
                    >
                      Reset Filters
                    </ResetButton>
                  </div>),
                accessor: 'visible', sort: false },
                {
                  Header: 'Description',
                  accessor: 'description',
                  width: '20%',
                },
                { Header: 'Actions', accessor: 'actions', width: '15%' },
              ],
              rows: skills || [],
            }}
          />
        </Grid>
      </MDBox>
      <NewDialog
        categories={categories}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        skill={editingSkill}
        handleChange={handleSkillChange}
        save={saveSkill}
      />
    </DashboardLayout>
  );
}

export default Skill;
