import React from 'react';

export interface CardProps {
  children: React.ReactNode;
}

export default function (props: CardProps) {
  return (
    <React.Fragment>
      <div className="flex flex-col gap-3 p-6 bg-white rounded-lg">
        {props.children}
      </div>
    </React.Fragment>
  );
}
