import classNames from 'classnames';
import React from 'react';
import { ReactNode } from 'react';
import { UseFormRegister } from 'react-hook-form';

const styles = {
    base: `h-[48px] p-2 pr-10 bg-[#F2F5FE] inline-block md:py-[14px] text-center md:px-4 rounded-lg`,
    variants: {
      primary: `text-gray-2
          peer-checked:border-light-blue
          peer-checked:bg-primary-blue
          peer-checked:child:!text-[white]
          peer-checked:!text-[white]`,
    },
  };
  

export interface RadioCardProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name?: string;
  value?: any;
  icon?: ReactNode;
  title?: string;
  variant?: 'primary';
  register?: UseFormRegister<any>;
}


const RadioCard: React.FC<RadioCardProps> = (props) => {
  const { variant = 'primary', className, register, ...rest } = props;
  const radioCardStyles = classNames(
    styles.base,
    styles.variants[variant],
    className
  );
  return (
    <label className="group relative cursor-pointer">
      <input
        type="radio"
        className="group peer absolute opacity-0"
        name={props.name}
        onInput={props.onChange}
        {...rest}
        {...register?.(props?.name ?? '')}
      />
      <div className={radioCardStyles}>
        {props.icon && <div className='mb-1'>{props.icon}</div>}
        <p className="text-primary-blue select-none text-[14px] whitespace-nowrap font-inter font-semibold leading-5">{props.title}</p>
      </div>
    </label>
  );
};

export default RadioCard;
