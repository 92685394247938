import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Chip, CircularProgress, Grid, TextField } from '@mui/material';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import axios from 'utils/axios';
import { CodilityTest, ExternalUser, Evaluation } from './types';
import MDButton from 'components/MDButton';
import Select from './Select';
import React from 'react';

export type CodilitySessionResult = {
  user: ExternalUser;
  evaluations: Evaluation[];
  sessions: string[];
};

function ExternalUserBoard(): JSX.Element {
  const params = useParams();
  const [user, setUser] = useState<CodilitySessionResult>(null);
  const [codilityTests, setCodilityTests] = useState<CodilityTest[]>([]);
  const [testToSend, setTestToSend] = useState(0);
  const testToSendObj = codilityTests.find((test) => test.id === testToSend);
  const [sendingTest, setSendingTest] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setLoading] = useState(false);

  function fetchSessionData(page = 0, limit = 3) {
    const fetchData = async () => {
      setLoading(true);
      const searchParams = new URLSearchParams();
      searchParams.set('id', params.id);
      searchParams.set('page', `${page}`);
      searchParams.set('limit', `${limit}`);
      const { data } = await axios.get<CodilitySessionResult>(
        `/user/external_user/getOne?${searchParams}`
      );
      const existingEvaluation = [];
      if (user && user.evaluations) {
        existingEvaluation.push(...user.evaluations);
      }
      setUser({
        user: data.user,
        sessions: data.sessions,
        evaluations: [...existingEvaluation, ...data.evaluations],
      });
      setCurrentPage(page);
      setLoading(false);
    };
    fetchData().catch(console.error);
  }

  useEffect(() => {
    fetchSessionData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get<CodilityTest[]>(`/user/codility/tests`);
      setCodilityTests(data);
    };
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (codilityTests && codilityTests.length > 0) {
      setTestToSend(codilityTests[0].id);
    }
  }, [codilityTests]);

  const sendTestEmail = async () => {
    const urlParams = new URLSearchParams();
    urlParams.set('test', `${testToSend}`);
    urlParams.set('email', user.user.email);

    setSendingTest(true);
    await axios.post(`/user/codility/send?${urlParams.toString()}`);
    const { data } = await axios.get(
      `/user/external_user/getOne?id=${params.id}`
    );
    setUser(data);
    setSendingTest(false);
  };

  const updateUserAcademy = async (isAcademy: boolean) => {
    const response = await axios.post(
      `/user/external_user/updateAcademy?id=${params.id}`,
      {
        isAcademy,
      }
    );
    if (response.status !== 200) {
      throw new Error('failed to update user');
    }
    setUser({
      ...user,
      user: {
        ...user.user,
        isAcademy,
      },
    });
  };

  if (!user) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <div style={{ textAlign: 'center' }}>
          <CircularProgress color="primary" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Grid container p={2} spacing={2}>
                <Grid item xs={12}>
                  Applicant Information
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth={true}
                    variant="standard"
                    defaultValue={user.user.firstName}
                    label="First Name"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth={true}
                    variant="standard"
                    defaultValue={user.user.lastName}
                    label="Last Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth={true}
                    variant="standard"
                    defaultValue={user.user.location}
                    label="Location"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth={true}
                    variant="standard"
                    defaultValue={user.user.email}
                    label="Email"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth={true}
                    variant="standard"
                    defaultValue={new Date(
                      user.user.availablity
                    ).toDateString()}
                    label="Available On"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    fullWidth={true}
                    variant="standard"
                    defaultValue={new Date(user.user.createdAt).toDateString()}
                    label="Application date"
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <div className="mb-2 mx-4 flex items-center">
                  <input
                    type="checkbox"
                    id="isAcademy"
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      updateUserAcademy(isChecked);
                    }}
                    checked={user.user.isAcademy ?? false}
                  />
                  <label htmlFor="isAcademy" className="ml-2 text-sm">
                    Academy
                  </label>
                </div>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Grid container p={2} spacing={2}>
                <Grid item xs={12}>
                  Claimed Roles
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {user.user.roles.map((role, index) => (
                      <Grid item key={index}>
                        <Chip label={role.name} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Grid container p={2} spacing={2}>
                <Grid item xs={12}>
                  Claimed Skills
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {user.user.skills.map((skill, index) => (
                      <Grid item key={index}>
                        <Chip label={skill.name} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Grid container p={2} spacing={2}>
                <Grid item xs={12}>
                  Codility Assessments
                </Grid>
                <Grid item xs={12}>
                  <Select
                    options={codilityTests.map((test) => test.name)}
                    label="Choose a test to send"
                    placeholder="Choose a test to send"
                    value={testToSendObj?.name ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const test = codilityTests.find(
                        (test) => test.name === e.target.value
                      );
                      if (test) {
                        setTestToSend(test.id);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  {sendingTest && <CircularProgress color="primary" />}
                  {!sendingTest && (
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => sendTestEmail()}
                    >
                      Send Test
                    </MDButton>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                Codility Results
              </Grid>

              {user.evaluations.map((evaluation, index) => {
                let score = 'Not Completed';
                let scoreN = 0;
                if (evaluation.result !== null) {
                  score = `${evaluation.result}/${evaluation.max_result}`;
                  scoreN =
                    Number.parseFloat(evaluation.result) /
                    evaluation.max_result;
                }
                return (
                  <Grid item key={index} xs={4}>
                    <Card>
                      <Grid container p={2} spacing={2}>
                        <Grid item xs={9}>
                          {evaluation.name}
                        </Grid>
                        <Grid item xs={3} textAlign="right" marginTop={1}>
                          <CircularProgress
                            variant="determinate"
                            value={scoreN}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            disabled
                            fullWidth={true}
                            variant="standard"
                            defaultValue={score}
                            label="Score"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            disabled
                            fullWidth={true}
                            variant="standard"
                            defaultValue={new Date(
                              Date.parse(evaluation.start_date)
                            ).toDateString()}
                            label="Start Date"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            disabled
                            fullWidth={true}
                            variant="standard"
                            defaultValue={new Date(
                              Date.parse(evaluation.close_date)
                            ).toDateString()}
                            label="End Date"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <h5>Tasks</h5>
                        </Grid>
                        {evaluation.tasks.map((task, index) => {
                          let textValue = 'Not Completed';
                          if (task.result) {
                            textValue = `${task.result} / ${task.max_result}`;
                          }
                          return (
                            <React.Fragment key={index}>
                              <Grid item xs={6}>
                                <TextField
                                  disabled
                                  fullWidth={true}
                                  variant="standard"
                                  defaultValue={textValue}
                                  label={task.name}
                                />
                              </Grid>
                            </React.Fragment>
                          );
                        })}
                      </Grid>
                    </Card>
                  </Grid>
                );
              })}

              {user.sessions.length !== user.evaluations.length && (
                <Grid item xs={4}>
                  {isLoading && (
                    <div style={{ textAlign: 'center' }}>
                      <CircularProgress color="primary" />
                    </div>
                  )}
                  {!isLoading && (
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => fetchSessionData(currentPage + 1)}
                    >
                      Load More
                    </MDButton>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ExternalUserBoard;
