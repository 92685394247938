
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

interface DataItem {
    value: string;
    label: string;
}

interface Props {
    label: string;
    value: any;
    items: DataItem[]
    onChange: (value: any) => void;
}

export default (props: Props) => {
    const { label, value, onChange, items } = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({...(value || {}), [event.target.name]: event.target.checked});
    };
    return (
        <FormControl>
            {label && <p className="!text-xs !pb-2 !font-inter !font-semibold !text-label-black">{label}</p>}
            <FormGroup classes={{ root: '!pl-2'}}>
                {items.map((item, index) => {
                    return (
                        <FormControlLabel
                            key={index}
                            classes={{ label: "!text-[11px] !font-inter !font-medium !text-label-black !mb-1", root: '!flex'}}
                            control={
                                <Checkbox
                                    checked={value ? value[item.value] : false}
                                    classes={{ root: "!p-1"}} // , checked: "!w-4 ! !h-4 !rounded-none"
                                    size={'small'}
                                    onChange={handleChange}
                                    name={item.value}
                                />
                            }
                            label={item.label}
                        />
                    )
                })}
            </FormGroup>
        </FormControl>
    )
}