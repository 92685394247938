import React, { useState } from "react";
import classnames from 'classnames';

export interface FormFieldProps extends React.InputHTMLAttributes<any> {
  defaultState: string | number;
  label?: string;
  icon?: string;
  customWidth?: boolean;
  endIcon?: string;
  onIconClick?: () => void;
}

const FormComponent = ({ setState, state, label, icon, endIcon, onIconClick, customWidth, ...props }: any) => (
  <React.Fragment>
    <label htmlFor={label} className={`${classnames({
      'min-w-150': !customWidth,
    })} flex grow basis-72  relative focus-within:text-gray-600 max-h-10`}>
      {label}
      {icon && (
        <span className="material-icons text-text-low-contrast pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3 pt-1">
          {icon}
        </span>
      )}
      <input
        className={`${classnames({
          'pl-10': icon,
        })} text-sm appearance-none border-2 rounded-md w-full py-2 px-3 text-black leading-tight placeholder:text-text-low-contrast hover:bg-background focus:outline-none focus:border-half-gray`}
        autoFocus
        type="text"
        id={label}
        value={state}
        placeholder={label}
        onChange={e => setState(e.target.value)}
        {...props}
      />
      {(endIcon && state) && (
        <div
          className='rounded z-10 flex items-center justify-center mr-2 p-0 hover:bg-[#0000000f] text-[#707A89] w-6 h-6 text-center absolute top-1/2 transform -translate-y-1/2 right-0 cursor-pointer'
          onClick={() => onIconClick && onIconClick()}
        >
          <span className="material-icons">
            {endIcon}
          </span>
        </div>
      )}
    </label>
  </React.Fragment>
);

export default function useForm(props: FormFieldProps) {
  const { defaultState, ...restProps } = props;
  const [state, setState] = useState(defaultState);

  return [
    state,
    <FormComponent state={state} setState={setState} {...restProps} />,
    setState
  ];
}