import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface RadioItem {
    value: number | string;
    label: string;
}

interface Props {
    label: string;
    value: string | number;
    items: RadioItem[]
    onChange: (value: string | number) => void;
}

export default (props: Props) => {
    const { label, value, onChange, items } = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange((event.target as HTMLInputElement).value);
    };
    return (
        <FormControl>
            <p className="text-xs pb-2 font-inter font-semibold text-label-black">{label}</p>
            <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                {items.map((item, index) => {
                    return (
                        <FormControlLabel
                            key={index}
                            value={item.value}
                            classes={{ label: "!text-[11px] !font-inter !font-medium !text-label-black !mb-1 !ml-0", root: '!flex !h-4 mb-2'}}
                            control={<Radio size={'small'}/>}
                            label={item.label}
                        />
                    )
                })}
            </RadioGroup>
        </FormControl>
    )
}