import React, { useEffect, useRef, useState } from 'react';
import Dropdown from './dropdown';
import Textfield from './textfield';
import Toggle from './toggle';
import classnames from 'classnames';

export enum TimePeriod {
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  years = 'years',
}

export interface DropdownProps {
  text: string;
  onChange?: (after: Date | null) => void;
}

export default function (props: DropdownProps) {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showAvailableNow, setShowAvailableNow] = useState(false);
  const [periodSelected, setPeriodSelected] = useState(TimePeriod.days);
  const [amount, setAmount] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function isInUse() {
      if (showAvailableNow) {
        return true;
      }
      if (amount <= 0) {
        return false;
      }
      return true;
    }
    setIsActive(isInUse());

    if (!props.onChange) return;

    if (showAvailableNow) {
      props.onChange(new Date());
      return;
    }
    if (amount === 0) {
      props.onChange(null);
      return;
    }
    const date = new Date();
    if (periodSelected === TimePeriod.days) {
      date.setDate(date.getDate() + amount);
    } else if (periodSelected === TimePeriod.weeks) {
      date.setDate(date.getDate() + amount * 7);
    } else if (periodSelected === TimePeriod.months) {
      date.setMonth(date.getMonth() + amount);
    } else if (periodSelected === TimePeriod.years) {
      date.setFullYear(date.getFullYear() + amount);
    }
    props.onChange(date);
  }, [showAvailableNow, periodSelected, amount]);

  function clear() {
    setShowAvailableNow(false);
    setAmount(0);
  }

  function getText() {
    if (!isActive) {
      return props.text;
    }
    if (showAvailableNow) {
      return 'Available Now';
    }

    return `Available in ${amount} ${periodSelected}`;
  }

  return (
    <div className="flex grow basis-72 min-w-150" ref={wrapperRef}>
      <button
        type="button"
        className={`${classnames({
          'border-primary': isActive,
        })} flex flex-grow justify-start items-center rounded-md border text-sm border-border-primary px-4 py-2 bg-white hover:bg-background focus:outline-none focus:border-primary`}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`${classnames({
            'text-primary': isActive,
          })} flex flex-grow`}
        >
          {getText()}
        </span>
        <span className="material-icons flex flex-shrink text-primary">
          keyboard_arrow_down
        </span>
      </button>
      {isOpen && (
        <div className="absolute origin-top-left bg-white mt-11 w-96 rounded-lg shadow-xl border border-slate-100">
          <div className="flex flex-row mx-4 mt-4">
            <span className="flex grow text-sm font-bold text-black">
              {props.text}
            </span>
            <span
              className="material-icons flex shrink text-text-mild-contrast cursor-pointer text-sm"
              onClick={() => setIsOpen(false)}
            >
              close
            </span>
          </div>
          <div className="flex flex-col px-4 pt-4">
            <div className="flex flex-row gap-2">
              <Textfield
                type="number"
                placeholder="Available in..."
                value={amount}
                onChange={(value) => {
                  setAmount(Math.max(0, value as number));
                }}
              />
              <Dropdown
                text="period"
                defaultSelection={periodSelected}
                options={new Set(Object.values(TimePeriod))}
                onChange={(selected) =>
                  setPeriodSelected([...selected][0] as TimePeriod)
                }
              />
            </div>
            <div className="flex flex-row gap-4 pt-4 items-center">
              <Toggle
                checked={showAvailableNow}
                onClick={() => setShowAvailableNow(!showAvailableNow)}
              />
              <span className="flex grow text-black text-sm">
                Only available Now
              </span>
            </div>
          </div>
          <div className="flex flex-row mx-4 my-4 items-center">
            <button
              className="flex grow uppercase text-sm font-bold underline cursor-pointer"
              onClick={clear}
            >
              clear
            </button>
            <button
              className="flex uppercase font-bold text-sm text-white bg-primary rounded-lg px-4 py-2 cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
