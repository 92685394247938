import Card from 'new-app/components/card';
import Pagination from 'new-app/components/pagination';
import Table from 'new-app/components/table';
import Textfield from 'new-app/components/textfield';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';

export default function () {
  const navigate = useNavigate();
  const [roles, setRoles] = useState({
    count: 0,
    total: 0,
    data: [],
  });
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 0,
  });
  const [search, setSearch] = useState('');

  async function fetchRoles() {
    const result = await axios.post('/role/active', {
      currentPage: pagination.page,
      perPage: pagination.limit,
      searchKey: search,
    });
    if (result.status !== 200) {
      throw new Error('got invalid response from server');
    }
    setRoles(result.data);
  }
  useEffect(() => {
    fetchRoles();
  }, [pagination, search]);

  function renderRoles(): Array<Map<string, JSX.Element>> {
    return roles.data.map(
      (role) =>
        new Map([['role', <span className="cursor-pointer">{role.name}</span>]])
    );
  }

  return (
    <>
      <Card>
        <div className="flex flex-col gap-4">
          <Textfield
            type="text"
            placeholder="Search..."
            icon="search"
            onChange={(val) => setSearch(`${val}`)}
          />
          <Table
            headings={['role']}
            data={renderRoles()}
            onHoverChangeColor={true}
            onEntryClick={(index) => {
              const role = roles.data[index];
              if (!role) return;
              navigate(`/roles/edit/${role._id}`);
            }}
          />
          <Pagination
            count={roles.count}
            limit={pagination.limit}
            page={pagination.page}
            total={roles.total}
            onChange={(limit, page) => setPagination({ limit, page })}
          />
        </div>
      </Card>
    </>
  );
}
