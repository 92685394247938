import { UserListIcon } from 'assets/icons';

export default function EmptyShortlist() {

    return (
        <div className='flex justify-center items-center flex-col gap-6 mt-12'>
            <UserListIcon />
            <p className='text-center text-[32px] text-cool-600 font-poppins font-semibold leading-10'>
                This is where you manage your shortlists
            </p>
            <p className='text-center text-[18px] text-cool-600 font-poppins font-semibold leading-6'>
                You don’t currently have one. <br />
                Start creating a shortlist and adding candidates to it.
            </p>
        </div>
    )
}