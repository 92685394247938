import classnames from 'classnames';

export interface ToggleProps {
  checked?: boolean;
  onClick?: () => void;
}

export default function (props: ToggleProps) {
  return (
    <div
      className={`${classnames({
        'bg-mild-gray': !props.checked,
        'bg-primary': props.checked,
      })} flex flex-row shrink rounded-full w-10 h-6 p-1 cursor-pointer`}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <div
        className={`${classnames({
          'opacity-0': props.checked,
        })} flex rounded-full w-4 h-4 bg-white`}
      ></div>
      <div className="flex grow"></div>
      <div
        className={`${classnames({
          'opacity-0': !props.checked,
        })} flex rounded-full w-4 h-4 bg-white`}
      ></div>
    </div>
  );
}
