import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { SuccessIcon, CloseIcon1, FailureIcon } from 'assets/icons';

export interface NotificationProps extends React.InputHTMLAttributes<any> {
    label: string;
    className?: string;
}

export default function (props: any) {
  const { severity, message, open, onClose} = props;

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal : 'right' }}>
        <div className='w-[480px] max-w-[100%] bg-white h-[76px] gap-[6px] relative flex flex-col p-4 rounded-lg' style={{ boxShadow: '0px 12px 32px 4px rgba(0, 11, 38, 0.1)'}}>
            <span className='absolute right-4 top-4 cursor-pointer' onClick={handleClose}><CloseIcon1 /></span>
            <div className='flex flex-row gap-2'>
                {severity === 'success' ? <SuccessIcon /> : null}
                {severity === 'error' ? <FailureIcon /> : null}
                <p className='font-poppins font-semibold text-[#555E6D] text-[14px] leading-5 capitalize'>{severity}</p>
            </div>
            <p className='font-inter font-normal text-[#555E6D] text-[12px] leading-4 pl-8'>{message}</p>
        </div>
    </Snackbar>
  );
}
