import { CandidateSearchItemResponse, CountryMatches, CountryCalculateMatch } from "new-app/models/candidateSearchResult";

export const calculateLocationMatches = (results: CandidateSearchItemResponse[]) => {
    const locations = results.reduce((locations : CountryCalculateMatch, { Location }) => {
        locations[Location] ??= { Country: Location, Matches: 0 };
        locations[Location].Matches++;
        return locations;
    }, {});

    const values = Object.values(locations) as CountryMatches[];
    return values.sort((x, y) => y.Matches - x.Matches);
}
