import { useEffect, useContext, useRef } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import GoogleIcon from '@mui/icons-material/Google';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useMaterialUIController, setLayout } from 'context';
import { authContext } from 'context/auth';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Logo from 'assets/images/custom/Distributed_Command.png';
import MDTypography from 'components/MDTypography';
import { logEvent, EventType } from 'context/analytics';

function Login({ mode }: any): JSX.Element {
  const { authUser } = useContext(authContext);
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const googleBtnRef = useRef<any>(null);

  useEffect(() => {
    if (mode === 'logout') {
      logEvent(EventType.logout);
      Auth.signOut();
    }
    setLayout(dispatch, 'page');
  }, [pathname, mode]);
  return authUser ? (
    <Navigate to={process.env.REACT_APP_TECH_RADAR_LINK  === 'true' ? "/tech_radar" : "/elastic_talent_board_plus"} replace={true} />
  ) : (
    <MDBox display="flex" pt={15} justifyContent="center">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <img src={Logo} style={{ width: '300px' }} />
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox my={1}>
              <MDButton
                variant="outlined"
                color="info"
                fullWidth
                startIcon={<GoogleIcon />}
                ref={googleBtnRef}
                onClick={() => Auth.federatedSignIn()}
              >
                Login with Google
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}
export default Login;
