import Card from 'new-app/components/card';
import Chip from 'new-app/components/chip';
import Dropdown from 'new-app/components/dropdown';
import Pagination from 'new-app/components/pagination';
import Table from 'new-app/components/table';
import Textfield from 'new-app/components/textfield';
import { ExternalUser } from 'pages/external_board/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';

type ExternalUserResponse = {
  users: ExternalUser[];
  total: number;
  count: number;
};

export default function () {
  const navigate = useNavigate();
  const [externalUsers, setExternalUsers] = useState<ExternalUser[]>([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 0,
    total: 0,
    count: 0,
  });
  const [search, setSearch] = useState<Record<string, string>>({
    name: '',
    email: '',
    location: '',
    skills: '',
    roles: '',
    isAcademy: null,
    availablityAfter: '1999-01-01',
    availablityBefore: '2999-01-01',
  });

  async function fetchExternalUsers() {
    const urlSearchParams = new URLSearchParams();

    // hack to always sort by createdAt descending, until we have a proper sort
    urlSearchParams.set('sort', '-createdAt');
    urlSearchParams.set('limit', `${pagination.limit}`);
    urlSearchParams.set('page', `${pagination.page}`);
    if (search.name) {
      urlSearchParams.set('name', search.name);
    }
    if (search.isAcademy) {
      urlSearchParams.set('isAcademy', search.isAcademy);
    }

    const req = await axios.get<ExternalUserResponse>(
      `/user/external_user/get?${urlSearchParams.toString()}`
    );
    if (req.status !== 200) {
      return;
    }
    setExternalUsers(req.data.users);
    setPagination({
      ...pagination,
      total: req.data.total,
      count: req.data.count,
    });
  }
  useEffect(() => {
    fetchExternalUsers();
  }, [pagination.page, pagination.limit, search]);

  const tableHeadings = {
    name: 'full name',
    isAcademy: 'Academy',
    applicationDate: 'application date',
    availability: 'availability date',
    roles: 'claimed roles',
    skills: 'claimed skills',
  };

  function renderExternalUser(): Array<Map<string, JSX.Element>> {
    return externalUsers.map(
      (externalUser) =>
        new Map([
          [
            tableHeadings.name,
            <span className="cursor-pointer">
              {externalUser.firstName} {externalUser.lastName}
            </span>,
          ],
          [
            tableHeadings.isAcademy,
            <span className="cursor-pointer flex justify-center">
              <input
                type="checkbox"
                readOnly
                checked={externalUser.isAcademy ? true : false}
              />
            </span>,
          ],
          [
            tableHeadings.applicationDate,
            <span className="cursor-pointer">
              {new Date(externalUser.createdAt).toDateString()}
            </span>,
          ],
          [
            tableHeadings.availability,
            <span className="cursor-pointer">
              {new Date(externalUser.availablity).toDateString()}
            </span>,
          ],
          [
            tableHeadings.roles,
            <span className="flex gap-2 cursor-pointer">
              {externalUser.roles.map((role, index) => (
                <Chip
                  key={index}
                  text={`${role.name}`}
                  className="bg-gray-600 text-gray-100"
                />
              ))}
            </span>,
          ],
          [
            tableHeadings.skills,
            <span className="flex gap-2 cursor-pointer">
              {externalUser.skills.map((skill, index) => (
                <Chip
                  key={index}
                  text={`${skill.name}`}
                  className="bg-gray-600 text-gray-100"
                />
              ))}
            </span>,
          ],
        ])
    );
  }

  return (
    <>
      <Card>
        <div className="flex flex-col gap-4">
          <Textfield
            type="text"
            placeholder="Search..."
            icon="search"
            onChange={(val) => setSearch({ ...search, name: `${val}` })}
          />
          <Dropdown
            className="max-h-10"
            text="Academy"
            allowUnselect={true}
            options={new Set(['true', 'false'])}
            onChange={(selected) => {
              const selectedItem = [...selected][0];
              if (selectedItem === '"None"') {
                setSearch({
                  ...search,
                  isAcademy: null,
                });
                return;
              }
              setSearch({
                ...search,
                isAcademy: selectedItem,
              });
            }}
          />
          <Table
            headings={Object.values(tableHeadings)}
            data={renderExternalUser()}
            onHoverChangeColor={true}
            onEntryClick={(index) => {
              const user = externalUsers[index];
              if (!user) return;
              navigate(`/external_talent_board/${user._id}/view`);
            }}
          />
          <Pagination
            count={pagination.count}
            limit={pagination.limit}
            page={pagination.page}
            total={pagination.total}
            onChange={(limit, page) =>
              setPagination({
                ...pagination,
                limit,
                page,
              })
            }
          />
        </div>
      </Card>
    </>
  );
}
