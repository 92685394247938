import { UserIcon } from 'assets/icons';

interface UserPhotoProps {
    name: string;
    role?: string;
    url?: string;
}

export default function UserPhoto(props: UserPhotoProps) {
    const { name, role, url } = props;

    return (
        <div className='flex flex-col justify-start min-w-[135px]'>
            {url ? <img src={url} className='rounded-full' width={64} height={64} /> : <UserIcon width={64} height={64}/>}
            <p className='text-[#191C25] font-poppins capitalize font-semibold text-[16px] leading-6 mt-4 whitespace-nowrap'>{name}</p>
            <p className='text-[#191C25] font-inter capitalize font-normal text-[14px] leading-5 mt-1 whitespace-nowrap'>{role}</p>
        </div>
    );
}