import React from 'react';
import classNames from 'classnames';

export interface HeadingProps {
  text: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

export default function (props: HeadingProps) {
  return (
    <React.Fragment>
      <span
        className={`${classNames({
          'text-sm': props.size === 'small',
          'text-xl': props.size === undefined || props.size === 'medium',
          'text-4xl': props.size === 'large',
        })} font-bold text-secondary grow ` + props.className || ''}
      >
        {props.text}
      </span>
    </React.Fragment>
  );
}
