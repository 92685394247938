import { styled } from '@mui/material/styles';
import Tabs, {TabsProps} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface StyledTabsProps extends TabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '&.MuiTabs-root': {
        padding: 0,
        borderRadius: 0,
        minHeight: '36px',
    },
});

interface StyledTabProps {
    label: string;
    disabled?: boolean;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.MuiTab-root': {
        fontSize: '14px',
        fontFamily: 'Noto Sans',
        borderRadius: 0,
        margin: 0,
        padding: 0,
        border: 0,
        
        height: '36px !important',
        minHeight: '36px !important',
        background: 'white',
        color: '#555E6D !important'
    },
    '&.Mui-selected': {
        borderRadius: 0,
        color: '#144FE7 !important',
        backgroundColor: 'white !important'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '&.Mui-disabled': {
        color: '#B3B3B3 !important',
    },
}));

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default {
    TabPanel,
    StyledTab,
    StyledTabs
}
