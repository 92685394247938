import { User } from 'new-app/models/user';

export function getLatestPayment(user: User) {
  if (!user || user?.paymentInfo.length === 0) return null;

  const paymentInfo = user?.paymentInfo.sort(
    (a, b) => Date.parse(a.paymentDate) - Date.parse(b.paymentDate)
  )[0];

  return paymentInfo;
}
