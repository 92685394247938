import { countryList } from './countryList';

export const mbToBytes = (value: number) => {
  return value * 1024 * 1024;
};

export const getNationalityList = () => {
  return countryList.reduce(
    (p, c) => Object.assign(p, { [c.cca2]: c.demonyms.eng.f }),
    {}
  );
};

export const getCountryList = () => {
  return countryList
    .sort((a, b) => a.demonyms.eng.f.localeCompare(b.demonyms.eng.f))
    .map((country) => country.cca2);
};

export const getCountryListByContinent = () => {
  return countryList.map(i => {
    return {
      name: i.name.common,
      region: i.region,
      subregion: i.subregion,
      cca2: i.cca2
    }
  })
}

