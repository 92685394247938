import Checkbox from './checkbox';
import classnames from 'classnames';
import { useState } from 'react';

export interface TableProps {
  /**
   * headings
   */
  headings: Array<string>;
  /**
   * a array of map where the key is heading and
   * value is the element to display in the table
   * data cell
   */
  data: Map<string, JSX.Element>[];
  /**
   * index of selected elements
   */
  selected?: Set<number>;
  onHoverChangeColor?: boolean;
  /**
   * when a user selects an entry this is called
   */
  toggleSelection?: (index: number) => void;
  onMouseEnter?: (index: number) => void;
  onMouseLeave?: (index: number) => void;
  onEntryClick?: (index: number) => void;
  onSortClick?: (sortedColumns: Map<string, number>) => void;
}

export default function (props: TableProps) {
  const [hovered, setHover] = useState(-1);
  const [sortedColumns, setSortedColumns] = useState<Map<string, number>>(
    new Map<string, number>()
  );

  function sortColumn(column: string) {
    const updatedSortedColumn = new Map<string, number>(sortedColumns);
    if (!sortedColumns.has(column)) {
      updatedSortedColumn.set(column, -1);
    } else if (sortedColumns.get(column) === -1) {
      updatedSortedColumn.set(column, 1);
    } else {
      updatedSortedColumn.delete(column);
    }
    setSortedColumns(updatedSortedColumn);

    if (props.onSortClick) {
      props.onSortClick(updatedSortedColumn);
    }
  }

  function renderHeading() {
    const headings = props.headings.map((heading, index) => {
      let renderSortArrow = null;
      if (sortedColumns.has(heading)) {
        if (sortedColumns.get(heading) === 1) {
          renderSortArrow = (
            <span className="absolute material-icons">expand_more</span>
          );
        } else {
          renderSortArrow = (
            <span className="absolute material-icons">expand_less</span>
          );
        }
      }

      return (
        <th
          key={index}
          className={`${classnames({
            'rounded-r-lg': index + 1 === props.headings.length,
            'cursor-pointer': props.onSortClick,
          })} min-w-100 text-left uppercase py-4 px-2 underline text-sm font-semibold text-text-mild-contrast`}
          onClick={() => {
            if (props.onSortClick) {
              sortColumn(heading);
            }
          }}
        >
          {heading}
          {props.onSortClick && renderSortArrow}
        </th>
      );
    });
    return (
      <thead className="bg-off-white rounded bg-light-gray">
        <tr>
          {props.toggleSelection && (
            <th key={-1} className="rounded-l-lg">
              &nbsp;
            </th>
          )}
          {headings}
        </tr>
      </thead>
    );
  }

  function renderDataElement(entry: Map<string, JSX.Element>, index: number) {
    const dataEntry = props.headings.map((heading, headingIndex) => {
      const data = entry.get(heading);
      return (
        <td
          key={headingIndex}
          className="text-left py-4 px-2 text-sm border-0 border-b-2 border-b-border-primary text-black"
        >
          {data}
        </td>
      );
    });

    return (
      <tr
        key={index}
        onMouseEnter={() => {
          if (props.onMouseEnter) {
            props.onMouseEnter(index);
          }
          setHover(index);
        }}
        onMouseLeave={() => {
          if (props.onMouseLeave) {
            props.onMouseLeave(index);
          }
          setHover(-1);
        }}
        onClick={() => {
          if (props.onEntryClick) {
            props.onEntryClick(index);
          }
        }}
        className={classnames({
          'bg-light-gray': props.onHoverChangeColor && index === hovered,
        })}
      >
        {props.toggleSelection && (
          <td
            key={-1}
            className="cursor-pointer pl-4 border-0 border-b-2 border-b-border-primary"
            onClick={() => {
              props.toggleSelection(index);
            }}
          >
            <Checkbox checked={props.selected && props.selected.has(index)} />
          </td>
        )}
        {dataEntry}
      </tr>
    );
  }

  return (
    <div className="flex flex-col">
      <table className="table-fixed">
        {renderHeading()}
        <tbody>
          {props.data.map((entry, index) => renderDataElement(entry, index))}
        </tbody>
      </table>
    </div>
  );
}