import React, { useEffect, useRef, useState } from 'react';

export interface TooltipProps {
  visible?: boolean;
  children: React.ReactNode;
}

export default function (props: TooltipProps) {
  const tooltipWidth = 300;
  const offset = 10;
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    function onMouseMove(event: MouseEvent) {
      const tooltipHeight = tooltipRef?.current?.clientHeight ?? -1;

      let x = event.clientX + offset + 5;
      let y = event.clientY + offset;
      if (x + tooltipWidth + offset > document.body.clientWidth) {
        x = event.clientX - (tooltipWidth + offset);
      }
      if (tooltipHeight > 0) {
        if (y + tooltipHeight + offset > document.body.clientHeight) {
          y = event.clientY - (tooltipHeight + offset);
        }
      }

      setPosition({ x, y });
    }

    window.addEventListener('mousemove', onMouseMove);
    return () => window.removeEventListener('mousemove', onMouseMove);
  }, []);

  if (!props.visible) {
    return null;
  }
  return (
    <div className="block relative w-0 h-0">
      <div
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
          zIndex: 20000,
          width: `${tooltipWidth}px`,
        }}
        className="block fixed bg-white rounded-lg shadow-xl border border-border-primary"
        ref={tooltipRef}
      >
        {props.children}
      </div>
    </div>
  );
}
