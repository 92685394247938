import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

interface BreadcrumbsProps {
    name?: string;
}

export default function BasicBreadcrumbs(props: BreadcrumbsProps) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" href="/elastic_talent_board_plus" classes={{ root: '!text-[14px] !font-normal !text-[#707A89] !font-inter !leading-5'}}>
          Elastic Talent Finder+
        </Link>
        <Link
          underline="hover"
          classes={{ root: '!text-[14px] !font-normal !capitalize !text-[#707A89] !leading-5 !font-inter'}}
          href="/elastic_talent_board_plus/"
        >
          {props.name || 'User'}
        </Link>
        <Typography classes={{ root: '!text-[14px] !font-normal !text-cool-900 !leading-5 !font-inter'}}>Edit Profile</Typography>
      </Breadcrumbs>
    </div>
  );
}