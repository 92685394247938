import { useEffect } from 'react';

export default function useExternalScripts({
  url,
  attrs = {},
}: {
  url: string;
  attrs: Object;
}) {
  useEffect(() => {
    const body = document.body;
    const script = document.createElement('script');
    script.async = true;

    for (const attr of Object.entries(attrs)) {
      script.setAttribute(attr[0], attr[1]);
    }
    script.setAttribute('src', url);
    body.appendChild(script);

    return () => {
      body.removeChild(script);
    };
  }, [url]);
}
