import * as React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';

import InputBase from '@mui/material/InputBase';

const RootContainer = styled('div')`
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
`;

const SelectLabel = styled('label')({
  fontSize: '11px',
  color: '#191C25',
  cursor: 'pointer',
  fontWeight: 500,
  fontFamily: 'Noto Sans',
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 7,
    position: 'relative',
    border: '1px solid #D9D9D9',
    background: 'transparent !important',
    fontSize: '0.65rem',  color: '#7b809a',
    padding: '10px 3px 10px 3px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontWeight: 700, fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
    ,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export interface SelectItems {
  title: string | number;
  value: string | number;
  filter?: number;
}

interface Props {
    items: SelectItems[];
    label?: string;
    value: any;
    width?: number;
    opacity?: number;
    multiple?: boolean;
    disabled?: boolean;
    onChange?: (value: any) => void;
}

export default function MultipleSelectCheckmarks(props: Props) {
  const [open, setOpen] = React.useState(false)

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '700px',
        width: 'auto',
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof props.value>) => {
    const {
      target: { value },
    } = event;
    props.onChange(
      typeof value === 'string' ? value.split(',') : value,
    );
    setOpen(false);
  };

  return (
    <div>
      <FormControl sx={{ m: 0, width: props.width || 200, height: '100%' }} disabled={props.disabled}>
        <Select
          multiple={props.multiple}
          value={props.value || ""}
          open={open}
          onChange={handleChange}
          input={<BootstrapInput />}
          renderValue={(selected) => ''}
          MenuProps={MenuProps}
          onClose={() => setOpen(false)}
          classes={{ select: "!h-[34px] !border-1 !border-red !rounded", icon: '!hidden'}}
        >
          {props.items.map((item: any, index: number) => {
            if (item.header) {
              return (
                <ListSubheader sx={{ lineHeight: '30px'}} key={index}>{item.header}</ListSubheader>
              )
            }
            if (!item.value) return;
            return (
              <MenuItem key={index} value={item.value} className="!px-2">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '1rem'}}>
                    {props.multiple && <Checkbox checked={props.value.includes(item.value)} size='small' />}
                    <p className='font-inter text-[#191C25] text-[12px] font-medium' style={{ marginTop: props.multiple ? '3px' : 0}}>{item.title}</p>
                  </div>
                  {item.filter && <p className='font-inter text-[#6C6F79] text-[12px] font-medium'>{item.filter}</p>}
                </div>
              </MenuItem>
          )})}
        </Select>
        
          <RootContainer onClick={() => !props.disabled && setOpen(true)} style={{ opacity: props.disabled ? 0.3 : props.opacity || 1}}>
            {props.label ? (<SelectLabel>{props.label}</SelectLabel>) : <span />}
            {open ? <span className="material-icons text-[#707A89]">expand_less</span> :
            <span className="material-icons text-[#707A89]">expand_more</span>}
          </RootContainer>
      </FormControl>
    </div>
  );
} 