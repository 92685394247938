import styled from "styled-components";

const Container = styled.div`
    display: flex;
    background: #ffffff;
    border: 0.5px solid #afafaf;
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: 716px;
    overflow: auto;
`;

const PieChartContainer = styled.div`
    flex: 6;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
`;

const PieChartDescription = styled.div`
    font-size: 0.875rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 310px;
    font-family: Poppins;
    color: #808080;
    z-index: 10;
`;

const PieChartHeader = styled.div`
    margin-bottom: 1rem;
    font-family: Poppins;
    font-size: 12px;
    color: #808080;
    background: rgba(255, 255, 255, 0.5);
`;

const PieChartSkills = styled.div`
    font-weight: 600;
    font-family: Poppins;
    font-size: 13px;
`;

const PieChartSkillTools = styled.li`
    font-family: Poppins;
    color: #E91E79;
`;

const PieChartSkillPlatforms = styled.li`
    font-family: Poppins;
    color: #F7931E;
`;

const PieChartSkillLanguages = styled.li`
    font-family: Poppins;
    color: #008D6C;
`;

const PieChartSkillFrameworks = styled.li`
    font-family: Poppins;
    color: #0071B8;
`;

const PieChartSkillTechniques = styled.li`
    font-family: Poppins;
    color: #9F50E9;
`;

const ZoomWrapper = styled.div`
    width: 11px;
    height: 270px;
    background: #e2e2e2;
    border: 0.5px solid #959595;
    margin-bottom: 40px;
    border-radius: 8px;
    align-self: flex-end;
`;

const LanguageContainer = styled.div`
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
`;

const LanguageDescription = styled.div`
    width: 100%;
    font-size: 0.875rem;
    font-family: Poppins-Regular;
    margin-bottom: .5rem;
`;

const LanguageWrapper = styled.div`
    width: 100%;
    height: 676px;
    background: #ffffff;
    border: 0.5px solid #afafaf;
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin: 0px 10px;
`;

const LanguageTitleWrapper = styled.div`
    width: 100%;
    height: 25px;
    color: #ffffff;
    background: #0071b8;
    opacity: 0.76;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
`;

const PieChart = styled.div`
    align-self: center;
`;

const SkillFilterContainer = styled.div`
    border: 1px solid rgba(223, 223, 223, 1);
    border-radius: 5px;
    padding-left: 1rem;
`

export default {
    Container,
    PieChartContainer,
    PieChartDescription,
    PieChartHeader,
    PieChartSkills,
    PieChartSkillTools,
    PieChartSkillPlatforms,
    PieChartSkillLanguages,
    PieChartSkillFrameworks,
    PieChartSkillTechniques,
    ZoomWrapper,
    LanguageContainer,
    LanguageDescription,
    LanguageWrapper,
    LanguageTitleWrapper,
    PieChart,
    SkillFilterContainer
}