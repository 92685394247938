export type TDocument = {
  name: string;
  path: string;
  type: string;
  size: number;
  uploadedBy: string;
  uploadedAt: string;
  deleted: boolean;
};

export interface IProject {
  _id?: string;
  name: string;
  code: string;
  type: string;
  description: string;
  logo?: string;
  documents?: TDocument[];
}

export interface ISkill {
  _id?: string;
  name: string;
  description: string;
  isCore?: string | boolean;
  visible?: string | boolean;
  category?: SkillCategoryDetail;
  [key: string]: any;
}

export interface TSkill {
  name: string;
  quadrant: string;
  core: number;
  ring: number;
  skillId?: number;
  uv?: number
}

export enum ProjectTypes {
  FIXED_OUTCOME = 'Fixed Outcome',
  MANAGED_OUTCOME = 'Managed Outcome',
  MANAGED_PROJECT = 'Managed Project',
}

export interface IRole {
  _id?: string;
  name: string;
  description: string;
  category: any;
  skills: ISkill[];
}

export interface SkillCategoryDetail {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
}

export interface SKillCategoryList {
  page: number;
  limit: number;
  count: number;
  total: number;
  items: SkillCategoryDetail[] | [];
}

export interface ISkillList {
  page: number;
  limit: number;
  count: number;
  total: number;
  items: ISkillItem[] | [];
}

export interface ISkillItem {
  id: number;
  name: string;
  displayName?: string;
  description: string;
  isCore: string | boolean;
  visible: string | boolean;
  category: any;
  categoryId?: number;
}
