import classnames from 'classnames';

export interface CheckboxProps {
  checked?: boolean;
  label?: string;
}

export default function (props: CheckboxProps) {
  return (
    <div
      className={`${classnames({
        'text-white bg-primary-blue': props.checked,
        'bg-white': !props.checked,
      })} flex h-4 w-4 border rounded-sm bg-white items-center justify-evenly`}
    >
      {props.checked && (
        <span className="material-icons flex text-xs font-bold relative text-center">
          check
        </span>
      )}
      {props.label && <p className="">{props.label}</p>}
    </div>
  );
}
