import Textfield from '../components/textfield';
import Card from '../components/card';
import Heading from 'new-app/components/heading';
import Dropdown from 'new-app/components/dropdown';
import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Chip from 'new-app/components/chip';
import Table from 'new-app/components/table';
import AvailabilityDropdown from 'new-app/components/availabilityDropdown';
import { Agency } from 'new-app/models/agency';
import { SecurityClearance } from 'new-app/models/securityClearance';
import ActionMenu from 'new-app/components/actionMenu';
import { useUnselect } from 'new-app/hooks/useUnselect';
import { useNavigate } from 'react-router-dom';
import DocumentDialog from 'pages/project/DocumentDialog';
import { User } from '../models/user';
import {
  fetchUsersList,
  deleteUser,
  fetchAllLanguages,
  fetchAllLocations,
} from '../reducers/user';
import Pagination from 'new-app/components/pagination';
import { fetchProjects } from 'new-app/reducers/project';
import { fetchSkills } from 'new-app/reducers/skill';
import { fetchRoles } from 'new-app/reducers/roles';
import TalentComparison from 'new-app/components/talentComparison';
import { getLatestPayment } from 'new-app/helpers/user';
import { TalentTooltip } from 'new-app/features/talent/components/TalentTooltip';
import { renderAvailabilityText } from 'new-app/features/talent/components/utils/renderAvailabilityText';

import { logEvent, EventType } from "context/analytics"

export default function () {
  const [showFullFilters, setShowFullFilters] = useState(false);
  const [actionMenuOpened, setActionMenuOpened] = useState<number>(-1);
  const [talentComparisonOpen, setTalentComparisonOpen] = useState(false);

  const actionMenuRef = useRef(null);
  const navigate = useNavigate();

  // dropdown options
  const [skills, setSkills] = useState<Array<string>>([]);
  const [projects, setProjects] = useState<Array<string>>([]);
  const [roles, setRoles] = useState<Array<string>>([]);
  const [languages, setLanguages] = useState<Array<string>>([]);
  const [locations, setLocations] = useState<Array<string>>([]);
  // talents
  const [users, setUsers] = useState<User[]>([]);
  const [totalTalents, setTotalTalents] = useState(0);
  const [countTalents, setCountTalents] = useState(0);
  const [selected, setSelected] = useState<Set<number>>(new Set([]));
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [hovered, setHovered] = useState(-1);
  // file management
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [documentDialogId, setDocumentDialogId] = useState<string>(null);
  // filter
  const [searchTerms, setSearchTerms] = useState<Array<string>>([]);
  const [rolesFilter, setRolesFilter] = useState<Array<string>>([]);
  const [skillsFilter, setSkillsFilter] = useState<Array<string>>([]);
  const [locationsFilter, setLocationsFilter] = useState<Array<string>>([]);
  const [projectFilter, setProjectFilter] = useState<Array<string>>([]);
  const [agenciesFilter, setAgenciesFilter] = useState<Array<string>>([]);
  const [languageFilter, setLanguageFilter] = useState<Array<string>>([]);
  const [availabilityFilter, setAvailabilityFilter] = useState<Date | null>(
    null
  );
  const [clearanceFilter, setClearanceFilter] = useState<Array<string>>([]);
  const [sourcesFilter, setSourcesFilter] = useState<Array<string>>([]);
  // pagination
  const [pagination, setPagination] = useState({ page: 0, limit: 10 });

  useUnselect(actionMenuRef, () => setActionMenuOpened(-1));
  useEffect(() => {
    setUsers([]);
    fetchUsersList(
      pagination.page,
      pagination.limit,
      searchTerms,
      availabilityFilter,
      clearanceFilter,
      rolesFilter,
      skillsFilter,
      locationsFilter,
      projectFilter,
      agenciesFilter,
      languageFilter,
      sourcesFilter
    )
      .then((data) => {
        setUsers(data.talents);
        setTotalTalents(data.total);
        setCountTalents(data.count);
      })
      .catch(console.error);
  }, [pagination]);

  useEffect(() => {
    setPagination({
      page: 0,
      limit: 10,
    });
    setUsers([]);
    fetchUsersList(
      0,
      10,
      searchTerms,
      availabilityFilter,
      clearanceFilter,
      rolesFilter,
      skillsFilter,
      locationsFilter,
      projectFilter,
      agenciesFilter,
      languageFilter,
      sourcesFilter
    )
      .then((data) => {
        setUsers(data.talents);
        setTotalTalents(data.total);
        setCountTalents(data.count);
      })
      .catch(console.error);
  }, [
    searchTerms,
    availabilityFilter,
    clearanceFilter,
    rolesFilter,
    skillsFilter,
    locationsFilter,
    projectFilter,
    agenciesFilter,
    languageFilter,
    sourcesFilter,
  ]);

  useEffect(() => {
    function filterValidItems<T>(data: Array<T>) {
      return data.filter((entry) => entry);
    }

    fetchProjects()
      .then((data) =>
        setProjects(filterValidItems(data).map((entry) => entry.name))
      )
      .catch(console.error);

    fetchSkills()
      .then((data) =>
        setSkills(filterValidItems(data.items).map((entry) => entry.name))
      )
      .catch(console.error);

    fetchRoles()
      .then((data) =>
        setRoles(filterValidItems(data).map((entry) => entry.name))
      )
      .then(console.error);

    fetchAllLanguages()
      .then((data) => setLanguages(filterValidItems(data)))
      .catch(console.error);

    fetchAllLocations()
      .then((data) => setLocations(filterValidItems(data)))
      .catch(console.error);
  }, []);

  async function handledeleteUser(id: string) {
    setDeleteDialogOpened(true);
    const result = confirm('Are you sure to delete this talent?');
    if (result) {
      await deleteUser(id);
      setUsers(users.filter((user) => user._id !== id));
    }
    setDeleteDialogOpened(false);
  }

  const tableHeaders = {
    firstName: 'first name',
    lastName: 'last name',
    role: 'role',
    skills: 'skills',
    availability: 'availability',
    location: 'location',
    actions: 'actions',
  };
  function getTableData() {
    return users.map((user, index) => {
      function renderRoleText(roles: any[]) {
        return (
          roles.sort(
            (a: any, b: any) =>
              Date.parse(a.startDate) - Date.parse(b.startDate)
          )[0]?.name ?? ''
        );
      }
      function renderSkillsText(skills: any[]) {
        const skillsTruncatedList = skills
          .slice(0, 3)
          .map((skill: any) => skill.name as string)
          .join(', ');

        if (skills.length <= 3) {
          return (
            <span className={skillsTruncatedList}>{skillsTruncatedList}</span>
          );
        }

        return (
          <span className="flex flex-row">
            <span className="block">
              {skillsTruncatedList}{' '}
              <span className="underline cursor-default">
                + {skills.length - 3} more
              </span>
            </span>
          </span>
        );
      }

      function renderLocation(location: string) {
        let prettyLocation = 'N/A';
        switch (location) {
          default:
            prettyLocation = location;
            break;
          case null:
          case '':
          case 'Unknown':
            break;
        }
        return <span className="flex">{prettyLocation}</span>;
      }
      function renderActionMenu() {
        const actions: {
          name: string;
          icon: string;
          callback: () => void;
          primary?: boolean;
        }[] = [
          {
            name: 'View ETM Profile',
            icon: 'person_search',
            callback: () => navigate(`/elastic_talent_board/${user._id}/view`),
          },
          {
            name: 'Add Skills',
            icon: 'add_box',
            callback: () =>
              navigate(`/elastic_talent_board/${user._id}/add_skills`),
          },
          {
            name: 'Add Roles',
            icon: 'group_add',
            callback: () =>
              navigate(`/elastic_talent_board/${user._id}/add_roles`),
          },
          {
            name: 'Review/Adjust Skills',
            icon: 'settings',
            callback: () =>
              navigate(`/elastic_talent_board/${user._id}/review_skills`),
          },
          {
            name: 'Manage Files',
            icon: 'file_copy',
            callback: () => {
              setDocumentDialogId(user._id);
              setDocumentDialogOpen(true);
              setActionMenuOpened(-1);
            },
          },
          {
            name: 'Delete User',
            icon: 'delete',
            primary: true,
            callback: () => {
              setActionMenuOpened(-1);
              handledeleteUser(user._id);
              setHovered(-1);
            },
          },
        ];

        return (
          <div
            className="flex flex-col"
            ref={index === actionMenuOpened ? actionMenuRef : null}
          >
            <span
              className="flex relative material-icons cursor-pointer text-primary"
              onClick={() => {
                if (index !== actionMenuOpened) {
                  setActionMenuOpened(index);
                } else {
                  setActionMenuOpened(-1);
                }
              }}
            >
              more_horiz
            </span>
            <ActionMenu visible={index === actionMenuOpened}>
              <div className="flex flex-col gap-4 py-4">
                <img
                  className="flex w-10 h-10 mx-4 rounded-full"
                  src={user.avatar}
                  alt="user_profile"
                />
                <span className="flex font-bold px-4">
                  {user.firstName} {user.lastName}
                </span>
                <div className="flex flex-col">
                  {actions.map((action, actionIndex) => (
                    <button
                      key={actionIndex}
                      className={`${classnames({
                        'text-primary': action.primary,
                      })} flex flex-row items-center gap-2 px-4 py-3 hover:bg-background text-sm`}
                      onClick={action.callback}
                    >
                      <span className="flex material-icons material-symbols-outlined text-sm">
                        {action.icon}
                      </span>
                      <span className="flex">{action.name}</span>
                    </button>
                  ))}
                </div>
              </div>
            </ActionMenu>
          </div>
        );
      }

      return new Map<string, JSX.Element>([
        [
          tableHeaders.firstName,
          <span className="flex">{user?.firstName ?? 'N/A'}</span>,
        ],
        [
          tableHeaders.lastName,
          <span className="flex">{user?.lastName ?? 'N/A'}</span>,
        ],
        [
          tableHeaders.role,
          <span className="flex">{renderRoleText(user?.roles ?? [])}</span>,
        ],
        [
          tableHeaders.skills,
          <span className="flex">{renderSkillsText(user?.skills ?? [])}</span>,
        ],
        [
          tableHeaders.availability,
          renderAvailabilityText(user?.projectDetail ?? null),
        ],
        [tableHeaders.location, renderLocation(user?.place?.location)],
        [tableHeaders.actions, renderActionMenu()],
      ]);
    });
  }

  function updateSelection(index: number) {
    const newSelection = new Set(selected);
    if (selected.has(index)) newSelection.delete(index);
    else newSelection.add(index);
    setSelected(newSelection);
  }

  const hoveredUser = hovered > -1 ? users[hovered] : null;
  const hoveredUserLatestPaymentInfo = hoveredUser
    ? getLatestPayment(hoveredUser)
    : null;

  return (
    <>
      <TalentComparison
        isOpen={talentComparisonOpen}
        users={users.filter((_, index) => selected.has(index))}
        onCloseClick={() => {
          setTalentComparisonOpen(false);
        }}
      />
      <TalentTooltip
        visible={
          hovered > -1 &&
          actionMenuOpened === -1 &&
          !documentDialogOpen &&
          !deleteDialogOpened &&
          countTalents > 0
        }
        hoveredUser={hoveredUser}
        hoveredUserLatestPaymentInfo={hoveredUserLatestPaymentInfo}
      />
      <DocumentDialog
        user={documentDialogId}
        assetType="user"
        open={documentDialogOpen}
        handleClose={() => setDocumentDialogOpen(false)}
      />
      <div className="flex flex-col gap-4">
        <Heading text="Elastic Talent Finder" size="large" />
        <Card>
          <div className="flex px-1">
            <Heading text="I'm looking for" size="medium" />
            <span
              className="flex shrink text-primary text-xs cursor-pointer"
              onClick={() => setShowFullFilters(!showFullFilters)}
            >
              {showFullFilters && 'Less Filters'}
              {!showFullFilters && 'More Filters'}
            </span>
          </div>
          <div
            className={`${classnames({
              'max-h-14': !showFullFilters,
              'overflow-hidden': !showFullFilters,
            })} flex flex-row gap-3 flex-wrap py-2 px-1 transition-all`}
          >
            <Textfield
              type="text"
              placeholder="Search..."
              icon="search"
              onEnterPressed={(value) => {
                const strVal = `${value}`.trim();
                if (strVal === '') return;
                logEvent(EventType.search, { "value" : strVal,  "searchTerms" : searchTerms.toString() })
                setSearchTerms([...searchTerms, strVal]);
              }}
            />
            <Dropdown
              multi
              showSearch
              showTitle
              text="Roles"
              options={new Set(roles)}
              onChange={(selections) => setRolesFilter([...selections])}
            />
            <Dropdown
              multi
              showSearch
              showTitle
              text="Skills"
              options={new Set(skills)}
              onChange={(selections) => setSkillsFilter([...selections])}
            />
            <Dropdown
              multi
              showSearch
              showTitle
              text="Location"
              options={new Set(locations)}
              onChange={(selections) => setLocationsFilter([...selections])}
            />
            <Dropdown
              multi
              showSearch
              showTitle
              text="Project"
              options={new Set(projects)}
              onChange={(selection) => setProjectFilter([...selection])}
            />
            <Dropdown
              multi
              showSearch
              showTitle
              text="Agencies"
              options={new Set(Object.values(Agency))}
              onChange={(selections) => setAgenciesFilter([...selections])}
            />
            <Dropdown
              multi
              showSearch
              showTitle
              text="Languages"
              options={new Set(languages)}
              onChange={(selections) => setLanguageFilter([...selections])}
            />
            <AvailabilityDropdown
              text="Availability"
              onChange={setAvailabilityFilter}
            />
            <Dropdown
              multi
              showSearch
              showTitle
              text="Security Clearances"
              options={new Set(Object.values(SecurityClearance))}
              onChange={(selections) =>
                setClearanceFilter(
                  [...selections].map((selection) =>
                    Object.keys(SecurityClearance).find(
                      (key) =>
                        SecurityClearance[
                          key as keyof typeof SecurityClearance
                        ] === selection
                    )
                  )
                )
              }
            />
          </div>
        </Card>
        <div className="flex flex-wrap gap-4">
          {[...searchTerms].map((searchTerm) => (
            <Chip
              className="bg-white text-black"
              text={searchTerm}
              onClick={() =>
                setSearchTerms(
                  searchTerms.filter((term) => term !== searchTerm)
                )
              }
            />
          ))}
          {[...searchTerms].length > 0 && (
            <Chip
              className="bg-white text-black"
              text="Clear All"
              onClick={() => setSearchTerms([])}
            />
          )}
        </div>
        <Card>
          <div className="flex px-1 items-center">
            <Heading
              text={`Total ETMs Found ${countTalents.toLocaleString('en-US')}`}
              size="medium"
            />
            <div className="flex shrink items-center">
              {(selected.size < 1 || selected.size > 3) && (
                <span className="flex text-sm mr-4">
                  Select up to 3 talents to compare
                </span>
              )}
              {selected.size >= 1 && selected.size <= 3 && (
                <span
                  className="flex text-sm mr-4 underline cursor-pointer"
                  onClick={() => setSelected(new Set([]))}
                >
                  Clear
                </span>
              )}
              <button
                className={`${classnames({
                  'text-slate-400': selected.size < 1 || selected.size > 3,
                  'border-slate-400': selected.size < 1 || selected.size > 3,
                  'text-primary': selected.size > 0 && selected.size < 4,
                  'border-primary': selected.size > 0 && selected.size < 4,
                })} flex text-sm font-bold px-4 py-2 border rounded-md `}
                onClick={() => {
                  if (selected.size > 0 && selected.size < 4) {
                    setTalentComparisonOpen(true);
                  }
                }}
              >
                Compare{' '}
                {selected.size > 0 && selected.size < 4 && `(${selected.size})`}
              </button>
            </div>
          </div>
          <Table
            onHoverChangeColor
            toggleSelection={(index) => updateSelection(index)}
            selected={selected}
            headings={Object.values(tableHeaders)}
            data={getTableData()}
            onMouseEnter={(index) => setHovered(index)}
            onMouseLeave={(index) => setHovered(-1)}
          />
          <Pagination
            limit={pagination.limit}
            page={pagination.page}
            total={totalTalents}
            count={countTalents}
            onChange={(limit, page) => {
              setPagination({
                page,
                limit,
              });
            }}
          />
        </Card>
      </div>
    </>
  );
}