import React, { useState, useContext, useRef } from 'react';
import { ItemWrapper } from './Item.style';
import { ThemeContext } from '../theme-context';
import PropTypes from 'prop-types';

const MAX_LENGTH = 15;

function Item(props) {
  //create ref
  let ref = useRef(null);

  const isSelected = props.data.quadrant === props.selectedQuad;

  //context variables
  const { itemFontSize, fontFamily } = useContext(ThemeContext);

  //state variables
  const [isHovered, setIsHovered] = useState(false);

  const shortName =
    props.data.name.length > MAX_LENGTH
      ? props.data.name.substr(0, MAX_LENGTH) + '...'
      : props.data.name;

  const onMouseToggle = () => {
    setIsHovered(!isHovered);
  };

  return (
    <ItemWrapper
      className="blip"
      id={'blip-' + props.data.id}
      transform={
        ' rotate(' +
        props.rotateDegrees +
        ') translate(' +
        props.data.x +
        ',' +
        props.data.y +
        ')'
      }
      onMouseEnter={onMouseToggle}
      onMouseLeave={onMouseToggle}
      ref={ref}
      style={{
        opacity: isHovered || isSelected ? '1.0' : '0.7',
        fontWeight: isHovered || isSelected ? 'Bold' : 'Normal',
      }}
    >
      {props.data.core > 0 ? (
        <circle r={`${(4 * 1/props.zoomLevel)}px`} fill={props.isQuadrantHovered || isHovered || isSelected ? props.strokeColor: "#CCCCCC"} />
      ) : (
        <circle r={`${(4 * 1/props.zoomLevel)}px`} fill={props.isQuadrantHovered || isHovered || isSelected ? props.strokeColor: "#CCCCCC"} />
      )}
      <text
        className={'name'}
        dx={`${(7 * 1/props.zoomLevel)}px`}
        dy={`${(5 * 1/props.zoomLevel)}px`}
        fill={props.isQuadrantHovered || isHovered || isSelected ? props.strokeColor : "#999999"}
        fontSize={itemFontSize * 1/props.zoomLevel}
        fontFamily={fontFamily}
      >
        {isHovered ? props.data.name : shortName}
      </text>
    </ItemWrapper>
  );
}

Item.propTypes = {
  rotateDegrees: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
};

export default Item;
