import Availability from 'new-app/components/availability';

export const renderAvailabilityText = (
  projects:
    | {
        name?: string;
        startDate?: string;
        endDate?: string;
      }[]
    | null
): JSX.Element => {
  if (!projects || projects.length === 0) {
    return <Availability date={null} />;
  }
  // find a null / undefined date
  const projectOnGoing = projects.find(
    (project) => !(project?.endDate ?? null)
  );
  if (projectOnGoing) {
    return <Availability date={null} />;
  }
  const maximumEndDate = Math.max(
    ...projects.map((project) => Date.parse(project.endDate))
  );
  return <Availability date={new Date(maximumEndDate)} />;
};
