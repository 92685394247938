import { Skeleton } from '@mui/material';

export default function () {
    var height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;
    const skeletonCounts = Math.ceil((height - 370) / 200);

    return (
        <div className='flex flex-col md:flex-row md:gap-x-[77px] mt-8 pb-[100px]'>
            <div className='flex flex-col gap-2 w-full'>
                {Array(skeletonCounts || 3).fill('').map((item, index) => (
                    <div className="flex flex-col w-[90%] mb-6" key={index}>
                        <div className='flex flex-row gap-x-4 items-center w-[100%]'>
                            <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '40%' }} />
                        </div>
                        <div className='flex flex-col w-[100%] pl-4'>
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '40%', borderRadius: '8px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '47%', borderRadius: '8px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '45%', borderRadius: '8px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '42%', borderRadius: '8px' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%', borderRadius: '8px' }} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}