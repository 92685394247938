import { useContext } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Card from './Card';
import links from './links';
import {Auth} from 'aws-amplify';
import { authContext, authLogic } from 'context/auth';
import { permissionRoute } from 'context/constants';
import { adminRole } from 'constants/index';
import { LockIcon } from 'assets/icons';
import Button from 'new-app/components/button';

function Admin(): JSX.Element {
  const { authUser } = useContext(authContext);
  const allowedLinks = links.filter((link) => {
    return authUser && authLogic(link.key, authUser['cognito:groups']) && !link.disabled;
  });

  const renderEmpty = () => {
    return (
      <div className="flex h-[500px] justify-center items-center">
        <div className="flex flex-col gap-8 items-center max-w-[469px]">
          <LockIcon />
            <h2 className="m-0 font-poppins font-semibold leading-10 text-[32px] text-cool-900">
              Sorry, you don't have access to Distributed Command.  
            </h2>
          <div className='text-center'>
            <p className="font-inter font-normal leading-5 text-[14px] text-cool-900">To request access, please
              contact Distributed team at</p>
            <a href="mailto:command@distributed.com" className="font-inter font-normal leading-5 text-[14px] text-marine-400">command@distributed.com</a>
          </div>
          <div>
            <Button label='Log out' className='!h-10 !font-inter !font-semibold !text-[14px]' onClick={async() => await Auth.signOut()}/>
          </div>
        </div>
      </div>
    );
  };
  const renderLinks = () => {
    const links = allowedLinks.map((link) => (
      <Grid item xs={12} md={6} lg={3} key={link.title}>
        <MDBox mb={1.5} mt={1.5}>
          <Card image={link.image} title={link.title} link={link.link} />
        </MDBox>
      </Grid>
    ));

    return (
      <MDBox my={3}>
        <Grid container spacing={3}>
          {links}
        </Grid>
      </MDBox>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {allowedLinks.length === 0 || allowedLinks.length === 1 ? renderEmpty() : renderLinks()}
    </DashboardLayout>
  );
}

export default Admin;
