import { default as http } from 'axios';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import {EventType, logEvent} from "../context/analytics";

http.defaults.headers.post['Content-Type'] = 'application/json';

const requestInterceptors = (config) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        config.headers.Authorization =
          'Bearer ' + session.getIdToken().getJwtToken();
        resolve(config);
      })
      .catch(() => {
        resolve(config);
      });
  });
};

const requestInterceptorErrors = (error) => {
  return Promise.reject(error);
};

const responseInterceptors = (response) => response;
const responseInterceptorErrors = (error) => {
  if (error?.response?.status == 401) {
    logEvent(EventType.logout);
    Auth.signOut();
    window.location.href = '/login';
    toast.warn(error.message);
  }
  return Promise.reject(error);
};

const { REACT_APP_BACKEND_URL } = process.env;

const axiosInstance = http.create();
axiosInstance.defaults.baseURL = `${REACT_APP_BACKEND_URL}/api/v1`;
axiosInstance.interceptors.request.use(
  requestInterceptors,
  requestInterceptorErrors
);
axiosInstance.interceptors.response.use(
  responseInterceptors,
  responseInterceptorErrors
);

// const axios = http.create();
// axios.defaults.baseURL = `${REACT_APP_BACKEND_URL}/api/${REACT_APP_LATEST_BACKEND_API_VERSION}`;
// axios.interceptors.request.use( requestInterceptors, requestInterceptorErrors);
// axios.interceptors.response.use(responseInterceptors, responseInterceptorErrors);

export { axiosInstance as axios };
export { requestInterceptors, requestInterceptorErrors, responseInterceptors, responseInterceptorErrors };

export default axiosInstance;
