import Availability from 'new-app/components/availability';
import { User } from 'new-app/models/user';
import Tooltip from '../../../components/tooltip';
import { getLatestPayment } from 'new-app/helpers/user';
import { renderAvailabilityText } from './utils/renderAvailabilityText';

export type Props = {
  visible: boolean;
  hoveredUser: User;
  hoveredUserLatestPaymentInfo?: ReturnType<typeof getLatestPayment>;
};

export const TalentTooltip = ({
  visible,
  hoveredUser,
  hoveredUserLatestPaymentInfo,
}: Props) => {
  return (
    <Tooltip visible={visible}>
      <div className="flex flex-col p-4">
        <span className="flex text-black text-sm font-bold">
          {hoveredUser?.firstName} {hoveredUser?.lastName}
        </span>
        <span className="flex text-text-mild-contrast text-xs">
          {hoveredUser?.email}
        </span>
        <div className="flex flex-col py-4 gap-4 text-xs">
          <div className="flex flex-row items-center gap-2">
            <span className="material-icons">badge</span>
            {renderAvailabilityText(hoveredUser?.projectDetail)}
          </div>
          <div className="flex flex-row items-center gap-2">
            <span className="material-icons">language</span>
            <span>{hoveredUser?.place?.language}</span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <span className="material-icons">folder_copy</span>
            <span>
              {
                hoveredUser?.projectDetail.filter(
                  (project) => Date.parse(project.endDate) <= Date.now()
                ).length
              }{' '}
              Completed Projects
            </span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <span className="material-icons">local_atm</span>
            <span>
              {hoveredUserLatestPaymentInfo?.amount}{' '}
              {hoveredUserLatestPaymentInfo?.currency} /{' '}
              {hoveredUserLatestPaymentInfo?.frequency}
            </span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <span className="material-icons">insights</span>
            <span>
              {hoveredUser?.skills.map((skill) => skill.name).join(', ')}
            </span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <span className="material-icons">business_center</span>
            <span>
              {hoveredUser?.roles.map((role) => role.name).join(', ')}
            </span>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
