import React from 'react';
import ErrorText from 'new-app/components/errorText';


export interface InputProps extends React.InputHTMLAttributes<any> {
    label: string;
    className?: string;
}

export default function (props: any) {
  const { register, errors, icon, required, pattern, rules={}, validate, className, inputProps, fullWidth, InputLabelProps, InputProps, ...restProps } = props;
  const inputClasses = `outline-0 ${icon ? 'pl-10' : 'pl-4'} py-[10px] h-10 min-w-[120px] w-full
    ${(errors && errors[props.name]?.message) ? 'bg-[#FEEFEF] !border-[#EE6C6C]' : ''}
    bg-white border border-[#D9D9D9] 
    hover:border-[#B3B3B3]
    disabled:bg-[#F7F7F7] 
    placeholder:text-cool-600
    focus:border-[#3168F4] 
    rounded text-cool-900 font-inter font-normal text-[14px] leading-5 ${className}`;
    
  return (
    <div className='flex flex-col w-full' ref={InputProps?.ref}>
        {props.label && <label className={'text-cool-700 font-inter font-normal text-[14px] leading-5 mb-2'}>{props.label}</label>}
        <div className='relative'>
          {icon ? <span className='absolute left-4 top-3' {...InputLabelProps}>{icon}</span> : null}
          <input
              {...inputProps}
              className={inputClasses}
              autoFocus
              type='text'
              {...register?.(props?.name ?? '', {required: required ? `${props.label} is required` : false,
                validate: (value: any) => validate ? validate(value) : true,
                pattern: pattern ? pattern : false,
                ...rules
              })}
              {...restProps}

              placeholder={props.placeholder || (props.label ?? '')}
          />
        </div>
        {(errors && errors[props.name]?.message) ? <ErrorText className="mt-2" text={errors[props.name]?.message} /> : null}
    </div>
  );
}
