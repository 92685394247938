import ButtonComponent from 'new-app/components/button';
import { Shortlist } from 'new-app/models/profile';
import { SmallEditIcon, DeleteIcon, PlusIcon1, UserListIcon } from 'assets/icons';

export interface ShortlistsDrawerProps {
  onAdd: (event: any, createMode?: boolean) => void;
  onEdit: (shortlist: Shortlist) => void;
  onDelete: (shortlist: Shortlist) => void;
  onSelect: (shortlist: Shortlist) => void;
  selectedShortlist: Shortlist;
  shortlists?: Shortlist[];
}

export default function ShortlistsDrawer(props: ShortlistsDrawerProps) {
  const { shortlists, selectedShortlist, onAdd, onEdit, onSelect, onDelete } = props;

  return (
    <div className="flex flex-col bg-white p-3 pt-6 gap-6 min-w-[256px] h-full grow">
      <div className="flex flex-row justify-between">
        <p className='text-cool-900 font-semibold font-inter text-[16px] '>My Shortlists ({shortlists.length})</p>
        <span onClick={(event: any) => onAdd(event, true)}><PlusIcon1 className='relative cursor-pointer' /></span>
      </div>

      <div className='flex flex-col gap-4'>
        {shortlists && shortlists.map((item: Shortlist) => {
          return (
            <div className='flex flex-row justify-between group items-center cursor-pointer' key={item.id}>
              <div className='flex flex-row group-hover:w-[185px] gap-2' onClick={() => onSelect(item)}>
                <span className={`w-1 h-6 block ${selectedShortlist?.id === item?.id ? 'bg-primary-blue' : 'bg-[transparent'}`} />
                <p className={`${selectedShortlist?.id === item?.id ? 'text-[#144FE7]' : 'text-[#555E6D]'} hover:text-[#3168F4] font-semibold font-inter text-[14px]`}>{item.name}</p>
              </div>
              <div className='flex flex-row gap-2 items-center hidden group-hover:flex'>
                <SmallEditIcon onClick={() => onEdit(item)} />
                <DeleteIcon onClick={() => onDelete(item)} />
              </div>
            </div>
          )
        })}
        {shortlists.length === 0 ? (
          <div className='flex flex-col gap-6'>
            <p className="text-cool-600 font-normal font-inter text-[14px] leading-5">You currently have no shortlists</p>
            <ButtonComponent
              className='!w-full !h-10 hover:!bg-marine-600 active:!bg-marine-800'
              label='New shortlist'
              icon={<PlusIcon1 color={'white'} />}
              onClick={(event: any) => onAdd(event, true)}
            />
          </div>
        ) : null}

      </div>
    </div>
  )
}