import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import BreadCrumbs from './breadCrumbs';
import TabPanel from '@mui/lab/TabPanel';
import { useForm } from 'react-hook-form';
import UserPhoto from './components/userPhoto';
import PersonalInfo from './components/personalInfo';
import ContractInfo from './components/contractInfo';
import { useLocation, useNavigate, useParams  } from 'react-router-dom';
import { getNationalityList } from 'utils';
import CircularProgress from '@mui/material/CircularProgress';
import { updateProfile, GetFullProfile } from 'new-app/reducers/candidateSearch';
import Notification from 'new-app/components/notification';
import { fetchUserRoles } from 'new-app/reducers/roles';
import Button from 'new-app/components/button';

import { Skeleton } from '@mui/material';

type UserProfileOptions = {
    [key: string]: any;
}

const tabClasses={
    root: '!text-[14px] !normal-case !font-semibold text-[#555E6D] !font-inter !leading-5 !pl-0 !pr-0 !mr-4',
    selected: '!text-[#144FE7]'
};

const tabPanelClasses={
    root: '!p-0 !pt-8',
};

const titles: UserProfileOptions = {
    '1': 'Personal Info',
    '2': 'Contract Info'
}


function Wrapper(props: any) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control, setValue, getValues, trigger
    } = useForm();
    const navigation = useNavigate();

    var height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;
    const skeletonCounts = Math.ceil((height - 370) / 200);
    
    const [isSaving, setSaving] = useState(false);
    const [roles, setRoles] = useState([])
   
    const { isLoading, userProfile, refresh, value: tab} = props;

    useEffect(() => {
        getRoles();
    }, [])

    const getRoles = async () => {
        try {
            const res: any = await fetchUserRoles();
            const defaultRoles = res.items.sort((a: any, b: any) =>
                a?.name.localeCompare(b?.name)
            ).map((i: any) => ({
                title: i.name,
                value: i.id,
            }));
            setRoles(defaultRoles);
        } catch (error) {
        }
    };

    useEffect(() => {
        if (userProfile) {
            const fullName = userProfile?.name || "",
            firstName = fullName.split(' ').slice(0, -1).join(' '),
            lastName = fullName.split(' ').slice(-1).join(' ');
            const nationalityList: any = getNationalityList();
            const nationality = Object.keys(nationalityList).reduce((prev: string, current: string) => {
                return nationalityList[current] === userProfile?.nationality ? current : prev;
            }, '');
            const addressLookupValue = userProfile?.locationDetails?.addressLookupValue;
            const fields = {
                ...userProfile,
                firstName,
                lastName,
                nationality,
                addressLookupValue,
            }
            reset(fields);
        }
    }, [userProfile]);

    function clean(obj: any) {
        for (var propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined || !obj[propName]) {
            delete obj[propName];
          }
        }
        return obj
    }

    const onSubmit = async (data: any) => {
        const {
            firstName,
            lastName,
            distributedEmail,
            internalEmail,
            dayRate,
            availableDate,
            preferredRateCurrency,
            recentRoleId,
            countryName,
            countryIso,
            phone,
            preferredContractLength,
            preferredHoursPerWeek,
            cityName,
            addressLookupValue,
            communityStatus,
        } = data;
        const nationalityList: any = getNationalityList();
        const nationality = nationalityList[data?.nationality];
        const personInfo = {
            firstName,
            lastName,
            distributedEmail,
            personalEmail: internalEmail,
            telephone: phone,
            roleId: recentRoleId,
            nationality,
            countryName,
            countryIso,
            cityName,
            communityStatus,
            addressLookupValue,
        }
        const contractInfo = {
            dayRate,
            dayRateCurrencyCode: preferredRateCurrency,
            contractLength: preferredContractLength,
            hourPerWeek: preferredHoursPerWeek,
            availableDate,
        }
        let formFields;
        switch (tab) {
            case '1':
                formFields = personInfo;break;
            case '2':
                formFields = contractInfo;break;
            default:
                formFields = personInfo;break;
        }
        try {
            setSaving(true);
            if (userProfile?.id) {
                const res = await updateProfile(userProfile?.id, clean(formFields));
                props.setNotification({
                    message: 'Engineer Profile Updated!',
                    open: true,
                    severity: 'success'
                });
                refresh();
            }
        } catch (error) {
            props.setNotification({
                message: 'Profile Update failed',
                open: true,
                severity: 'error'
            });
        } finally {
            setSaving(false)
        }
    }

    const onChangeField = (name: string, value: any) => {
        setValue(name, value);
        trigger(name);
    };

 

    function getUserForm(value: string) {
        const formProps = {
            register, errors, control, onChangeField, getValues, setValue
        }
        switch(value) {
            case '1':
                return <PersonalInfo {...formProps} roles={roles} />;
            case '2':
                return <ContractInfo {...formProps} />;
            default: 
                return <PersonalInfo {...formProps} />;
        }
    }

    if (isLoading) {
        return (
            <div className='flex flex-col md:flex-row md:gap-x-[77px] gap-y-4 pb-[100px]'>
                <div className='flex flex-col gap-1'>
                    <Skeleton variant="circular" width={64} height={64} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', marginTop: '12px', width: '120px', borderRadius: '8px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px', borderRadius: '8px' }} />
                </div>
                <div className='flex flex-col gap-2 w-full'>
                    {Array(skeletonCounts || 3).fill('').map((item, index) => (
                        <div className="flex flex-col w-[100%] mb-6" key={index}>
                            <div className='flex flex-row gap-x-4 items-center w-[100%]'>
                                <Skeleton variant="text" sx={{ fontSize: '1.8rem', width: '50%' }} />
                            </div>
                            <div className='flex flex-col w-[100%] pl-4'>
                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '40%', borderRadius: '8px' }} />
                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '55%', borderRadius: '8px' }} />
                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%', borderRadius: '8px' }} />
                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '42%', borderRadius: '8px' }} />
                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%', borderRadius: '8px' }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col md:flex-row md:gap-x-[77px] gap-y-4 pb-[100px]'>
            <UserPhoto name={userProfile?.name || ""} role={userProfile?.recentRole || ""}/>
          
            <div className='flex flex-col gap-y-6 w-full '>
                <p className='text-[#191C25] font-poppins font-semibold text-4 leading-6'>{titles[props.value] || 'Personal Info'}</p>
                <div className='max-w-[560px]'>
                    {getUserForm(props.value)}
                </div>
                <div className='absolute flex items-center left-0 bottom-0 w-full bg-[#F7F7F7] h-[72px] flex flex-row justify-end gap-x-4 pr-8 md:pr-[200px]'>
                    <Button
                        className='!bg-grey-100 hover:!bg-grey-200 active:!bg-grey-400 !text-[#666666] text-[14px] !h-10 !px-6 !py-2'
                        label='Cancel'
                        onClick={(e: any) => {e.preventDefault()}}
                    />
                    <Button
                        type="submit"
                        isLoading={isSaving}
                        className='!h-10 hover:!bg-marine-600 active:!bg-marine-800 flex items-center gap-2 text-white rounded-full font-inter font-semibold text-[14px] leading-6 tracking-[.01em] h-10 px-6 py-2'
                        label='Save'
                    />
                </div>
            </div>
        </form >
    )
} 

export default function LabTabs() {
  const [value, setValue] = React.useState('1');
  const {state}: any = useLocation();
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  let { id } = useParams();
  const [notification, setNotification] = useState({
    message: '',
    severity: '',
    open: false
  });

  const onClose = () => {
    setNotification({
        message: '',
        severity: '',
        open: false
    })
  }

  const getUserProfile = async (init=false) => {
    init && setLoading(true)
    try {
        const userProfile = await GetFullProfile(id);
        setUserProfile(userProfile);
    } catch (error) {
        setNotification({
            message: 'Failed to retrieve user profile',
            open: true,
            severity: 'error'
        });
    } finally {
        setLoading(false);
    }
  }

  useEffect(() => {
    if (state || id) {
        getUserProfile(true)
    }
    }, [state])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className='pl-4 pr-5 pt-6 md:pl-[80px] md:pr-[80px] bg-white w-full h-full min-h-[100vh]'>
        <BreadCrumbs name={userProfile ? userProfile['name'] : state?.name} />
        <p className='pt-8 text-[24px] font-semibold text-[#191C25] leading-8 font-poppins'>Edit Profile</p>
        <div className='mt-6'>
            <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs" classes={{ indicator: '!bg-[#144FE7]' }}>
                    <Tab label="Personal Info" value="1" classes={tabClasses} />
                    <Tab label="Contract Info" value="2" classes={tabClasses} />
                </TabList>
                </Box>
                <TabPanel value={value} classes={tabPanelClasses}>
                    <Wrapper value={value} userProfile={userProfile} isLoading={isLoading} refresh={getUserProfile} setNotification={setNotification}/>
                </TabPanel>
            </TabContext>
            </Box>
        </div>
        <Notification {...notification} onClose={onClose}/>
    </div>
  );
}