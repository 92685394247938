import { axios } from 'utils/axios';
import { fetch } from '../helpers/cachedAxios';
import { SKillCategoryList } from 'interfaces';

export async function fetchCategories(
  options: any = { limit: 99, page: 0 }
): Promise<SKillCategoryList> {
  const result = await fetch('/engineer/skill-categories', 'GET', {
    params: { ...options },
  });
  
  return result;
}

export async function addCategory(category: string | number) {
  const result = await axios.post('/engineer/skill-category', { name: category });
  return result;
}

export async function updateCategory(id: number, category: string) {
  const result = await axios.put(`/engineer/skill-category/${id}`, { name: category });
  return result;
}
