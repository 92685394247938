import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

export interface WrapperProps {
  children: React.ReactNode;
  disableSpace?: boolean;
}


export default function Wrapper(props: WrapperProps) {
  return <DashboardLayout disableSpace={props.disableSpace}>{props.children}</DashboardLayout>;
}
