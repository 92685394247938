import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import InputComponent from 'new-app/components/input';
import Label from 'new-app/components/label';
import ErrorText from 'new-app/components/errorText';
import SelectComponent from 'new-app/components/select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import RadioCard from 'new-app/components/radioCard';
import { availabilityLists } from 'constants/availabilityLists';
import { currencyList } from 'constants/currencies';
import { contractLengthList } from 'constants/contract-length-list'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export default function ContractInfo(props: any) {
    const { register, errors, control, onChangeField, getValues } = props;
    const [isOpen, setOpen] = useState(false);
    const isMatched = availabilityLists.some(option => moment(option.value, 'YYYY-MM-DD').isSame(moment(getValues('availableDate'), 'YYYY-MM-DD')))

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={'flex flex-col gap-y-4'}>
                <div className={'flex flex-col md:flex-row gap-x-4'}>
                    <InputComponent label='Rate per Day' name='dayRate' errors={errors} register={register} required />
                    <div className='flex flex-col w-full'>
                        <Label label='Currency' />
                        <Controller
                            render={
                                ({ field }) =>
                                    <SelectComponent
                                        value={field.value}
                                        errors={errors}
                                        name="preferredRateCurrency"
                                        items={currencyList}
                                        onChange={(selections) => onChangeField('preferredRateCurrency', selections)}
                                    />
                            }
                            control={control}
                            name="preferredRateCurrency"
                        />
                        <ErrorText text={errors.preferredRateCurrency?.message} />
                    </div>
                    <InputComponent label='Hours per Week' name='preferredHoursPerWeek' type='number' validate={(value: any) => value <= 40 ? true : 'Max 40 hours allowed'} errors={errors} register={register} />
                    <div className='flex flex-col w-full'>
                        <Label label='Contract Length' />
                        <Controller
                            render={
                                ({ field }) =>
                                    <SelectComponent
                                        value={field.value}
                                        errors={errors}
                                        name="preferredContractLength"
                                        items={contractLengthList}
                                        onChange={(selections) => onChangeField('preferredContractLength', selections)}
                                    />
                            }
                            control={control}
                            name="preferredContractLength"
                        />
                        <ErrorText text={errors.preferredContractLength?.message} />
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <Label label='Availability' />
                    <div className="grid grid-cols-2 md:flex gap-4">
                        {availabilityLists.map((option) => (
                            <RadioCard
                                key={option.value}
                                title={option.title}
                                name="availableDate"
                                onChange={event => {
                                    onChangeField(event.target.name, option.value)
                                }}
                                value={option.value}
                                register={register}
                                className="w-full flex items-center"
                                defaultChecked={moment(option.value, 'YYYY-MM-DD').isSame(moment(getValues('availableDate'), 'YYYY-MM-DD'))}
                            />
                        ))}
                        <Controller
                            control={control}
                            name="availableDate"
                            render={({ field }) => (
                                <div onClick={() => { !isOpen && setOpen(!isOpen) }} className='relative cursor-pointer w-full'>
                                    <DatePicker
                                        open={isOpen}
                                        onClose={() => setOpen(false)}
                                        onOpen={() => setOpen(true)}
                                        value={moment(field.value)}
                                        format='DD MMMM YYYY'
                                        onChange={(newValue: any) => {
                                            onChangeField('availableDate', moment(newValue).toISOString(true))}
                                        } 
                                        slotProps={{
                                            textField: { placeholder: 'Pick a date', disabled: true }
                                        }}
                                        sx={{
                                            width: '100%',
                                            '.MuiInputBase-root': { borderRadius: '8px', background: !isMatched ? '#144FE7 !important' : '#F2F5FE', width: '150px', minWidth: '100%', border: 0, height: '48px' },
                                            '.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': { border: 0, borderColor: 'transparent !important' },
                                            '.MuiInputBase-root .MuiOutlinedInput-notchedOutline': { border: 0, borderColor: 'transparent !important' },
                                            '.MuiInputBase-input': { fontSize: '14px', color: !isMatched ? 'white' : '#144FE7', fontFamily: 'Noto sans' },
                                            '.MuiInputBase-input::placeholder': { fontSize: '14px', fontWeight: 600, opacity: 1, color: !isMatched ? 'white' : '#144FE7 !important', fontFamily: 'Noto sans' },
                                            '.MuiInputBase-input.Mui-disabled': { fontSize: '14px', fontWeight: 600, opacity: 1, '-webkit-text-fill-color': !isMatched ? 'white' : '#144FE7', border: 0, fontFamily: 'Noto sans' },
                                            '.MuiInputAdornment-root': { display: 'none' },
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <ErrorText text={errors.nationality?.message} />
                </div>
            </div>
        </LocalizationProvider>
    );
}