import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

export interface TextFieldProps {
  type: React.HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: string | number) => void;
  onIconClick?: () => void;
  onEnterPressed?: (value: string | number) => void;
  icon?: string;
  endIcon?: string;
  customWidth?: boolean;
}

const Search = (props: TextFieldProps) => {
  const [value, setValue] = useState<string | number>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <React.Fragment>
      <label
        className={`${classnames({
          'min-w-150': !props.customWidth,
        })} flex grow basis-72  relative focus-within:text-gray-600 max-h-10`}
      >
        {props.icon && (
          <span className="material-icons text-text-low-contrast pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3 pt-1">
            {props.icon}
          </span>
        )}
        <input
          className={`${classnames({
            'pl-10': props.icon,
          })} text-sm appearance-none border-2 rounded-md w-full py-2 px-3 text-black leading-tight placeholder:text-text-low-contrast hover:bg-background focus:outline-none focus:border-half-gray`}
          autoFocus
          type={props.type}
          placeholder={props.placeholder}
          value={value}
          onChange={(event) => {
            let newValue: string | number = event.target.value;
            if (props.type === 'number') {
              newValue = event.target.valueAsNumber;
            }
            setValue(newValue);
            if (props.onChange) {
              props.onChange(newValue);
            }
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter' && props.onEnterPressed) {
              props.onEnterPressed(value);
            }
          }}
        />
         {props.endIcon && (
          <div
            className='rounded-r z-10 bg-dark-bg-gray text-text-low-contrast w-10 h-10 text-center absolute top-1/2 transform -translate-y-1/2 right-0 pt-2 cursor-pointer'
            onClick={() => props.onIconClick && props.onIconClick()}
          >
            <span className="material-icons">
              {props.endIcon}
            </span>
          </div>
        )}
      </label>
    </React.Fragment>
  );
}
export default React.memo(Search)