export const getCurrencyUnit = (code: string) => {
    switch (code) {
        case 'USD':
            return '$';
        case 'EUR':
            return '€';
        default:
            return '£';
    }
}
