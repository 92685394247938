import * as React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Input from 'new-app/components/input';
import Button from 'new-app/components/button';
import { SearchIcon } from 'assets/icons';
import StyledAccordion from 'new-app/components/accordion';
import InputBase from '@mui/material/InputBase';
import { CloseIcon1 } from 'assets/icons';

const RootContainer = styled('div')`
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
`;

const SelectLabel = styled('label')({
  fontSize: '11px',
  color: '#191C25',
  cursor: 'pointer',
  fontWeight: 500,
  fontFamily: 'Inter',
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 7,
    position: 'relative',
    border: '1px solid #D9D9D9',
    background: 'transparent !important',
    fontSize: '0.65rem', color: '#7b809a',
    padding: '10px 3px 10px 3px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontWeight: 700, fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
    ,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export interface SelectItems {
  title: string | number;
  value: string | number;
  filter?: number;
  parentId?: string;
  header?: string;
}

interface Props {
  items: SelectItems[];
  label?: string;
  accordion?: boolean;
  value: any;
  width?: number;
  opacity?: number;
  multiple?: boolean;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export default function MultipleSelectCheckmarks(props: Props) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value])

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '520px',
        minWidth: '320px',
        overflow: 'auto',
        width: 'auto',
        padding: 0,
      },
    },
  };

  const [expanded, setExpanded] = React.useState<string | false>('panel0');

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.stopPropagation();
      setExpanded(newExpanded ? panel : false);
    };

  const handleChange = (event: SelectChangeEvent<typeof props.value>) => {
    event.stopPropagation();
  };

  let multiItems: SelectItems[] = [];
  if (props.multiple) {
    multiItems = props.items.filter(i => !!i.header)
  }

  const onSubmit = () => {
    props.onChange(value);
    onClose();
  }
  
  const onClose = () => {
    setOpen(false)
    setSearchTerm('')
  }

  const onSelectItem = (event: any, item: SelectItems) => {
    event.stopPropagation();

    if (item.value === 'all') {
      const allItems = props.items.map(item => item.value);
      if (value.includes('all') && item.value === 'all') {
        return setValue([]);
      }
      return setValue(allItems.concat(['all']))
    }
    
    let subItems: (string | number)[] = [];
    let newValues = value.filter(i => i !== 'all');
    if (item.header) {
      // select all sub childs
      subItems = props.items.filter(i => i.parentId === item.header).map(i => i.value);
      if (value.includes(item.header)) {
        // remove continent items
        const newItems = [...newValues].filter(i => i !== item.header && !subItems.includes(i))
        subItems = [];
        return setValue([...newItems]);
      }
    }
    if (value.includes(item.value)) {
      newValues = newValues.filter(i => i !== item.value && i !== item.parentId);
      return setValue([...newValues].concat(subItems));
    }
    const allSubItems = props.items.filter(i => i.parentId === item.parentId).map(i => i.value).filter(i => !!i);
    newValues = [...newValues, item.value];
    if (allSubItems.every( i => newValues.includes(i))) {
      const parentItem = props.items.find(i => i.header === item.parentId);
      if (parentItem) {
        newValues = newValues.concat(parentItem.value)
      }
    }
    setValue(newValues.concat(subItems));
  }

  const renderMenuItem = (item: SelectItems, index: number) => {
    return (
      <MenuItem key={index} value={item.value} className="!p-0 !w-full !pr-4 !rounded-none" onClick={(event) => onSelectItem(event, item)}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: '1rem' }}>
            {props.multiple &&
              <Checkbox
                checked={value.includes(item.value)}
                
                size='small'
                classes={{ root: '!rounded-s' }}
              />}
            <p className='font-inter text-cool-700 text-[14px] font-normal' style={{ marginTop: props.multiple ? '3px' : 0 }}>{item.title}</p>
          </div>
          {item.filter && <p className='font-inter text-cool-700 text-[14px] font-normal'>{item.filter}</p>}
        </div>
      </MenuItem>
    )
  }

  const isDisabled = value === props.value ? true : value.length === 0;

  return (
    <div>
      <FormControl sx={{ m: 0, width: '100%', height: '100%', padding: 0 }} disabled={props.disabled}>
        <Select
          multiple={props.multiple}
          value={value || ""}
          open={open}
          onChange={handleChange}
          input={<BootstrapInput />}
          renderValue={(selected) => ''}
          MenuProps={MenuProps}
          onClose={() => setOpen(false)}
          classes={{ select: "!h-[34px] !border-1 !border-red !rounded-lg", icon: '!hidden' }}
        >
          <div className='p-2 pb-0 realtive'>
            <Input
              placeholder='Search'
              className='!text-cool-900'
              icon={<SearchIcon />}
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            {searchTerm ? (
              <span className='absolute cursor-pointer right-4 top-5' onClick={() => setSearchTerm('')}>
                <CloseIcon1 />
              </span>
            ) : null}
          </div>
          <div className='max-h-[300px] h-[300px] overflow-auto'>
            {searchTerm ? 
              <div className='border-t flex flex-col border-grey-200 mt-4 pl-4 py-2'>
                {props.items.filter(i => ((i.value || "").toString()).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
                  .map((item, index) => {
                    return renderMenuItem(item, index);
                  })}
              </div> : (
              <div>
                <div className='border-b border-grey-200 px-2 py-1'>
                  {renderMenuItem({
                    value: 'all',
                    title: 'Select All'
                  }, 0)}
                </div>
                {props.multiple && props.accordion ? multiItems.map((item, rowIndex) => {
                  const subItems = props.items.filter(i => item.header === i.parentId);
                  if (subItems.length === 0) return;
                  return (
                    <StyledAccordion.Accordion expanded={expanded === `panel${rowIndex}`} onChange={handleAccordionChange(`panel${rowIndex}`)}>
                      <StyledAccordion.AccordionSummary>
                        {renderMenuItem(item, rowIndex)}
                      </StyledAccordion.AccordionSummary>
                      <StyledAccordion.AccordionDetails>
                        {subItems.map((item, index) => {
                          return renderMenuItem(item, index)
                        })}
                      </StyledAccordion.AccordionDetails>
                    </StyledAccordion.Accordion>
                  )
                }) : props.items.map((item: any, index: number) => {
                  if (item.header) {
                    return (
                      <ListSubheader sx={{ lineHeight: '30px' }} key={index}>{item.header}</ListSubheader>
                    )
                  }
                  if (!item.value) return;
                  return renderMenuItem(item, index)
                })}
              </div>
            )}
          </div>
          <div className='flex flex-row justify-end gap-4 border-t border-grey-200 h-[64px] bg-grey-50 items-center pr-3'>
            <Button
              className='!h-8 !bg-grey-200 !text-[12px] !text-grey-600 !font-semibold !font-inter'
              label='Cancel'
              onClick={onClose}
            />
            <Button
              disabled={isDisabled}
              className={`!h-8 ${isDisabled ? '!bg-grey-100' : ''} !text-[12px] !font-semibold !font-inter`}
              label='Select'
              onClick={onSubmit}
            />
          </div>
        </Select>

        <RootContainer onClick={() => !props.disabled && setOpen(true)} style={{ opacity: props.disabled ? 0.3 : props.opacity || 1 }}>
          {props.value.length > 0 ? <p className='text-cool-900 font-inter text-[12px] leading-4 font-semibold'>{`${props.value.length} ${props.value.length === 1 ? 'Location' : 'Locations'}`}</p> : null}
          {props.label && props.value.length === 0 ? (<SelectLabel>{props.label}</SelectLabel>) : null}
          {open ? <span className="material-icons text-[#707A89]">expand_less</span> :
            <span className="material-icons text-[#707A89]">expand_more</span>}
        </RootContainer>
      </FormControl>
    </div>
  );
} 