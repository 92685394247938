import { Assessment } from 'new-app/models/assessement';
import EngineerApiInvoker from '../../utils/engineerAPIInvoker';
import SphereApiInvoker from '../../utils/sphereApiInvoker';
import { fetch } from 'new-app/helpers/cachedAxios';

export async function getTechnicalTests(): Promise<Assessment[]> {
  return await new SphereApiInvoker().Fetch('/technical-tests/');
}

export async function getTechnicalTest(testId: string): Promise<Assessment> {
  return await new SphereApiInvoker().Fetch(`/technical-tests/${testId}`);
}

export async function createTechnicalTests(data: Assessment) : Promise<Assessment> {
    return await new SphereApiInvoker().Post(`/technical-tests/`, data);
}

export async function updateTechnicalTests(testId: number, data: Assessment) : Promise<Assessment> {
    return await new SphereApiInvoker().Put(`/technical-tests/${testId}`, data);
  }

export async function deleteTechnicalTest(testId: number) : Promise<any> {
    return await new SphereApiInvoker().Delete(`/technical-tests/${testId}`);
}
