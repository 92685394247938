import React from 'react';

export const MinusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="3" fill="#3168F4"/>
            <rect x="2.66663" y="6.6665" width="10.6667" height="2.66667" rx="1.33333" fill="white"/>
        </svg>
    )
}
