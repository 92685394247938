// @mui material components
import { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Project from './ProjectDetail';

import axios from 'utils/axios';
import { useBackdrop } from 'context/backdrop';

function ProjectDetail({ formData }: any): JSX.Element {
  const { fetching } = useBackdrop();

  const [projects, setProjects] = useState<
    Array<{ _id: string; name: string }>
  >([]);
  const [clients, setClients] = useState<Array<string>>([]);

  async function getProjects() {
    const { data } = await axios.post('/project/all', {
      fields: ['_id', 'name'],
    });
    setProjects(data);
  }

  async function getClients() {
    const { data } = await axios.get('/clients/all');
    setClients(data.map((client: any) => client.name));
  }

  useEffect(() => {
    fetching(true);
    Promise.all([getProjects(), getClients()])
      .catch((error) => console.error(error))
      .then(() => fetching(false));
  }, []);
  return (
    <MDBox>
      <MDTypography p={3} variant="h5" fontWeight="bold">
        Projects
      </MDTypography>
      <MDBox mt={1.625}>
        <Project formData={formData} projects={projects} clients={clients} />
      </MDBox>
    </MDBox>
  );
}

export default ProjectDetail;
