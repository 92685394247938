import React from 'react';
import classNames from 'classnames';

export interface LabelProps {
    label: string;
}

export default function (props: LabelProps) {
    return (
        <label className={'text-[#555E6D] font-inter font-normal text-[14px] leading-5 mb-2'}>{props.label}</label>
    );
}
