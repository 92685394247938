export interface ChipProps {
  text: string;
  className?: string;
  icon?: string;
  isFilter?: boolean;
  onClick?: () => void;
}

export default function (props: ChipProps) {
  const { isFilter=true } = props;
  return (
    <div
      className={`${props.className} flex rounded-full justify-center px-4 py-2 text-xs items-center cursor-pointer border border-border-primary`}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {props.icon && (
        <span className="material-icons flex shrink text-[1.25rem] mr-2">{props.icon}</span>
      )}
      <span className="flex">{props.text}</span>
      {props.onClick && isFilter && (
        <span className="material-icons flex shrink text-xs ml-2">close</span>
      )}
    </div>
  );
}
