import { AxiosInstance, default as http } from 'axios';
import { requestInterceptorErrors, requestInterceptors, responseInterceptorErrors, responseInterceptors } from './axios';

export default class SphereApiInvoker {
  http: AxiosInstance;

  constructor() {
    this.http = http.create({
      baseURL: process.env.REACT_APP_SPHERE_API_BASE_URL,
      headers: {
        "content-type": "application/json",
      },
    });
    this.http.interceptors.request.use(
      requestInterceptors,
      requestInterceptorErrors
    );
    this.http.interceptors.response.use(
      responseInterceptors,
      responseInterceptorErrors
    );
  }
  
  public async Fetch(path: string, queryString?: any) {
    const params: { [key: string]: string } = {};
    if (queryString)
    {
      Object.entries(queryString).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          params[key] = `[${value.join(',')}]`;
        } else if (value instanceof Date) {
          params[key] = value.toISOString();
        } else {
          params[key] = value.toString();
        }
      });
    }

    path = path.startsWith('/') ? path : `/${path}`;
    const result = await this.http.get(path, { params: params });
    return result.data;
  }

  public async Post(path: string, payload: any) {
    const result = await this.http.post(path, payload);
    return result.data;
  }
  
  public async Put(path: string, payload: any) {
    const result = await this.http.put(path, payload);
    return result.data;
  }

  public async Delete(path: string) {
    const result = await this.http.delete(path);
    return result.data;
  }
}