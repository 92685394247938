import React, { useContext, useState, useEffect, useRef } from 'react';
import { RadarContents, OptionButton, OptionIcon } from './Radar.style';
import PropTypes from 'prop-types';
import { INITIAL_VALUE, ReactSVGPanZoom, TOOL_PAN } from 'react-svg-pan-zoom';

import Quadrant from './Quadrant/Quadrant';
import { getColorScale, ThemeContext } from './theme-context';

//when point coordinates are calculated randomly, sometimes point coordinates
// get so close that it would be hard to read the textual part. When such
//collisions occur, the position generator retries. This constant defines the
//number of trials where it has to stop.
const MAX_COLLISION_RETRY_COUNT = 350;

//This value is used to determine whether a collision retry should be triggered or not.
const TOLERANCE_CONSTANT = 6;

//default radar width
const DEFAULT_WIDTH = 700;

//radius of rings diminish as they move away from the center
const RADIUS_DIMINISH_CONSTANT = 1.5;

//extend width to right so that overflow text would be visible
const RIGHT_EXTENSION = 1.1;

function Radar(props) {
  //manage optional variables
  const [points, setPoints] = useState([])
  const Viewer = useRef(null);
  const [tool, setTool] = useState(TOOL_PAN)
  const [value, setValue] = useState(INITIAL_VALUE)
  const [zoomLevel, setZoomLevel] = useState(1);

  const width = props.width || DEFAULT_WIDTH;
  const rings = props.rings || [''];
  const radiusDiminishConstant =
    props.radiusDiminish || RADIUS_DIMINISH_CONSTANT;
  const data = props.data || [];
  if (data.length === 0) {
    console.log('No Data Provided');
  }

  useEffect(() => {
    Viewer.current.fitToViewer();
  }, []);

  //context variables
  const { fontSize, fontFamily, colorScale } = useContext(ThemeContext);

  //margin of radar
  const margin = props.margin || 0;

  //some internally used constants
  const angle = 360 / props.quadrants.length;

  //collision detection constants
  const toleranceX = (width / rings.length / 100) * TOLERANCE_CONSTANT * 4;
  const toleranceY = props.fontSize || fontSize;

  //console.log("Collision Tolerance (Pixels):");
  //console.log("x: " + toleranceX);
  //console.log("y: " + toleranceY);

  //given the ring and quadrant of a value,
  //calculates x and y coordinates
  const processRadarData = (quadrants, rings, data) => {
    //order by rings. this will result in better collision
    //detection performance since it is harder to relocate
    // the points in the core ring
    data.sort(function (a, b) {
      return rings.indexOf(a.ring) - rings.indexOf(b.ring);
    });

    let collisionCount = 0;

    // go through the data
    const results = [];

    for (const i in data) {
      const entry = data[i];

      let quadrant_delta = 0;

      // figure out which quadrant this is
      const angle = (2 * Math.PI) / props.quadrants.length;
      for (let j = 0, len = quadrants.length; j < len; j++) {
        if (quadrants[j] === entry.quadrant) {
          quadrant_delta = angle * j;
        }
      }
      const coordinates = getRandomCoordinates(
        rings,
        entry,
        angle,
        quadrant_delta,
        results,
        collisionCount
      );
      if (collisionCount < MAX_COLLISION_RETRY_COUNT) {
        collisionCount = coordinates.collisionCount;
      }

      const blip = {
        id: i,
        name: entry.name,
        quadrant: entry.quadrant,
        core: entry.core,
        x: coordinates.x,
        y: coordinates.y,
      };

      results.push(blip);
    }

    return results;
  };

  //used by processRadarData.
  //generates random coordinates within given range
  const getRandomCoordinates = (
    rings,
    entry,
    angle,
    quadrant_delta,
    results,
    collisionCount = 0
  ) => {
    const polarToCartesian = (r, t) => {
      const x = r * Math.cos(t);
      const y = r * Math.sin(t);
      return { x: x, y: y };
    };

    const getPositionByQuadrant = (radiusArray) => {
      const ringCount = rings.length;
      const margin = 0.2;
      const ringIndex = rings.indexOf(entry.ring);
      const posStart = radiusArray[ringIndex] + (1 / ringCount) * margin;
      const posLength =
        Math.random() *
        (radiusArray[ringIndex + 1] -
          radiusArray[ringIndex] -
          2 * ((1 / ringCount) * margin));
      return posStart + posLength;
    };

    const calculateRadiusDiminish = (nrOfRings) => {
      let max = 1;

      //create the array. each value represents
      //the share of total radius among rings.
      let arr = [1];
      for (let i = 1; i < nrOfRings; i++) {
        max = max * radiusDiminishConstant;
        arr.push(max);
      }

      //calculate total shares of radius
      const sum = arr.reduce((a, b) => a + b);
      arr = arr.map((a) => a / sum);

      //now, each member of the array represent
      //the starting position of ring in the
      //circle
      arr.reverse();
      for (let i = 1; i < nrOfRings; i++) {
        arr[i] = arr[i - 1] + arr[i];
      }

      //add 0 for the center of the circle
      arr.push(0);

      //sort the array so that 0 is at the start
      arr.sort();

      return arr;
    };

    const hasCollision = (results, coordinates) => {
      if (collisionCount >= MAX_COLLISION_RETRY_COUNT) {
        return false;
      }

      for (const result of results) {
        if (
          Math.abs(result.x - coordinates.x) <= toleranceX &&
          Math.abs(result.y - coordinates.y) <= toleranceY
        ) {
          if (++collisionCount >= MAX_COLLISION_RETRY_COUNT) {
            console.log('max collision retry limit reached: ' + collisionCount);
          }
          return true;
        }
      }
      return false;
    };

    const radiusArray = calculateRadiusDiminish(props.rings.length);

    const randomPosition = getPositionByQuadrant(radiusArray);
    const positionAngle = Math.random();
    const ringWidth = width / 2;

    //theta is the position in the quadrant
    const theta = positionAngle * angle + quadrant_delta;
    const r = randomPosition * ringWidth;

    const data = polarToCartesian(r, theta);

    //recalculate if there is a collision
    const collision = hasCollision(results, data);
    if (collision) {
      return getRandomCoordinates(
        rings,
        entry,
        angle,
        quadrant_delta,
        results,
        collisionCount
      );
    }

    //report number of collisions detected
    data.collisionCount = collisionCount;
    return data;
  };

  useEffect(() => {
    const points = processRadarData(props.quadrants, rings, data);
    setPoints(points);
  }, [data])

  return (
    //theme context variables can be overridden by props
    <ThemeContext.Provider
      value={{
        fontSize: props.fontSize || fontSize,
        itemFontSize: props.itemFontSize || props.fontSize || fontSize,
        fontFamily: props.fontFamily || fontFamily,
        colorScale: props.colorScaleIndex
          ? getColorScale(props.colorScaleIndex)
          : colorScale,
        quadrantsConfig: props.quadrantsConfig || {},
      }}
    >
      <ReactSVGPanZoom
        ref={Viewer}
        width={width * RIGHT_EXTENSION}
        height={width * RIGHT_EXTENSION}
        tool={tool} onChangeTool={setTool}
        background='white'
        value={value} onChangeValue={setValue}
        defaultTool={'none'}
        onZoom={e => {
          setZoomLevel(e.a);
        }}
        miniatureProps={{
          position: 'none'
        }}
        customToolbar={
          () => {
            return (
              <div role="toolbar" style={{ position: 'absolute', transform: 'none', top: '5px', left: 'unset', right: '5px', bottom: 'unset', backgroundColor: 'rgba(19, 20, 22, 0.9)', borderRadius: '2px', display: 'flex', flexDirection: 'column', padding: '2px 1px' }}>
                <OptionButton title="Selection" name="unselect-tools" type="button" onClick={() => setTool('none')}>
                  <OptionIcon width="24" height="24" stroke="currentColor">
                    <path d="M10.07,14.27C10.57,14.03 11.16,14.25 11.4,14.75L13.7,19.74L15.5,18.89L13.19,13.91C12.95,13.41 13.17,12.81 13.67,12.58L13.95,12.5L16.25,12.05L8,5.12V15.9L9.82,14.43L10.07,14.27M13.64,21.97C13.14,22.21 12.54,22 12.31,21.5L10.13,16.76L7.62,18.78C7.45,18.92 7.24,19 7,19A1,1 0 0,1 6,18V3A1,1 0 0,1 7,2C7.24,2 7.47,2.09 7.64,2.23L7.65,2.22L19.14,11.86C19.57,12.22 19.62,12.85 19.27,13.27C19.12,13.45 18.91,13.57 18.7,13.61L15.54,14.23L17.74,18.96C18,19.46 17.76,20.05 17.26,20.28L13.64,21.97Z"></path></OptionIcon>
                </OptionButton>
                <OptionButton title="Pan" name="select-tool-pan" type="button" onClick={() => setTool('pan')}>
                  <OptionIcon width="24" height="24" stroke="white">
                    <path d="M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z"></path>
                    {/* <path d="M26 48q-5.45 0-9.4-2.8t-5.7-7.45L5.8 24.4q-.15-.4-.225-.825Q5.5 23.15 5.5 22.7q0-1.3 1-2.3 1-1 2.3-1 .3 0 .575.025.275.025.525.125l1.5.4q1 .25 1.575.675.575.425 1.275 1.525V8.25q0-1.75 1.25-3t3-1.25q.6 0 1.1.175.5.175.9.475v-.4q0-1.75 1.275-3T24.8 0q1.6 0 2.75 1.05t1.4 2.45q.2-.25.85-.375Q30.45 3 31 3q1.9 0 3.075 1.425Q35.25 5.85 35.25 7.6v1.95q.35-.3.9-.425T37.25 9q1.75 0 3 1.25t1.25 3V32.5q0 6.85-4.325 11.175Q32.85 48 26 48Zm0-3q5.6 0 9.05-3.475Q38.5 38.05 38.5 32.5V13.25q0-.55-.35-.9t-.9-.35q-.55 0-.9.35t-.35.9V24h-3.75V7.25q0-.55-.35-.9T31 6q-.55 0-.9.35t-.35.9V24H26V4.25q0-.55-.35-.9t-.9-.35q-.55 0-.9.35t-.35.9V24h-3.75V8.25q0-.55-.35-.9T18.5 7q-.55 0-.9.35t-.35.9V29.5h-2.9l-1.85-4.7q-.5-1.3-1.275-1.675-.775-.375-2.025-.675-.5-.1-.675.1-.175.2.025.65l5.2 13.5q1.45 3.75 4.55 6.025Q21.4 45 26 45Z"/> */}
                  </OptionIcon>
                </OptionButton>
                <OptionButton title="Zoom in" name="select-tool-zoom-in" onClick={() => setTool('zoom-in')} type="button"><OptionIcon width="24" height="24" stroke="currentColor"><g><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path><path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z"></path></g></OptionIcon></OptionButton>
                <OptionButton title="Zoom out" name="select-tool-zoom-out" onClick={() => setTool('zoom-out')} type="button"><OptionIcon width="24" height="24" stroke="currentColor"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"></path></OptionIcon></OptionButton>
                <OptionButton title="Fit to viewer" name="fit-to-viewer" onClick={() => {
                  setTool('none')
                  Viewer.current.fitToViewer();
                }} type="button"><OptionIcon width="24" height="24" stroke="currentColor"><path d="M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z"></path></OptionIcon>
                </OptionButton>
              </div>
            )
          }
        }
        detectAutoPan={false}
        detectPinchGesture={false}
        scaleFactorOnWheel={1.1}
        scaleFactorMin={1}
        scaleFactorMax={60}
        toolbarProps={{
          position: 'right',
          activeToolColor: '#FF007A'
        }}
      >
        <svg
          width={width * RIGHT_EXTENSION}
          height={width * RIGHT_EXTENSION}
          style={{
            margin: margin,
          }}
        >
          <g
            transform={
              'translate(' +
              (width / 2) * RIGHT_EXTENSION +
              ',' +
              (width / 2) * RIGHT_EXTENSION +
              ')'
            }
          >
            {props.quadrants.map((value, index) => {
              //get points that belong to this quadrant
              const filteredPoints = points.filter(
                (element) => element.quadrant === value
              );

              return (
                <g key={index}>
                  <Quadrant
                    transform={
                      ' rotate(' +
                      (360 / props.quadrants.length) * index +
                      ') translate(' +
                      margin +
                      ',' +
                      margin +
                      ')  '
                    }
                    isSelectionAllowed={tool === 'none'}
                    rotateDegrees={(360 / props.quadrants.length) * index}
                    width={width - 2 * margin}
                    index={index}
                    rings={rings}
                    points={filteredPoints}
                    zoomLevel={zoomLevel}
                    angle={angle}
                    name={value}
                    radiusDiminish={radiusDiminishConstant}
                  />
                </g>
              );
            })}
          </g>
        </svg>
      </ReactSVGPanZoom>
    </ThemeContext.Provider>
  );
}

Radar.propTypes = {
  quadrants: PropTypes.array.isRequired,
  rings: PropTypes.array,
  data: PropTypes.array,
  width: PropTypes.number,
  fontSize: PropTypes.number,
  itemFontSize: PropTypes.number,
  colorScaleIndex: PropTypes.number,
  radiusDiminish: PropTypes.number,
};

export default Radar;
