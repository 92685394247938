import { createContext, ReactNode } from 'react';
import routes from 'routes';
import { permissionRoute } from 'context/constants';
import { Navigate } from 'react-router-dom';
import { adminRole } from '../constants/index';
import links from '../pages/admin/links';

interface AuthProps {
  children: ReactNode;
  auth: { [key: string]: any };
}

interface IAuthContext {
  authUser: { [key: string]: any };
}

export const authContext = createContext<IAuthContext | null>(null);

const useProvideAuth = (authUser: { [key: string]: any }) => {
  return {
    authUser,
  };
};

export function authLogic(routeKey: string, cognitoGroups: string[]): boolean {
  const permissionKeys = permissionRoute[routeKey];
  if (routeKey === 'username' || routeKey === 'home') return true;
  if (!permissionKeys || permissionKeys.length === 0) return true;


  if (cognitoGroups) {
    if (cognitoGroups.includes(adminRole)) return true;

    for (const permissionKey of permissionKeys) {
      if (cognitoGroups.includes(permissionKey)) {
        return true;
      }
    }
  }

  return false;
}

export function AuthProvider({ children, auth }: AuthProps) {
  const path = getPath();

  const key = routes.find((s) => (s.route ? s.route == path : ''))?.key;
  const userSession = useProvideAuth(auth);

  if (
    !auth &&
    !path.includes('login') &&
    !path.includes('logout') &&
    !path.includes('signin')
  ) {
    return <Navigate to="/login" replace={true} />;
  }

  const isAllowed = auth && authLogic(key, auth['cognito:groups']);
  if (
    !isAllowed &&
    !path.includes('login') &&
    !path.includes('logout') &&
    !path.includes('signin')
  ) {
    const allowedLinks = links.filter((link) => {
      return auth && authLogic(link.key, auth['cognito:groups']) && !link.disabled && link.key !== 'logout';
    });
    return <Navigate to={allowedLinks?.length == 0 ? '/admin' : process.env.REACT_APP_TECH_RADAR_LINK  === 'true' ? "/tech_radar" : "/elastic_talent_board_plus"} replace={true} />;
  }
  return (
    <authContext.Provider value={{ ...userSession }}>
      {children}
    </authContext.Provider>
  );
}

const getPath = (): String => {
  const pathNames = window.location.pathname.split('/').slice(1);
  let returnUrl = '';
  if (pathNames.length > 0) {
    pathNames.forEach((val, index) => {
      if (!val.includes('-')) returnUrl += `/${val}`;
      else returnUrl += pathNames[0] == 'roles' ? '/:_id' : '/:id';
    });
    return returnUrl;
  }

  return window.location.pathname;
};
