// @ts-nocheck
import { Auth } from 'aws-amplify';
import { setLogin } from 'context/analytics';
import {
  useState,
  useEffect,
  JSXElementConstructor,
  Key,
  ReactElement,
} from 'react';

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import Zendesk from "react-zendesk";

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import BackdropProvider from 'context/backdrop';
import CssBaseline from '@mui/material/CssBaseline';
// import Icon from "@mui/material/Icon";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Material Dashboard 2 PRO React TS exampless
import Sidenav from 'examples/Sidenav';
import Configurator from 'examples/Configurator';

// Material Dashboard 2 PRO React TS themes
import theme from 'assets/theme';

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from 'assets/theme-dark';

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { AuthProvider } from 'context/auth';
// RTL plugins

// Material Dashboard 2 PRO React TS routes
import routes from 'routes';

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav } from 'context';

// Images
import brandImage from 'assets/images/custom/Distributed_Command.png';

import useExternalScripts from 'new-app/hooks/useExternalScripts';

Auth.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNOUT,
      scope: ['email', 'openid'],
      responseType: 'code',
      federationTarget: 'COGNITO_USER_POOLS',
    },
  },
});


const zenDeskSetting = {
  launcher: {
    chatLabel: {
      "*": "Feedback"
    },
    label: {
      "*": "Feedback"
    }
  },
};

const getJwtSession = async () => {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch (err) {}
};

function App() {
  const [userSession, setUserSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
      zIndex: 10000,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  // SatisMeter init
  useExternalScripts({
    url: 'https://app.satismeter.com/satismeter.js'
  });

  useEffect(async () => {
    const jwtSession = await getJwtSession();
    const payload = jwtSession?.getIdToken()?.payload ?? null;
    if (payload != null && window.satismeter) {
      const { email, sub } = payload
      window.satismeter({
        writeKey: process.env.REACT_APP_SATISMETER_KEY,
        userId: sub,
        traits: {
          name: email,
          email: email,
          createdAt: new Date(),
        }
      });
    }
  }, [window.satismeter])

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const jwtSession = await getJwtSession();
      const payload = jwtSession?.getIdToken()?.payload ?? null;
      setUserSession(payload);
      if (payload != null) {
        const { email, sub } = payload
        const groups = payload["cognito:groups"]
        setLogin(email, sub, groups);
      }
      setIsLoading(false);
    })();
  }, []);

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        disabled?: boolean;
      }) => {
        if (route.disabled) {
          return null
        }
        
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return (
            <Route
              path={route.route}
              element={route.component}
              key={route.key}
            />
          );
        }

        return null;
      }
    );

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <BackdropProvider>
        <AuthProvider auth={userSession}>
          <CssBaseline />
          {layout === 'dashboard' && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brandImage}
                brandName="Distributed Command"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === 'vr' && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to={process.env.REACT_APP_TECH_RADAR_LINK  === 'true' ? "/tech_radar" : "/elastic_talent_board_plus"} />} />
          </Routes>
          <ToastContainer toastStyle={{ fontSize: 13, fontFamily: 'Roboto' }} />
        </AuthProvider>
      </BackdropProvider>
      <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY} {...zenDeskSetting}/>
    </ThemeProvider>
  );
}

export default App;
