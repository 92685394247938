import { CandidateSearchRequest } from 'new-app/models/candidateSearchRequest';
import { CandidateSearchResult } from 'new-app/models/candidateSearchResult';
import { FullUserProfileModel, Shortlist, CurrencyResponse } from 'new-app/models/profile';
import SphereApiInvoker from '../../utils/sphereApiInvoker';

export async function searchCandidates(searchCriteria: CandidateSearchRequest): Promise<CandidateSearchResult> {
  return await new SphereApiInvoker().Fetch('/members/cv-search', searchCriteria);
}

export async function GetFullProfile(userId: string) : Promise<FullUserProfileModel> {
  return await new SphereApiInvoker().Fetch(`/members/${userId}/full-profile`);
}

export async function updateProfile(userId: string, data: any) : Promise<FullUserProfileModel> {
  return await new SphereApiInvoker().Put(`/members/${userId}/profile`, data);
}

export async function getCVSignedUrl(userId: string) : Promise<string> {
  return await new SphereApiInvoker().Fetch(`/members/${userId}/cv`);
}

export async function getCVDownloadUrl(userId: string) : Promise<string> {
  return await new SphereApiInvoker().Fetch(`/members/${userId}/cv/download`);
}

export async function getShortlists() : Promise<Shortlist[]> {
  return await new SphereApiInvoker().Fetch(`/talent-shortlists/`);
}

export async function deleteShortlist(shortlistId: string) : Promise<any> {
  return await new SphereApiInvoker().Delete(`/talent-shortlists/${shortlistId}`);
}

export async function updateUsersFromShortlist(shortlistId: string, userId: string) : Promise<any> {
  return await new SphereApiInvoker().Delete(`/talent-shortlists/${shortlistId}/shortlist-user/${userId}`);
}

export async function addUsersToShortlist(shortlistId: string, userIds: string[]) : Promise<Shortlist> {
  return await new SphereApiInvoker().Post(`/talent-shortlists/${shortlistId}/shortlist-users`, {"UserIds": userIds});
}

export async function updateShortlist(shortlistId: string, Name: string) : Promise<Shortlist> {
  return await new SphereApiInvoker().Put(`/talent-shortlists/${shortlistId}`, { Name });
}

export async function createShortlist(Name: string) : Promise<Shortlist> {
  return await new SphereApiInvoker().Post(`/talent-shortlists/`, { Name });
}

export async function getCurrencies() : Promise<CurrencyResponse[]> {
  return await new SphereApiInvoker().Fetch(`/config/currencies`);
}

export async function updateCurrency(currencyId: number, conversionRate: number) : Promise<CurrencyResponse> {
  return await new SphereApiInvoker().Put(`/config/currency/${currencyId}`, { conversionRate });
}