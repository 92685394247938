import { fetch } from 'new-app/helpers/cachedAxios';
import { Role } from 'new-app/models/role';


export function fetchRoles(): Promise<Role[]> {
  return fetch('/role/active_options', 'post');
}

export const fetchUserRoles = async () => {
  const options = { limit: 1000, page: 0 };
  const result = await fetch('/engineer/roles', 'GET', {
    params: { ...options },
  });
  
  return result;
}