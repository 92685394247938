import { useContext } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Cell, CartesianGrid } from "recharts";
import { RadarDataContext } from "./radar-data-context";
import { SupplyButton, DemandButton } from "./buttons";
import Styled from './styles'

interface skillsProp {
  name: string;
  uv: number;
}


const SupplyDemandBarChart = () => {
  const { quad, selectedSkills } = useContext(RadarDataContext);

  function CustomTooltip({ payload, label, active }: any) {
    if (active && payload) {
      return (
        <div className="bg-white py-2 px-4 shadow-lg shadow-[#AFAFAF]/50">
          <p className="text-[16px] font-medium">{`Skill Name : ${label}`}</p>
          <p className="text-[16px] font-medium">{`${payload[0].value >= 0 ? 'Supply' : 'Demand'} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  }
    
  const renderCustomAxisTick = ({ x, y, payload, ...props }: any) => {
    return (
      <text x={x} y={y + 15} fill={payload.value >= 0 ? '#0071BC' : '#FF007A'} fontSize={14} fontFamily={'Poppins-Regular'} textAnchor="middle">
        {Math.abs(payload.value)}
      </text>
    );
  };
  const renderCustomYAxisTick = ({ x, y, payload, ...props }: any) => {
    const getLabel = (text: string) => {
      const threshold = 20;
      if (text && text.length <= threshold) {
        return text
      }
      return text ? text.substring(0, threshold).concat("...") : '';
    }
    return (
      <text x={x} y={y + 5} fill={'#0071B8'} fontSize={14} fontFamily={'Poppins'} textAnchor="start" dx={-120}>
        {getLabel(payload.value)}
      </text>
    );
  };

  return (
    <>
      <div className='poppins text-[#808080] text-[14px] w-full mb-[10px]'>
        <strong>The bar chart</strong> shows the total current supply and demand
      </div>

      <Styled.LanguageWrapper>
        <Styled.LanguageTitleWrapper>{quad}</Styled.LanguageTitleWrapper>
        <BarChart
          width={490}
          height={570}
          data={selectedSkills}
          margin={{
            top: 5,
            right: 30,
            left: 50,
            bottom: 5,
          }}
          layout="vertical"
        >
          <XAxis
            type="number"
            tickCount={5}
            domain={[-20, 20]}
            tick={renderCustomAxisTick}
          />
          <YAxis
            type="category"
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={renderCustomYAxisTick}
            width={110}
          />
          <Tooltip content={<CustomTooltip />} cursor={{fill: 'white'}} wrapperStyle={{ outline: "none" }}/>
          {/* <Legend /> */}
          <ReferenceLine x={0} stroke="#AFAFAF" />
          <Bar dataKey="uv" radius={5} barSize={20} >
            {selectedSkills.map((datum: skillsProp, index: number) => (
              <Cell key={`cell-${index}`} fill={datum.uv > 0 ? "#0071b8" : "#FF007A"} />
            ))}
          </Bar>
        </BarChart>
        <div className="flex mt-[-10px] justify-center gap-x-4 pl-[130px] pr-8">
          <DemandButton />
          <SupplyButton />
        </div>
      </Styled.LanguageWrapper>
    </>
  );
};

export default SupplyDemandBarChart;
