import React from 'react';

export type ActionMenuProps = {
  children: React.ReactNode;
  visible?: boolean;
};

export default function (props: ActionMenuProps) {
  if (!props.visible) {
    return null;
  }
  return (
    <div className="block relative">
      <div className="block absolute z-50 bg-white shadow-xl rounded-lg border border-border-primary text-black w-52 right-10">
        {props.children}
      </div>
    </div>
  );
}
