import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ArrowDownIcon, ArrowUpIcon } from 'assets/icons';
import Checkbox from './checkbox';
import TableLoadingSkeleton from './TableLoadingSkeleton';
interface TableHeader {
  title: string;
  leftAlign?: boolean;
}

export interface TableProps {
  /**
   * headings
   */
  headings: Array<TableHeader>;
  /**
   * a array of map where the key is heading and
   * value is the element to display in the table
   * data cell
   */
  data: Map<string, JSX.Element>[];
  /**
   * index of selected elements
   */
  selected?: Set<number>;
  onHoverChangeColor?: boolean;
  /**
   * when a user selects an entry this is called
   */
  isLoading?: boolean;
  parentId?: string;
  toggleSelection?: (index: number) => void;
  onMouseEnter?: (index: number) => void;
  onMouseLeave?: (index: number) => void;
  fetchMoreData?: () => void;
  onEntryClick?: (index: number) => void;
  onSortClick?: (sortedColumns: Map<string, number>) => void;
  className?: string;
  headerClasses?: string;
}

function Table(props: TableProps) {
  const [hovered, setHover] = useState(-1);
  const [sortedColumns, setSortedColumns] = useState<Map<string, number>>(
    new Map<string, number>()
  );

  const sortColumn = (column: string) => {
    const updatedSortedColumn = new Map<string, number>();
    if (!sortedColumns.has(column)) {
      updatedSortedColumn.set(column, -1);
    } else if (sortedColumns.get(column) === -1) {
      updatedSortedColumn.set(column, 1);
    } else {
      updatedSortedColumn.delete(column);
    }
    setSortedColumns(updatedSortedColumn);

    props.onSortClick(updatedSortedColumn);
  }

  const renderHeading = useMemo(() => {
    const headings = props.headings.map((heading, index) => {
      let renderSortArrow = null;
      if (sortedColumns.has(heading.title)) {
        if (sortedColumns.get(heading.title) === 1) {
          renderSortArrow = (
            <ArrowDownIcon />
          );
        } else {
          renderSortArrow = (
            <ArrowUpIcon />
          );
        }
      }
      return (
        <th
          key={index}
          className={`${classnames({
            'cursor-pointer': props.onSortClick,
          })} min-w-100 text-center py-4 px-2 capitalize text-[12px] font-inter text-[#6C6F79]`}
          onClick={() => {
            if (props.onSortClick) {
              sortColumn(heading.title);
            }
          }}
        >
          {props.onSortClick && !renderSortArrow ? (
            <span
              className={`${classnames({
                'justify-start': heading.leftAlign,
                'justify-center': !heading.leftAlign,
              })} ${props.headerClasses || ""} flex flex-row items-center gap-x-4 text-[#6C6F79]`}
            >
              <p className='whitespace-nowrap'>{heading.title}</p>
            </span>
          ) : (
            <span
              className={`${classnames({
                'justify-start': heading.leftAlign,
                'justify-center': !heading.leftAlign,
              })} ${props.headerClasses || ""} flex flex-row items-center gap-x-4 text-[#191C25]`}
            >
              <p className='whitespace-nowrap'>{heading.title}</p>
              {props.onSortClick && renderSortArrow}
            </span>
          )}
        </th>
      );
    });
    return (
      <thead className="bg-off-white bg-bg-light-gray sticky top-0 z-50">
        <tr>
          {props.toggleSelection && (
            <th key={-1} className="">
              &nbsp;
            </th>
          )}
          {headings}
        </tr>
      </thead>
    );
  }, [sortedColumns]);

  const renderBody = useMemo(() => props.data.map((entry, index) => renderDataElement(entry, index, null)), [JSON.stringify(props.data), props.selected, sortedColumns])

  function renderDataElement(entry: Map<string, JSX.Element>, index: number, style: any) {
    const dataEntry = props.headings.map((heading, headingIndex) => {
      const data = entry.get(heading.title);
      return (
        <td
          key={headingIndex}
          className="py-4 px-2 text-center text-[12px] font-inter border-0 border-b-2 border-b-border-primary text-black"
        >
          {data}
        </td>
      );
    });

    return (
      <tr
        style={style}
        key={index}
        onMouseEnter={() => {
          if (props.onMouseEnter) {
            props.onMouseEnter(index);
          }
          setHover(index);
        }}
        onMouseLeave={() => {
          if (props.onMouseLeave) {
            props.onMouseLeave(index);
          }
          setHover(-1);
        }}
        onClick={() => {
          if (props.onEntryClick) {
            props.onEntryClick(index);
          }
        }}
        className={classnames({
          'hover:bg-light-gray hover:cursor-pointer': props.onHoverChangeColor,
        })}
      >
        {props.toggleSelection && (
          <td
            key={-1}
            className="cursor-pointer text-center pl-4 border-0 border-b-2 border-b-border-primary"
            onClick={(event: React.MouseEvent<HTMLTableDataCellElement>) => {
              event.stopPropagation();
              props.toggleSelection(index);
            }}
          >
            <Checkbox checked={props.selected && props.selected.has(index)} />
          </td>
        )}
        {dataEntry}
      </tr>
    );
  }

  return (
    <InfiniteScroll
      dataLength={props.data.length}
      next={props.fetchMoreData && props.fetchMoreData}
      style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
      hasMore={true}
      loader={null}
      scrollableTarget={props.parentId}
    >
      {props.isLoading ? <TableLoadingSkeleton /> : (
        <table className="table-fixed">
          {renderHeading}
          <tbody>
            {renderBody}
          </tbody>
        </table>
      )}
      {(!props.isLoading && (!props.data || props.data.length === 0)) &&
        <div className='flex justify-center items-center h-full min-h-[300px]'>
          <p className="text-label-black font-medium w-[100%] text-center font-inter text-[13px]">No data found!</p>
        </div>}
    </InfiniteScroll>
  );
}

export default React.memo(Table);
