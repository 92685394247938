/**
 * This helper script caches the data for each
 * request in local storage
 * WHY?
 * This is done because there is no state
 * management and we don't need to make a request
 * frequently; This is used for data that won't
 * be changing in real time.
 */
import axios from '../../utils/axios';

export interface CachedItem {
  created: number;
  ttl: number;
  data: any;
}

export async function clear() {
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key.startsWith('AXIOS: ')) {
      window.localStorage.removeItem(key);
    }
  }
}

export async function expire() {
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (key.startsWith('AXIOS: ')) {
      const data: CachedItem = JSON.parse(window.localStorage.getItem(key));
      if (data.created + data.ttl * 1000 < Date.now()) {
        window.localStorage.removeItem(key);
      }
    }
  }
}

export async function fetchNoCache(url: string, method: string, data?: any) {
  let result;
  if (method.toUpperCase() === 'GET') {
    result = await axios.get(url, data);
  }
  else {
    result = await axios({
      url,
      method,
      data,
    });
  }

  if (result.status < 200 || result.status >= 300) {
    throw new Error(
      `got a ${result.status} response:\n${result.statusText}\n${result.data}`
    );
  }
  return result.data;
}

export async function refresh(
  url: string,
  method: string,
  data?: any,
  ttl = 3
) {
  const cacheKey = `AXIOS: [${method.toUpperCase()}] ${url} ${JSON.stringify(
    data
  )}`;
  const result = await fetchNoCache(url, method, data);
  window.localStorage.setItem(
    cacheKey,
    JSON.stringify({
      created: Date.now(),
      ttl,
      data: result,
    } as CachedItem)
  );
  return result;
}

export async function fetch(url: string, method: string, data?: any, ttl = 3) {
  const cacheKey = `AXIOS: [${method.toUpperCase()}] ${url}`;
  const cached: CachedItem = JSON.parse(window.localStorage.getItem(cacheKey));
  if (cached !== null) {
    if (cached.ttl * 1000 + cached.created > Date.now()) {
      return cached.data;
    } else {
      refresh(url, method, data, ttl);
      return cached.data;
    }
  }

  return refresh(url, method, data, ttl);
}

// auto clear expired cache
setInterval(expire, 1000);
