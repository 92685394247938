import React from 'react';

export const LogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="126" height="23" viewBox="0 0 126 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1028_35757)">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.4172 1.66886C2.16751 3.66361 0 7.24995 0 11.3425C0 15.4351 2.16751 19.0214 5.4172 21.0162V1.66886ZM8.29515 22.2711C9.26484 22.5409 10.2868 22.685 11.3425 22.685C17.6068 22.685 22.685 17.6068 22.685 11.3425C22.685 5.07823 17.6068 0 11.3425 0C10.2868 0 9.26484 0.144224 8.29515 0.414044V22.2711Z" fill="#FF007A" />
                <path d="M32.551 5.98828C33.8376 5.98828 34.9619 6.24532 35.9239 6.7594C36.8859 7.27348 37.6278 7.99787 38.1493 8.93256C38.6825 9.85557 38.949 10.9246 38.949 12.1397C38.949 13.3432 38.6825 14.4123 38.1493 15.3469C37.6278 16.2816 36.8801 17.006 35.9065 17.52C34.9445 18.0342 33.826 18.2912 32.551 18.2912H27.9785V5.98828H32.551ZM32.3599 15.6975C33.4841 15.6975 34.3592 15.3878 34.9851 14.7686C35.611 14.1494 35.9239 13.2731 35.9239 12.1397C35.9239 11.0064 35.611 10.1243 34.9851 9.49338C34.3592 8.86247 33.4841 8.54701 32.3599 8.54701H30.9515V15.6975H32.3599Z" fill="#FF007A" />
                <path d="M42.1053 7.49565C41.5838 7.49565 41.155 7.34375 40.8187 7.03998C40.4942 6.72452 40.332 6.33896 40.332 5.8833C40.332 5.41595 40.4942 5.03038 40.8187 4.7266C41.155 4.41115 41.5838 4.25342 42.1053 4.25342C42.6153 4.25342 43.0326 4.41115 43.3571 4.7266C43.6932 5.03038 43.8613 5.41595 43.8613 5.8833C43.8613 6.33896 43.6932 6.72452 43.3571 7.03998C43.0326 7.34375 42.6153 7.49565 42.1053 7.49565ZM43.5832 8.51213V18.2913H40.6102V8.51213H43.5832Z" fill="#FF007A" />
                <path d="M49.685 18.4318C48.8389 18.4318 48.0855 18.2857 47.4247 17.9936C46.7641 17.7015 46.2425 17.3042 45.86 16.8019C45.4775 16.2878 45.2632 15.7153 45.2168 15.0843H48.155C48.1898 15.4232 48.3462 15.6978 48.6245 15.908C48.9026 16.1183 49.2445 16.2236 49.6502 16.2236C50.0211 16.2236 50.305 16.1534 50.5021 16.0132C50.7107 15.8614 50.815 15.6686 50.815 15.4349C50.815 15.1545 50.6702 14.95 50.3804 14.8214C50.0906 14.6813 49.6212 14.5294 48.9722 14.3659C48.2767 14.2023 47.6972 14.0328 47.2336 13.8576C46.7699 13.6706 46.37 13.3844 46.0339 12.9988C45.6978 12.6016 45.5297 12.07 45.5297 11.404C45.5297 10.8432 45.6804 10.3349 45.9818 9.87928C46.2947 9.41193 46.7467 9.04389 47.3379 8.77517C47.9405 8.50644 48.6533 8.37207 49.4763 8.37207C50.6933 8.37207 51.6496 8.67585 52.345 9.2834C53.0521 9.89096 53.4577 10.6971 53.562 11.7019H50.815C50.7687 11.3631 50.6181 11.0944 50.363 10.8958C50.1196 10.6971 49.7951 10.5978 49.3894 10.5978C49.0417 10.5978 48.7751 10.6679 48.5897 10.8081C48.4042 10.9366 48.3114 11.1178 48.3114 11.3514C48.3114 11.6318 48.4564 11.8421 48.7461 11.9823C49.0475 12.1225 49.5111 12.2628 50.137 12.403C50.8556 12.5899 51.441 12.7768 51.893 12.9638C52.345 13.139 52.739 13.4311 53.0753 13.8401C53.423 14.2373 53.6025 14.7748 53.6141 15.4524C53.6141 16.025 53.452 16.539 53.1274 16.9947C52.8144 17.4387 52.3566 17.7892 51.7539 18.0461C51.1627 18.3032 50.4731 18.4318 49.685 18.4318Z" fill="#FF007A" />
                <path d="M60.8396 15.75V18.2911H59.3269C58.249 18.2911 57.4087 18.0282 56.8061 17.5024C56.2033 16.9651 55.902 16.0946 55.902 14.8912V11.0005H54.7197V8.51189H55.902V6.12842H58.875V8.51189H60.8222V11.0005H58.875V14.9263C58.875 15.2184 58.9444 15.4286 59.0836 15.5571C59.2227 15.6857 59.4544 15.75 59.779 15.75H60.8396Z" fill="#FF007A" />
                <path d="M65.4691 10.1422C65.8168 9.60481 66.2515 9.18419 66.7731 8.88041C67.2946 8.56496 67.8742 8.40723 68.5116 8.40723V11.5793H67.6945C66.9527 11.5793 66.3964 11.7429 66.0255 12.0701C65.6545 12.3855 65.4691 12.9463 65.4691 13.7525V18.2916H62.4961V8.51238H65.4691V10.1422Z" fill="#FF007A" />
                <path d="M71.4277 7.49565C70.9061 7.49565 70.4772 7.34375 70.1411 7.03998C69.8166 6.72452 69.6543 6.33896 69.6543 5.8833C69.6543 5.41595 69.8166 5.03038 70.1411 4.7266C70.4772 4.41115 70.9061 4.25342 71.4277 4.25342C71.9376 4.25342 72.3549 4.41115 72.6795 4.7266C73.0156 5.03038 73.1836 5.41595 73.1836 5.8833C73.1836 6.33896 73.0156 6.72452 72.6795 7.03998C72.3549 7.34375 71.9376 7.49565 71.4277 7.49565ZM72.9055 8.51213V18.2913H69.9324V8.51213H72.9055Z" fill="#FF007A" />
                <path d="M78.0326 9.89691C78.3107 9.44125 78.7106 9.07322 79.2322 8.79281C79.7537 8.5124 80.3507 8.37219 81.0229 8.37219C81.8226 8.37219 82.5472 8.57666 83.1962 8.98559C83.8452 9.39452 84.3552 9.9787 84.7261 10.7381C85.1086 11.4976 85.2999 12.3797 85.2999 13.3845C85.2999 14.3893 85.1086 15.2773 84.7261 16.0484C84.3552 16.8078 83.8452 17.3979 83.1962 17.8185C82.5472 18.2273 81.8226 18.4319 81.0229 18.4319C80.3391 18.4319 79.7422 18.2975 79.2322 18.0287C78.7222 17.7483 78.3223 17.3803 78.0326 16.9246V18.2916H75.0596V5.32275H78.0326V9.89691ZM82.2747 13.3845C82.2747 12.6367 82.0661 12.0526 81.6489 11.6319C81.2432 11.1996 80.739 10.9835 80.1363 10.9835C79.5451 10.9835 79.0409 11.1996 78.6237 11.6319C78.218 12.0642 78.0152 12.6543 78.0152 13.402C78.0152 14.1498 78.218 14.7398 78.6237 15.1721C79.0409 15.6044 79.5451 15.8205 80.1363 15.8205C80.7274 15.8205 81.2316 15.6044 81.6489 15.1721C82.0661 14.7282 82.2747 14.1323 82.2747 13.3845Z" fill="#FF007A" />
                <path d="M96.4391 8.51221V18.2914H93.4661V16.9595C93.1648 17.3919 92.7533 17.7423 92.2317 18.011C91.7217 18.2681 91.1538 18.3966 90.5278 18.3966C89.7861 18.3966 89.1312 18.2331 88.5633 17.9059C87.9953 17.567 87.5548 17.0822 87.2419 16.4512C86.929 15.8203 86.7725 15.0784 86.7725 14.2255V8.51221H89.7281V13.8225C89.7281 14.4767 89.8962 14.9849 90.2323 15.3471C90.5684 15.7093 91.0205 15.8904 91.5885 15.8904C92.1679 15.8904 92.6258 15.7093 92.9619 15.3471C93.298 14.9849 93.4661 14.4767 93.4661 13.8225V8.51221H96.4391Z" fill="#FF007A" />
                <path d="M103.982 15.75V18.2911H102.47C101.392 18.2911 100.551 18.0282 99.9486 17.5024C99.346 16.9651 99.0447 16.0946 99.0447 14.8912V11.0005H97.8623V8.51189H99.0447V6.12842H102.018V8.51189H103.965V11.0005H102.018V14.9263C102.018 15.2184 102.087 15.4286 102.226 15.5571C102.365 15.6857 102.597 15.75 102.922 15.75H103.982Z" fill="#FF007A" />
                <path d="M114.783 13.2442C114.783 13.5246 114.766 13.8166 114.731 14.1205H108.003C108.049 14.728 108.24 15.1953 108.576 15.5225C108.924 15.8379 109.347 15.9957 109.845 15.9957C110.587 15.9957 111.103 15.6803 111.393 15.0493H114.557C114.395 15.6919 114.099 16.2702 113.67 16.7844C113.253 17.2984 112.726 17.7015 112.088 17.9936C111.451 18.2857 110.738 18.4318 109.95 18.4318C108.999 18.4318 108.153 18.2272 107.411 17.8184C106.67 17.4095 106.09 16.8252 105.673 16.0658C105.256 15.3064 105.047 14.4184 105.047 13.4019C105.047 12.3854 105.25 11.4975 105.655 10.738C106.073 9.97858 106.652 9.3944 107.394 8.98547C108.136 8.57654 108.988 8.37207 109.95 8.37207C110.889 8.37207 111.723 8.5707 112.453 8.96795C113.183 9.36519 113.751 9.93186 114.157 10.6679C114.574 11.404 114.783 12.2628 114.783 13.2442ZM111.741 12.4555C111.741 11.9414 111.567 11.5325 111.219 11.2287C110.871 10.925 110.437 10.7731 109.915 10.7731C109.417 10.7731 108.994 10.9191 108.646 11.2112C108.31 11.5033 108.101 11.9181 108.02 12.4555H111.741Z" fill="#FF007A" />
                <path d="M115.76 13.3845C115.76 12.3797 115.945 11.4976 116.316 10.7381C116.699 9.9787 117.214 9.39452 117.863 8.98559C118.513 8.57666 119.237 8.37219 120.037 8.37219C120.674 8.37219 121.254 8.50656 121.775 8.77529C122.308 9.04401 122.726 9.4062 123.027 9.86187V5.32275H126V18.2916H123.027V16.8896C122.749 17.357 122.349 17.7308 121.827 18.0112C121.318 18.2916 120.721 18.4319 120.037 18.4319C119.237 18.4319 118.513 18.2273 117.863 17.8185C117.214 17.3979 116.699 16.8078 116.316 16.0484C115.945 15.2773 115.76 14.3893 115.76 13.3845ZM123.027 13.402C123.027 12.6543 122.818 12.0642 122.401 11.6319C121.996 11.1996 121.497 10.9835 120.906 10.9835C120.315 10.9835 119.811 11.1996 119.393 11.6319C118.988 12.0526 118.785 12.6367 118.785 13.3845C118.785 14.1323 118.988 14.7282 119.393 15.1721C119.811 15.6044 120.315 15.8205 120.906 15.8205C121.497 15.8205 121.996 15.6044 122.401 15.1721C122.818 14.7398 123.027 14.1498 123.027 13.402Z" fill="#FF007A" />
            </g>
            <defs>
                <clipPath id="clip0_1028_35757">
                    <rect width="126" height="22.685" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
