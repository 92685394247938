export const currencyList = [
    {
        value: 'GBP',
        title: 'GBP',
      },
      {
        value: 'USD',
        title: 'USD',
      },
      {
        value: 'EUR',
        title: 'EUR',
      },
];
  