import * as React from 'react';
import moment from 'moment';
import InputComponent from 'new-app/components/input';
import ButtonComponent from 'new-app/components/button';
import { LargeEditIcon, CloseIcon2, CurrencyEuroIcon, CurrencyPoundIcon, CurrencyUSDollarIcon } from 'assets/icons';
import { CurrencyResponse } from 'new-app/models/profile';
import { getCurrencyUnit } from './utils';

interface CurrencyRateProps {
    rate: CurrencyResponse;
    title?: string;
    isLoading?: boolean;
    onSave: (item: CurrencyResponse, conversionRate: number) => void;
}

export default function CurrencyRate(props: CurrencyRateProps) {
    const { rate, isLoading, onSave } = props;
    const [isUpdateMode, setUpdateMode] = React.useState(false)
    const [conversionRate, setConversionRate] = React.useState(rate?.conversionRate);

    return (
        <div className='bg-white flex flex-col m-4 md:m-8 md:!ml-0 md:!mt-0 gap-4 border border-grey-200 rounded-lg p-4 md:p-6 max-w-[100%] min-w-[90%] md:min-w-[510px]'>
            <div className='flex flex-row justify-between mb-2'>
                <p className='text-[16px] font-semibold text-cool-900 leading-6 font-poppins'>
                    {`GBP (£) to ${rate.code} (${getCurrencyUnit(rate.code)})`}
                </p>
                <span className='cursor-pointer'>
                    {isUpdateMode ?
                        <CloseIcon2 onClick={() => setUpdateMode(false)} />
                        : <LargeEditIcon onClick={() => setUpdateMode(true)} />}
                </span>
            </div>
            {isUpdateMode ? (
                <div className='flex flex-row gap-4 py-2 mt-[1px]'>
                    <div className='!w-[160px]'>
                        <InputComponent
                            className="!h-12"
                            type='number'
                            value={conversionRate}
                            onChange={(e: any) => setConversionRate(e.target.value)}
                            placeholder='Exchange Rate'
                        />
                    </div>
                    <ButtonComponent
                        className='!px-8'
                        label='Save'
                        disabled={!conversionRate}
                        isLoading={isLoading}
                        onClick={() => onSave(rate, parseFloat(conversionRate))}
                    />
                </div>
            ) : (
                <div className='flex flex-row items-center gap-6'>
                    <div className='flex flex-row -gap-2'>
                        <span className='flex justify-center items-center w-10 h-10 rounded-full bg-marine-50 text-marine-500'>
                            <CurrencyPoundIcon />
                        </span>
                        <span className='flex justify-center items-center  w-10 h-10 rounded-full bg-marine-50 text-marine-500 -ml-2'>
                            {rate.code === "USD" ? <CurrencyUSDollarIcon /> : null}
                            {rate.code === "EUR" ? <CurrencyEuroIcon /> : null}
                            {rate.code === "GBP" ? <CurrencyPoundIcon /> : null}
                        </span>
                    </div>
                    <p className='text-[40px] font-semibold text-cool-900 leading-12 font-poppins'>{rate?.conversionRate ? parseFloat(rate?.conversionRate).toFixed(2) : 0}</p>
                </div>
            )}
            <p className='text-[14px] font-normal text-cool-600 leading-5 font-inter'>
                Last updated {rate?.lastUpdated ? moment(rate?.lastUpdated).format('DD MMMM YYYY') : 'N/A'}
            </p>
        </div>
    );
}