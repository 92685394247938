export const availabilityFilters = [
    { value: 0, label: "All"},
    { value: 1, label: "Available now"},
    { value: 7, label: "Within next weeks"},
    { value: 14, label: "Within next 2 weeks"},
    { value: 28, label: "Within next 4 weeks"},
];

export const communityStatusFilters = [
    { value: 'ETM', label: "Elastic Team Member"},
    { value: 'Academy', label: "Academy"},
    { value: 'NewApplicant', label: "New Candidate"},
];
