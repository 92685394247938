import React from 'react';

export const LocationIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.71875 15.6479C10.3438 13.6137 14 8.76284 14 6.0088C14 2.69144 11.3125 0 8 0C4.6875 0 2 2.69144 2 6.0088C2 8.76284 5.65625 13.6137 7.25 15.6479C7.625 16.1174 8.34375 16.1174 8.71875 15.6479ZM8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z" fill="#FF007A" />
        </svg>

    )
}
