/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TDocument, IProject } from 'interfaces';
import DataTable from 'examples/Tables/DataTable';
import MDButton from 'components/MDButton';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { confirm } from 'react-confirm-box';
import { dialogRender } from 'utils/confirmDialog';
import download from 'js-file-download';
import { useBackdrop } from 'context/backdrop';
import { fileSizeLimit } from 'constants/index';
import { mbToBytes } from 'utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface DialogProps {
  user: string;
  assetType: 'user' | 'project';
  open: boolean;
  handleClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export type DocumentRenderType = TDocument & { actions?: JSX.Element };

function DocumentDialog(props: DialogProps): JSX.Element {
  const { fetching } = useBackdrop();
  const { user, assetType, open, handleClose } = props;
  const [file, setFile] = useState<File>(null);
  const [documents, setDocuments] = useState<DocumentRenderType[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const fileAcceptTypes = [
    'application/rtf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'application/vnd.ms-excel',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ].join(',');

  useEffect(() => {
    fetchDocuments();
  }, [user]);

  const renderDocumentActions = (document: TDocument) => {
    return (
      <MDBox display="flex" justifyContent="space-between">
        <MDButton
          color="info"
          style={{ marginRight: 3 }}
          iconOnly
          onClick={() => handleDownload(document.path)}
        >
          <Icon>download</Icon>
        </MDButton>
        <MDButton
          color="primary"
          iconOnly
          onClick={() => handleDelete(document.name)}
        >
          <Icon>delete</Icon>
        </MDButton>
      </MDBox>
    );
  };

  const handleFileDialog = (e: ChangeEvent) => {
    const file = (e.target as HTMLInputElement).files[0];
    if (file.size > mbToBytes(fileSizeLimit)) {
      toast.warn(`File should be less than ${fileSizeLimit}MB!`);
      return;
    }
    setFile(file);
  };
  const handleUpload = async () => {
    const fileExists = documents.find((doc) => doc.name === file.name);
    if (fileExists) {
      alert('File already exists, Please delete the file and re-upload it');
      return;
    }

    fetching(true);
    // read file
    const contents = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async function (ev) {
        const contents = ev.target.result;
        resolve(contents);
      };
      reader.onerror = function (ev) {
        reject(ev);
      };
    });
    // upload file to s3 bucket
    const s3UploadResult = await axios.post(
      `/asset/upload`,
      {
        file: contents,
        type: file.type,
        asset: assetType,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
    if (s3UploadResult.status !== 200) {
      fetching(false);
      throw new Error('failed to upload file');
    }

    // save file in user record
    const filePath = s3UploadResult.data.data.Key;
    const result = await axios.post(
      '/user/files/add',
      {
        userId: user,
        path: filePath,
        name: file.name,
        type: file.type,
        size: file.size,
      },
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
    if (result.status !== 200) {
      fetching(false);
      throw new Error('failed to add uploaded file to the list');
    }

    // add document to the current state
    const doc: TDocument = {
      name: file.name,
      path: filePath,
      type: file.type,
      size: file.size,
      uploadedBy: '',
      uploadedAt: new Date().toISOString(),
      deleted: false,
    };
    setDocuments([
      ...documents,
      {
        ...doc,
        actions: renderDocumentActions(doc),
      },
    ]);

    fetching(false);
  };
  const handleDownload = async (filePath: string) => {
    fetching(true);
    const result = await axios.post('asset/download', {
      assetKey: filePath,
    });
    if (result.status !== 200) {
      fetching(false);
      throw new Error('failed to download file');
    }
    const data = result.data;
    if (!data || !data.url) {
      fetching(false);
      throw new Error('failed to get downloadable url');
    }
    window.open(data.url, '_blank');
    fetching(false);
  };
  const handleDelete = async (fileName: string) => {
    fetching(true);
    const result = await axios.delete(`/user/files/remove`, {
      headers: {
        'content-type': 'application/json',
      },
      data: {
        userId: user,
        name: fileName,
      },
    });
    if (result.status !== 200) {
      fetching(false);
      throw new Error('failed remove document');
    }
    setDocuments(documents.filter((doc) => doc.name !== fileName));
    fetching(false);
  };
  const fetchDocuments = async () => {
    if (!user) return;
    fetching(true);
    let files: TDocument[] = [];

    try {
      const result = await axios.get<Array<TDocument>>(
        `/user/files/list?userId=${user}`
      );
      files = result.data;
    } catch (err) {
      console.error(err);
    } finally {
      fetching(false);
    }

    setDocuments(
      files.map((doc) => ({
        ...doc,
        actions: renderDocumentActions(doc),
      }))
    );
    fetching(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Upload Documents
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={9}>
              <MDBox px={3}>
                <MDInput
                  label="Click Here To Upload File"
                  value={file?.name || ''}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MDButton
                          onClick={() => fileRef.current.click()}
                          iconOnly
                        >
                          <Icon>attach_file</Icon>
                        </MDButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  ref={fileRef}
                  accept={fileAcceptTypes}
                  style={{ display: 'none' }}
                  onChange={handleFileDialog}
                />
              </MDBox>
            </Grid>
            <Grid item xs={3}>
              <MDButton
                color="info"
                onClick={handleUpload}
                disabled={file === null}
              >
                <Icon>upload</Icon>&nbsp;Upload
              </MDButton>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                table={{
                  columns: [
                    { Header: 'File Name', accessor: 'name' },
                    {
                      Header: 'Uploaded At',
                      accessor: 'uploadedAtPretty',
                      width: '30%',
                    },
                    { Header: 'Actions', accessor: 'actions', width: '30%' },
                  ],
                  rows:
                    documents.map((doc) => ({
                      ...doc,
                      uploadedAtPretty: new Date(
                        doc.uploadedAt
                      ).toLocaleDateString('en'),
                    })) || [],
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

DocumentDialog.defaultProps = {
  project: undefined,
};
export default DocumentDialog;
