import React, { useState, useEffect } from 'react';
import SelectComponent from 'new-app/components/select';
import Autocomplete from 'react-google-autocomplete';
import ReactFlagsSelect from 'react-flags-select';
import { useForm, Controller } from "react-hook-form";
import { getCountryList, getNationalityList } from 'utils';
import 'react-phone-input-2/lib/style.css';
import InputComponent from 'new-app/components/input';
import Label from 'new-app/components/label';
import ErrorText from 'new-app/components/errorText';
import { phone } from 'phone';


export interface RoleProps {
    createdAt: string;
    description: string;
    id: number;
    name: string;
    updatedAt: string;
}

export interface RolesResponseType {
    items: RoleProps[];
}

const departmentItems = [
    {
        value:"NewApplicant",
        title: "New Candidate"
    },
    {
        value: "Academy",
        title: "Academy User"
    },
    {
        value: "ETM",
        title: "Elastic Team Member"
    },
]

export default function PersonalInfo(props: any) {
    const { register, errors, control, getValues, setValue, onChangeField } = props;
    

    const populateAddress = (place: any) => {
        onChangeField('addressLookupValue', place.formatted_address)
        const cityComponent = place.address_components.filter((x: any) => x.types.includes('locality'));
        if (cityComponent && cityComponent.length > 0) {
            setValue('cityName', cityComponent[0].long_name);
        }

        const countryComponent = place.address_components.filter((x: any) => x.types.includes('country'));
        if (countryComponent && countryComponent.length > 0) {
            setValue('countryName', countryComponent[0].long_name);
            setValue('countryIso', countryComponent[0].short_name);
        }
    };
    return (
        <div className={'flex flex-col gap-y-4'}>
            <div className={'flex flex-col md:flex-row gap-4'}>
                <InputComponent label='First Name' name='firstName' errors={errors} register={register} required />
                <InputComponent label='Last Name' name='lastName' errors={errors} register={register} required />
            </div>
            <div className='flex flex-col w-full'>
                <Label label='Role' />
                <Controller
                    render={
                        ({ field }) =>
                            <SelectComponent
                                value={field.value}
                                errors={errors}
                                name="recentRoleId"
                                items={props.roles}
                                onChange={(selections) => onChangeField('recentRoleId', selections)}
                            />
                    }
                    control={control}
                    name="recentRoleId"
                />
                <ErrorText text={errors.preferredRateCurrency?.message} />
            </div>
            <div className='flex flex-col w-full'>
                <Label label='Community Status' />
                <Controller
                    render={
                        ({ field }) =>
                            <SelectComponent
                                value={field.value}
                                errors={errors}
                                name="communityStatus"
                                items={departmentItems}
                                onChange={(selections) => onChangeField('communityStatus', selections)}
                            />
                    }
                    control={control}
                    name="communityStatus"
                />
                <ErrorText text={errors.preferredRateCurrency?.message} />
            </div>
            <InputComponent label='External Email' name='personalEmail' pattern={{ value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: 'Invalid email' }} errors={errors} register={register} required />
            <InputComponent label='Internal Email' name='distributedEmail' pattern={{ value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, message: 'Invalid email' }} errors={errors} register={register} />
            <InputComponent label='Phone' name='phone' register={register} errors={errors} required validate={(value: any) => phone(value).isValid ? true : 'Invalid phone number'} />

            <div className={'flex md:flex-row flex-col gap-4'}>
                <div className='flex flex-col w-full relative'>
                    <Label label='Nationality' />
                    <Controller
                        render={
                            ({ field }) =>
                                <ReactFlagsSelect
                                    selected={field.value}
                                    selectButtonClassName='border-0 !text-cool-900 !font-inter !font-normal !text-[14px]'
                                    countries={getCountryList()}
                                    customLabels={getNationalityList()}
                                    onSelect={(nationality) => onChangeField('nationality', nationality)}
                                    placeholder="Nationality"
                                    searchable
                                    searchPlaceholder="Search"
                                    className={`h-10 bg-white border border-[#D9D9D9] rounded text-cool-900 font-inter font-normal text-[14px] leading-5 `}
                                />
                        }
                        rules={{ required: "Nationality is required" }}
                        control={control}
                        name="nationality"
                    />
                    <ErrorText text={errors.nationality?.message} />
                </div>
                <div className='flex flex-col w-full'>
                    <Label label='Location' />
                    <Controller
                        render={
                            ({ field }) =>
                                <Autocomplete
                                    {...field}
                                    apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                    onPlaceSelected={populateAddress}
                                    placeholder='Location'
                                    className={`outline-0 pl-4 h-10 py-[10px] font-bold bg-white border border-[#D9D9D9] rounded text-cool-900 font-inter font-normal text-[14px] leading-5 `}
                                />
                        }
                        control={control}
                        rules={{ required: "Location is required" }}
                        name="addressLookupValue"
                    />
                    <ErrorText text={errors.location?.message} />
                </div>
            </div>
        </div>
    );
}