/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import axios from 'utils/axios';
import { Permission as PermissionList } from '../../interfaces/enums';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface DialogProps {
  editingMember?: any;
  open: boolean;
  handleClose: () => void;
  // handleChange: (event: any, field: string) => void;
  save: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function Permission(props: DialogProps): JSX.Element {
  const [permissions, setPermissions] = useState<Set<string>>(new Set([]));
  const id = props?.editingMember?._id ?? '';

  async function fetchRoles() {
    if (!id) return;
    const result = await axios.get(`/user/permissions/${id}`, {});
    if (result.status !== 200) {
      throw new Error('failed to get user permissions');
    }
    setPermissions(new Set(result.data as Array<string>));
  }
  async function addRole(role: string) {
    const result = await axios.post(`/user/permissions/`, {
      userid: id,
      group: role,
    });
    if (result.status !== 200) {
      throw new Error('failed to add user permissions');
    }
    const updatedPermissions = new Set<string>([...permissions]);
    updatedPermissions.add(role);
    setPermissions(updatedPermissions);
  }
  async function removeRole(role: string) {
    const result = await axios.delete(`/user/permissions/`, {
      data: {
        userid: id,
        group: role,
      },
    });
    if (result.status !== 200) {
      throw new Error('failed to remove user permissions');
    }
    const updatedPermissions = new Set<string>([...permissions]);
    updatedPermissions.delete(role);
    setPermissions(updatedPermissions);
  }
  useEffect(() => {
    fetchRoles();
  }, [props.editingMember]);

  if (!props.editingMember) {
    return null;
  }

  return (
    <div>
      <BootstrapDialog
        onClose={props?.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props?.open}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props?.handleClose}
        >
          Edit Permissions For {props?.editingMember?.firstName}{' '}
          {props?.editingMember?.lastName}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {Object.values(PermissionList).map((permission) => (
              <Grid item xs={12} key={permission}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={permissions.has(permission)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked) {
                            addRole(permission);
                          } else {
                            removeRole(permission);
                          }
                        }}
                      />
                    }
                    label={permission.replace('_', ' ')}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default Permission;
