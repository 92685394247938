import { useState, useEffect } from 'react';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import axios from 'utils/axios';
import { useBackdrop } from 'context/backdrop';
import Table from 'new-app/components/customTable';
import Pagination from 'new-app/components/pagination';
import Textfield from 'new-app/components/textfield';
import Card from 'new-app/components/card';
import { getLatestProject } from 'new-app/features/talent/components/utils/projectDetails';
import { formatBasicDate } from 'new-app/helpers/formatDate';

function Financials(): JSX.Element {
  const [financials, setFinancials] = useState([]);
  const { fetching } = useBackdrop();
  const [totalFinancials, setTotalFinancials] = useState(0);
  const [countFinancials, setCountFinancials] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [sortedColumns, setSortedColumns] = useState(
    new Map<string, number>([['End Date', 1]])
  );

  const [pagination, setPagination] = useState({ page: 0, limit: 10 });

  function sortedColumnMapped() {
    const data: { [key: string]: number } = {};
    for (const key of sortedColumns.keys()) {
      data[key] = sortedColumns.get(key);
    }
    return data;
  }

  const tableHeadings = [
    { title: 'Name', leftAlign: true},
    { title: 'Per day or hour'},
    { title: 'Currency'},
    { title: 'Project'},
    { title: 'Start Date'},
    { title: 'End Date'},
  ];
  const getData = async () => {
    try {
      fetching(true);
      const {
        data: { data, count, total },
      } = await axios.post('/user/financials', {
        searchKey,
        sortedColumns: sortedColumnMapped(),
        currentPage: pagination.page,
        perPage: pagination.limit,
      });

      const transformedData = data.map((data: Record<string, any>) => {
        const latestProject = data.currentProjects;

        return new Map<string, number | string>([
          ['Name', <div className='text-left'>{data.firstName + ' ' + data.lastName}</div>],
          ['Rate', data.payment?.amount ?? 0],
          ['Per day or hour', data.payment?.frequency ?? 'Day'],
          ['Currency', data.payment?.currency ?? 'N/A'],
          ['Project', <div className='inline-block text-left min-w-[190px]'>{latestProject.name}</div>],
          ['Start Date', formatBasicDate(latestProject.startDate)],
          ['End Date', formatBasicDate(latestProject.endDate)],
        ]);
      });
      console.log(transformedData);
      setFinancials([...transformedData]);
      setCountFinancials(count);
      setTotalFinancials(total);
    } finally {
      fetching(false);
    }
  };

  useEffect(() => {
    getData();
  }, [pagination, searchKey, sortedColumns]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Textfield
          type="text"
          placeholder="Search..."
          icon="search"
          onEnterPressed={(value) => {
            setSearchKey(value.toString());
          }}
        />
      </Card>
      <Card>
        <Table
          onHoverChangeColor
          headings={tableHeadings}
          data={financials}
          onSortClick={(sortedColumns) => {
            setSortedColumns(sortedColumns);
          }}
        />
        <Pagination
          limit={pagination.limit}
          page={pagination.page}
          total={totalFinancials}
          count={countFinancials}
          onChange={(limit, page) => {
            setPagination({
              page,
              limit,
            });
          }}
        />
      </Card>
    </DashboardLayout>
  );
}

export default Financials;
