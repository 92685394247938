import { User, UserFullProfileModel } from 'new-app/models/user';
import SphereApiInvoker from 'utils/sphereApiInvoker';
import { fetch, fetchNoCache, refresh } from '../helpers/cachedAxios';

export async function fetchUsersList(
  page = 0,
  limit = 10,
  searchTerms?: string[],
  availabilityDate?: Date,
  clearances?: string[],
  roles?: string[],
  skills?: string[],
  locations?: string[],
  projects?: string[],
  agencies?: string[],
  languages?: string[],
  sources?: string[],
  approvals?: boolean[]
): Promise<{
  talents: User[];
  total: number;
  count: number;
}> {
  const data = await fetch('/user/talent/all', 'post', {
    page,
    limit,
    searchTerms,
    availabilityDate,
    clearances,
    roles,
    skills,
    locations,
    projects,
    agencies,
    languages,
    sources,
    approvals,
  });
  return data;
}

export async function deleteUser(id: string) {
  await fetchNoCache(`/user/${id}`, 'delete');
}

export async function fetchAllLocations(): Promise<string[]> {
  return fetch('/user/locations/all', 'get');
}

export async function fetchAllLanguages(): Promise<string[]> {
  return fetch('/user/languages/all', 'get');
}

export async function fetchFullProfile(userId: string) : Promise<UserFullProfileModel> {
  return await new SphereApiInvoker().Fetch(`members/${userId}/full-profile`);
}