import React from 'react';

export function useUnselect(
  ref: React.MutableRefObject<any>,
  callback: () => void
) {
  function handleClickOutside(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}
