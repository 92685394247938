import { fetchCategories, addCategory, updateCategory } from '../reducers/skillCategories';
import Pagination from 'new-app/components/pagination';
import { useEffect, useState } from 'react';
import Table from 'new-app/components/table';
import Card from 'new-app/components/card';
import Textfield from 'new-app/components/textfield';
import { SKillCategoryList, SkillCategoryDetail } from 'interfaces';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Icon from '@mui/material/Icon';
import { toast } from 'react-toastify';
import SkillCategoryEdit from './SkillCategoryEdit';

export default function () {
  const [categories, setCategories] = useState<SKillCategoryList>({
    count: 0,
    total: 0,
    limit: 0,
    page: 0,
    items: [],
  });
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 0,
  });
  const [newCategory, setNewCategory] = useState('');
  const [editingSkillCategory, setEditingSkillCategory] = useState<SkillCategoryDetail>(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSaving, setSaving] = useState(false);

  function renderCategories(): Array<Map<string, JSX.Element>> {
    useEffect(() => {
      (async () => {
        const data = await fetchCategories({
          page: pagination.page,
          limit: pagination.limit,
        });
        setCategories(data);
      })();
    }, [pagination, isSaving]);

    return categories.items.map(
      (category) =>
        new Map([
          ['category', <span className="cursor-pointer">{category.name}</span>],
          ['actions',  (
            <MDBox>
              <MDButton
                color="info"
                style={{ marginRight: 3 }}
                iconOnly
                onClick={() => handleEdit(category)}
              >
                <Icon>edit</Icon>
              </MDButton>
            </MDBox>
          )],
        ])
    );
  }
  const handleEdit = (category: SkillCategoryDetail) => {
    setEditingSkillCategory(category);
    setOpenDialog(true);
  };
  const handleSkillCategoryChange = (value: string, key: string) => {
    const newCategory = {...editingSkillCategory, [key]: value}
    setEditingSkillCategory(newCategory);
  };
  const saveSkillCategory = async (category: SkillCategoryDetail) => {
    try {
      if (!category || !category.name) {
        toast.warn('Please Input Skill Name!');
        return;
      }
      setSaving(true);
      await updateCategory(category.id, category.name);
      toast.success('Saved a Skill Category Successfully!');
      setOpenDialog(false);
    } catch (error: any) {
      toast.warn(error.message);
    } finally {
      setSaving(false);
    }
  };

  async function addCategoryHandler(category: string | number) {
    if (category == '') return toast.warn('Please Input Category Name!');;
    try {
      setSaving(true);
      await addCategory(category);
      setSaving(false);
      toast.success('Added a Skill Category Successfully!');
      setNewCategory('');
    } catch (error: any) {
      if (error.response.data.message && typeof error.response.data.message === "string") {
        toast.warn(error.response.data.message || error.message);
      } else {
        toast.warn('Failed to save Skill Cateogry');
      }
    } finally {
      setSaving(false);
    }
  }

  return (
    <>
      <Card>
        <div className="grid grid-cols-2 gap-4">
          <Textfield
            type={'text'}
            icon={'add'}
            placeholder={'Enter Category'}
            value={newCategory}
            onEnterPressed={addCategoryHandler}
            onChange={(category) => setNewCategory(`${category}`)}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Table
            headings={['category', 'actions']}
            data={renderCategories()}
            onHoverChangeColor={true}
          />
          <Pagination
            count={categories.count}
            limit={pagination.limit}
            page={pagination.page}
            total={categories.total}
            onChange={(limit: any, page: any) => setPagination({ limit, page })}
          />
        </div>
      </Card>
      <SkillCategoryEdit
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        skillCategory={editingSkillCategory}
        handleChange={handleSkillCategoryChange}
        save={saveSkillCategory}
      />
    </>
  );
}
