import React, { useEffect, useRef, useState } from 'react';
import Textfield from './textfield';
import Checkbox from './checkbox';
import classnames from 'classnames';
import { useUnselect } from '../hooks/useUnselect';

export interface DropdownProps {
  text: string;
  options: Set<string>;
  defaultSelection?: string;
  allowUnselect?: boolean;
  multi?: boolean;
  showTitle?: boolean;
  showSearch?: boolean;
  onChange?: (selected: Set<string>) => void;
  customWidth?: boolean;
  className?: string;
}

export default function (props: DropdownProps) {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  const [filter, setFilter] = useState('');

  let options = props.options;
  if (props.allowUnselect && !props.multi) {
    options = new Set(['"None"', ...props.options]);
  }

  useUnselect(wrapperRef, () => setIsOpen(false));
  useEffect(() => {
    if (props.defaultSelection) setSelected(new Set([props.defaultSelection]));
  }, []);

  function optionSelected(option: string) {
    if (props.multi) {
      const newSelected = new Set(selected);
      if (selected.has(option)) {
        newSelected.delete(option);
      } else {
        newSelected.add(option);
      }
      setSelected(newSelected);
    } else {
      setSelected(new Set([option]));
      setIsOpen(false);
      if (props.onChange) {
        props.onChange(new Set([option]));
      }
    }
  }

  function getTextValue() {
    if (selected.size === 0) {
      return props.text;
    }

    if (props.multi) {
      return `${selected.size} ${props.text} selected`;
    } else {
      const selectedItem = [...selected][0];
      if (props.allowUnselect && selectedItem === [...options][0]) {
        return props.text;
      }
      return [...selected][0];
    }
  }

  function renderOptions(options: Set<string>) {
    return [...options]
      .filter((option) => option.toLowerCase().includes(filter.toLowerCase()))
      .map((option, index) => {
        let renderCheckbox: JSX.Element = null;
        if (props.multi) {
          const isChecked = selected.has(option);
          renderCheckbox = <Checkbox checked={isChecked} />;
        }
        return (
          <div
            className="flex flex-row gap-2 text-gray-700 px-4 py-2 text-sm cursor-pointer hover:bg-gray-50"
            role="menuitem"
            tabIndex={-1}
            id="menu-item-0"
            onClick={() => optionSelected(option)}
            key={index}
          >
            {renderCheckbox}
            {option}
          </div>
        );
      });
  }

  return (
    <div
      className={`${classnames({
        'min-w-150': !props.customWidth,
      })} flex grow basis-72 ${props.className ?? props.className}`}
      ref={wrapperRef}
    >
      <button
        type="button"
        className={`${classnames({
          'border-primary': selected.size !== 0 && props.multi,
          'border-border-primary': selected.size === 0 || !props.multi,
        })} flex flex-grow justify-start items-center rounded-md border text-sm px-4 py-2 bg-white hover:bg-background focus:outline-none focus:border-primary`}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`${classnames({
            'text-primary': selected.size !== 0 && props.multi,
          })} flex flex-grow`}
        >
          {getTextValue()}
        </span>
        <span className="material-icons flex flex-shrink text-primary">
          keyboard_arrow_down
        </span>
      </button>
      {isOpen && (
        <div
          className={`${classnames({
            'w-80': props.showSearch,
          })} absolute z-50 origin-top-left bg-white mt-11 rounded-lg shadow-xl border border-slate-100`}
          style={{ width: '95%' }}
        >
          {props.showTitle && (
            <div className="flex flex-row mx-4 mt-4">
              <span className="flex grow text-sm font-bold text-black">
                {props.text}
              </span>
              <span
                className="material-icons flex shrink text-text-mild-contrast cursor-pointer text-sm"
                onClick={() => setIsOpen(false)}
              >
                close
              </span>
            </div>
          )}
          {props.showSearch && (
            <div className="flex m-4">
              <Textfield
                type="text"
                icon="search"
                placeholder="Search..."
                value={filter}
                onChange={(value) => setFilter(`${value}`)}
              />
            </div>
          )}
          <div
            className={`${classnames({
              'h-80': props.showSearch,
              'overflow-y-scroll': props.showSearch,
            })} divide-y divide-background`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="py-1" role="none">
              {renderOptions(options)}
            </div>
          </div>
          {props.multi && (
            <div className="flex flex-row mx-4 my-4 items-center">
              <button
                className="flex grow uppercase text-sm font-bold underline cursor-pointer"
                onClick={() => {
                  if (props.onChange) {
                    props.onChange(new Set([]));
                  }
                  setSelected(new Set([]));
                }}
              >
                clear
              </button>
              <button
                className="flex uppercase font-bold text-sm text-white bg-primary rounded-lg px-4 py-2 cursor-pointer"
                onClick={() => {
                  if (props.onChange) {
                    props.onChange(selected);
                  }
                  setIsOpen(false);
                }}
              >
                apply
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
