import { useEffect, useMemo } from 'react';
import useState from 'react-usestateref'

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import Slider from '@mui/material/Slider';

import { logEvent, EventType } from "context/analytics"

import useForm from 'new-app/components/customInput';
import Heading from 'new-app/components/heading';
import Chip from 'new-app/components/chip';
import Table from 'new-app/components/customTable';
import Notification from 'new-app/components/notification';

import TableLoader from 'new-app/components/tableLoader';
import Tabs from 'new-app/components/tabs'
import RadioGroup from 'new-app/components/radioGroup';
import CheckboxGroup from 'new-app/components/checkboxGroup';
import { availabilityFilters, communityStatusFilters } from 'new-app/constants/strings';
import { CandidateSearchRequest } from 'new-app/models/candidateSearchRequest';
import { CandidateSearchResult, CandidateSearchItemResponse } from 'new-app/models/candidateSearchResult';
import { Shortlist } from 'new-app/models/profile';
import { UserIcon, FilterIcon, CloseIcon1 } from 'assets/icons';
import DisabledItem from 'new-app/components/disabledItem';
import Availability from 'new-app/components/availability';
import Tooltip from '@mui/material/Tooltip';

import MultipleSelectCheckmarks from 'components/MDMultiSelect';
import { CircularProgress } from '@mui/material';
import UserProfileDrawer from './userProfileDrawer';
import {
  searchCandidates,
  createShortlist,
  addUsersToShortlist,
  deleteShortlist,
  updateUsersFromShortlist,
  getShortlists,
  updateShortlist,
} from 'new-app/reducers/candidateSearch';
import moment from 'moment';
import { calculateLocationMatches } from 'utils/locationMatch';
import ShortlistsPopup from './shortlists/shortlistsPopup';
import ShortlistsDeleteModal from './shortlists/shortlistDeleteModal';
import ShortlistsDrawer from './shortlists/shortlistDrawer';

import EmptyShortlist from './shortlists/emptyShortlist';
import ShortlistStatus from './shortlists/shortlistStatus';
import { getCountryListByContinent } from 'utils';

const tabs = {
  'boolean_search': 0,
  'shortlists': 1,
}

const tableHeaders = {
  name: 'Name',
  role: 'Role',
  skills: 'Skills',
  dayrate: 'Day Rate',
  location: 'Location',
  availability: 'Availability',
  actions: ''
};

export default function () {
  // dropdown options
  const [locations, setLocations] = useState<Array<any>>([]);
  // talents
  const [users, setUsers, usersRef] = useState<CandidateSearchItemResponse[]>([]);
  const [sourceUsers, setSourceUsers] = useState<CandidateSearchResult>();
  const [totalTalents, setTotalTalents] = useState(0);
  const [countTalents, setCountTalents] = useState(0);
  const [selected, setSelected] = useState<Set<number>>(new Set([]));
  // filter
  const [searchTerms, setSearchTerms] = useState<string>('');
  const [locationMatches, setLocationMatches] = useState<Array<any>>([]);
  const [locationsFilter, setLocationsFilter] = useState<Array<string>>([]);
  const [availabilityFilter, setAvailabilityFilter] = useState(
    null
  );
  const [communityStatusFilter, setCommunityStatusFilter] = useState(
    null
  );
  // pagination
  const [pagination, setPagination] = useState({ page: 0, limit: 10 });

  const [tabValue, setTabValue] = useState(0);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [searchMode, setSearchMode] = useState(true);
  const [shortlistCreateMode, setShortlistCreateMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [shortlistUpdateMode, setShortlistUpdateMode] = useState(false);
  const [shortlistDeleteMode, setShortlistDeleteMode] = useState(false);
  const [shortListName, setShortlistName] = useState('');
  const [isShortlistSaving, setIsShortlistSaving] = useState(false);
  const [isShortlistLoading, setIsShortlistLoading] = useState(false);
  const [filterError, setFilterError] = useState('');
  const [dayRateFilter, setDayRateFilter, dayRateRef] = useState<number[]>([0, 1500]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tableSortFilter, setTableSortFilter] = useState(null);
  const [shortlists, setShortlists] = useState<Shortlist[]>([]);
  const [shortlistCandidates, setShortlistCandidates, candidatesRef] = useState<CandidateSearchItemResponse[]>([])
  const [selectedShortlist, setSelectedShortlist, selectedShortlistRef] = useState<Shortlist | null>(null);
  const [infiniteScrollPage, setInfiniteScrollPage] = useState(1);
  const [notification, setNotification] = useState({
    message: '',
    severity: '',
    open: false
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const addToShortlist = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      if (tabValue === tabs.shortlists) {
        setShortlistCreateMode(true);
      } else {
        setShortlistCreateMode(false);
      }
    }, 300)
  };

  const getExistingShortlists = async () => {
    setIsShortlistLoading(true);
    try {
      const res = await getShortlists();
      setShortlists(res)
      if (res.length > 0) {
        populateSelectedShortlist(res[0])
      } else {
        setSelectedShortlist(null);
        setShortlistName('');
      }
    } catch (error) {
      onCatchError(error);
    } finally {
      setIsShortlistLoading(false);
    }
  }

  const populateSelectedShortlist = (shortlist: Shortlist) => {
    setSelectedShortlist(shortlist);
    setShortlistName(shortlist?.name);
  }

  const onRemoveSelection = () => {
    setSelected(new Set([]))
  }

  const populateShortlistCandidates = (): CandidateSearchItemResponse[] => {
    const filterShortlistUsers = selectedShortlist?.users?.Results || [];
    setShortlistCandidates(filterShortlistUsers);
    return filterShortlistUsers;
  }

  useEffect(() => {
    if (tabValue === tabs.shortlists) {
      setShortlistName('');
      setShortlistCreateMode(true);
      setShortlistUpdateMode(false);
      setShortlistDeleteMode(false);
      populateShortlistCandidates();
      setInfiniteScrollPage(1)
    } else {
      setShortlistCreateMode(false);
      setInfiniteScrollPage(1)
    }
    setSelected(new Set([]));
  }, [tabValue, selectedShortlist])

  const minDistance = 10;

  const hasCommunityFilter = () => {
    return communityStatusFilter && Object.keys(communityStatusFilter).length > 0 &&
      Object.keys(communityStatusFilter).every(key => !!communityStatusFilter[key]);
  }

  const hasFilters = locationsFilter.length > 0 ||
    hasCommunityFilter() ||
    availabilityFilter || (dayRateFilter[0] !== 0 || dayRateFilter[1] !== 1500);

  const onResetSearch = () => {
    setIsLoading(true);
    setSearchMode(true);
    if (hasValidDayRateFilter && !searchTerms) {
      getUserList();
    }
    setSearchTerms('');
    if (typeof setFormValue === "function") {
      setFormValue('');
    }
  }

  const onCloseNotfication = () => {
    setNotification({
      message: '',
      severity: '',
      open: false
    })
  }

  const onEnterSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsLoading(true);
      onClearFilters();
      setSearchMode(true);
      setSearchTerms(formValue.toString());

      if (formValue) {
        const strVal = `${formValue}`.trim();
        const gaLogParams = {
          "category": "Elastic Talent Finder+",
          "action": "Search",
          "label": "Elastic Talent Finder Search Usage",
          "value": strVal,
          "searchTerms": formValue.toString()
        };
        logEvent(EventType.search, gaLogParams);
      }
    }
  }

  const [formValue, Form, setFormValue] = useForm({
    defaultState: "",
    placeholder: "Example: (node or javascript) AND (react OR bootstrap) NOT d3",
    icon: "search",
    endIcon: "close",
    customWidth: true,
    onIconClick: onResetSearch,
    onKeyPress: onEnterSearch
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getFilteredUsers = (filterData: CandidateSearchRequest): CandidateSearchResult => {
    const newData = sourceUsers.Results.filter((candidate: CandidateSearchItemResponse) => {
      let availabilityFilter = true;
      if (filterData['AvailableDateTo']) {
        availabilityFilter = moment(candidate.Availability.Date).isSameOrBefore(filterData['AvailableDateTo'], 'day');
      }
      if (filterData['AvailableNow']) {
        const now = new Date();
        availabilityFilter = moment(candidate.Availability.Date).isSameOrBefore(now, 'day');
      }
      let dayRateFilter = true;
      if ((filterData['DayRateFrom'] || filterData['DayRateFrom'] === 0) && filterData['DayRateTo'] && filterData['DayRateTo'] !== 1500 && filterData['DayRateFrom'] !== 0) {
        const candidateDayRate = candidate.DayRate.PrimaryCurrencyValue || 0;
        dayRateFilter = candidateDayRate >= filterData['DayRateFrom'] && candidateDayRate <= filterData['DayRateTo'];
      }
      let locationFilter = true;
      if (filterData['Countries']) {
        locationFilter = filterData['Countries'].includes(candidate.Location);
      }
      let communityStatusFilter = true;
      if (filterData['AcademyStatus']) {
        communityStatusFilter = filterData['AcademyStatus'].includes(candidate.CommunityStatus);
      }
      return availabilityFilter && dayRateFilter && locationFilter && communityStatusFilter;
    });
    return {
      Success: true,
      Error: null,
      Results: newData,
      ResultsCount: newData.length,
      TotalItems: sourceUsers.Results.length,
      LocationMatches: calculateLocationMatches(sourceUsers.Results)
    }
  }

  const getFilterItems = () => {
    const searchObjects: any = {
      Query: searchTerms || '',
      // PageSize: 100, // pagination.limit * (pagination.page + 1),
    }
    if (communityStatusFilter) {
      const academyFilters = Object.keys(communityStatusFilter).filter(key => !!communityStatusFilter[key]);
      if (academyFilters.length > 0) {
        searchObjects['AcademyStatus'] = academyFilters;
      }
    }
    if (dayRateFilter[0] < dayRateFilter[1]) {
      searchObjects['DayRateFrom'] = dayRateRef?.current[0] || dayRateFilter[0];
      searchObjects['DayRateTo'] = dayRateRef?.current[1] || dayRateFilter[1];
    }
    if (locationsFilter.length > 0) {
      searchObjects['Countries'] = locationsFilter.filter(i => i !== 'all');
    }
    if (availabilityFilter && parseInt(availabilityFilter) !== 0) {
      if (parseInt(availabilityFilter) === 1) {
        searchObjects['AvailableNow'] = true;
      } else {
        searchObjects['AvailableDateTo'] = new Date(moment().add(availabilityFilter, 'days').format('YYYY-MM-DD'));
      }
    }

    return searchObjects;
  }

  const getUserList = async () => {
    try {
      if (!searchTerms) return;
      searchMode || isLoading ? setIsLoading(true) : setIsFilterLoading(true);
      setUsers([]);

      let userData;
      if (!sourceUsers || (searchTerms && searchMode) || searchMode) {
        userData = await searchCandidates({
          Query: searchTerms || '',
        });
        setSourceUsers(userData);
      } else {
        const filterItems = getFilterItems();
        userData = getFilteredUsers(filterItems);
      }
      setUsers(tableSortFilter ? getSortedUsers(userData.Results, tableSortFilter) : userData.Results);
      setTotalTalents(userData.TotalItems);
      setCountTalents(userData.ResultsCount);
      setLocationMatches(userData.LocationMatches);
    } catch (error: any) {
      setNotification({
        severity: 'error',
        open: true,
        message: error?.message || 'Failed to retrieve candidates'
      })
    } finally {
      setIsLoading(false);
      setSearchMode(false);
      setTimeout(() => setIsFilterLoading(false), 1000);
    }
  }
  const hasValidDayRateFilter = dayRateFilter[0] < dayRateFilter[1];

  useEffect(() => {
    getExistingShortlists();
    setTimeout(() => {
      if (hasValidDayRateFilter) {
        getUserList();
      }
    }, 500)
  }, [
    searchTerms,
    // pagination,
    availabilityFilter,
    dayRateFilter,
    locationsFilter,
    communityStatusFilter,
  ]);

  useEffect(() => {
    if (hasValidDayRateFilter)
      setFilterError('');
  }, [dayRateFilter]);

  const tableData = useMemo(() => getTableData(), [users, tabValue, infiniteScrollPage, selectedShortlist]);

  const tableHeader = Object.values(tableHeaders).map((heading, index) => {
    return {
      title: heading,
      leftAlign: index <= 2,
    }
  });

  const populateLocationList = () => {
    const countriesByContinent = getCountryListByContinent();
    const worldContinents = [
      "Asia",
      'Africa',
      'North America',
      'South America',
      'Europe',
      'Oceania',
      'Antarctica'
    ]
    const getContinentByCountry = (country: string) => {
      const countryItem = countriesByContinent.find(i => i.cca2 === country || i.name === country)
      return worldContinents.includes(countryItem?.region) ? countryItem?.region : countryItem?.subregion;
    }

    const filterLocations: any[] = locationMatches
      .map(i => ({
        value: i.Country,
        parentId: getContinentByCountry(i.Country),
        title: i.Country,
        filter: i.Matches,
        header: '',
      }))
      .sort((a, b) => (b.filter - a.filter));

    const getCountryCountbyContinent = (continent: string) => {
      return filterLocations
        .filter(i => i.parentId === continent)
        .reduce((p, c) => {
          return p + c.filter || 0
        }, 0)
    }

    worldContinents.map(continent => {
      filterLocations.push({
        header: continent,
        title: continent,
        value: continent,
        filter: getCountryCountbyContinent(continent)
      })
    })
    setLocations(filterLocations)
  }

  useEffect(() => {
    if (locationMatches.length > 0) {
      populateLocationList();
    }
  }, [locationMatches])

  const onClickRow = (index: number) => {
    setOpenFilterDrawer(true);
    setShortlistCreateMode(false);
    onCloseNotfication();
    setSelectedUser(tabValue === tabs.shortlists ? populateShortlistCandidates()[index] : users[index]);
  }

  const onCloseFilter = () => {
    setOpenFilterDrawer(false);
    setSelectedUser(null)
  }

  const onOpenShortlist = (item: Shortlist) => {
    onCloseFilter();
    setTabValue(tabs.shortlists);
    setSelectedShortlist(shortlists.find(i => i?.id === item?.id));
  }

  async function onRemoveUserFromShortlist(event: React.MouseEvent<HTMLDivElement>, userId: string) {
    event.stopPropagation();
    try {
      await updateUsersFromShortlist(selectedShortlist.id, userId);
      setNotification({
        severity: 'success',
        message: 'Sucessfully updated shortlist',
        open: true
      });
      getExistingShortlists();
    } catch (error) {
      onCatchError(error);
    }
  }

  const onScrollTable = () => {
    setInfiniteScrollPage(infiniteScrollPage + 1)
  }

  function getTableData() {
    function renderSkillsText(skills: any[]) {
      const skillsTruncatedList = skills
        .slice(0, 3)
        .map((skill: any) => skill as string)
        .join(', ');

      if (skills.length <= 3) {
        return (
          <span className={skillsTruncatedList}>{skillsTruncatedList}</span>
        );
      }

      return (
        <span className="flex flex-row">
          <span className="block leading-4">
            {skillsTruncatedList}{' '}
            <span className="underline cursor-default leading-4">
              + {skills.length - 3} more
            </span>
          </span>
        </span>
      );
    }

    function renderCloseAction(user: CandidateSearchItemResponse) {
      return (
        <span className='cursor-pointer hover:!bg-cool-200 active:!bg-cool-200 w-6 h-6 flex justify-center items-center rounded'
          onClick={(event: React.MouseEvent<HTMLDivElement>) => onRemoveUserFromShortlist(event, user?.CandidateId)}
        >
          <CloseIcon1 />
        </span>
      )
    }
    const rowsPerPage = 50;
    const infiniteScrollData = users.filter((i, index) => index < rowsPerPage * infiniteScrollPage);
    const tableRows = (tabValue === tabs.shortlists ? populateShortlistCandidates() : infiniteScrollData).map((user, index) => {
      const userDayRate = user?.DayRate.Value;
      const userDayRateConvertedToGBP = user?.DayRate.PrimaryCurrencyValue;
      const tableRow = new Map<string, JSX.Element>([
        [
          tableHeaders.name,
          <div className="flex items-center">
            <span className="mr-4 ml-1"><UserIcon /></span>
            <div className='flex flex-col items-start gap-1'>
              <span className="flex text-left">{user?.Name || 'N/A'}</span>
              {user?.AcceptedCommunityMemberAgreement === 'true' ?
                <Tooltip title={"Community Member Agreement Accepted"}>
                  <span className='flex items-center font-inter font-normal text-[10px] text-success-700 border border-success-300 leading-[14px] bg-success-50 px-2 py-[2px] rounded-full'>CMA</span>
                </Tooltip>
                : null}
              {user?.AcceptedCommunityMemberAgreement === 'false' ?
                <Tooltip title={"Community Member Agreement Rejected"}>
                  <span className='flex items-center font-inter font-normal text-[10px] text-error-700 leading-[14px] border border-error-300 bg-error-50 px-2 py-[2px] rounded-full'>CMA</span>
                </Tooltip>
                : null}
              {!user?.AcceptedCommunityMemberAgreement || user?.AcceptedCommunityMemberAgreement === null ?
                <Tooltip title={"Pending Community Member Agreement"}>
                  <span className='flex items-center font-inter font-normal text-[10px] text-grey-700 leading-[14px] border border-cool-300 bg-grey-50 px-2 py-[2px] rounded-full'>CMA</span>
                </Tooltip>
                : null}
            </div>
          </div>
        ],
        [
          tableHeaders.role,
          <span className="block text-left">{user?.Role ?? ''}</span>,
        ],
        [
          tableHeaders.skills,
          <span className="flex text-left">{renderSkillsText(user?.Skills ?? [])}</span>,
        ],
        [
          tableHeaders.dayrate,
          <span className="flex justify-center">
            {`${(userDayRate && user?.DayRate.Currency) ? 'GBP' : ''} ` + (!!userDayRate && userDayRateConvertedToGBP ? userDayRateConvertedToGBP : 'N/A')}
          </span>,
        ],
        [
          tableHeaders.availability,
          <Availability date={user?.Availability?.Date ? new Date(user?.Availability?.Date) : null} />
        ],
        [tableHeaders.location, <span className="flex justify-center">{user?.Location || 'N/A'}</span>],
      ]);
      if (tabValue === tabs.shortlists) {
        tableRow.set(tableHeaders.actions, renderCloseAction(user));
      }
      return tableRow;
    });
    return tableRows;
  }

  function updateSelection(index: number) {
    const newSelection = new Set(selected);
    if (selected.has(index)) newSelection.delete(index);
    else newSelection.add(index);
    setSelected(newSelection);
  }

  const handleDayRateChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setDayRateFilter([Math.min(newValue[0], dayRateFilter[1] - minDistance), dayRateFilter[1]]);
    } else {
      setDayRateFilter([dayRateFilter[0], Math.max(newValue[1], dayRateFilter[0] + minDistance)]);
    }
  };

  const onChangeFrom = (event: React.FormEvent<HTMLInputElement>) => {
    setDayRateFilter([parseInt(event.currentTarget.value), dayRateFilter[1]]);
    if (parseInt(event.currentTarget.value) > dayRateFilter[1]) {
      setFilterError('‘From’ value must be lower than ‘To’ value');
    } else setFilterError('')
  }

  const onChangeTo = (event: React.FormEvent<HTMLInputElement>) => {
    setDayRateFilter(() => [dayRateFilter[0], parseInt(event.currentTarget.value)]);
    if (parseInt(event.currentTarget.value) < dayRateFilter[0]) {
      setFilterError('‘To’ value must be higher than ‘From’ value');
    } else setFilterError('')
  }

  const onClearFilters = () => {
    setDayRateFilter([0, 1500]);
    setAvailabilityFilter(null)
    setLocationsFilter([])
    setCommunityStatusFilter(null)
  }

  const onSelectLocation = (selections: any[]) => {
    if (selections[selections.length - 1] === 'all') {
      return setLocationsFilter([])
    }
    if (locationsFilter.includes('all') && selections[selections.length - 1] !== 'all') {
      return setLocationsFilter([]);
    }
    setLocationsFilter([...selections])
  }

  const getSortedUsers = (sortData: CandidateSearchItemResponse[], sortFilters: Map<string, number>) => {
    let sortedUsers = [...sortData]
    for (const [key, value] of sortFilters) {
      sortedUsers = sortedUsers.sort((a, b) => {
        if (typeof a[key as keyof CandidateSearchItemResponse] === "string") {
          return value * ((b[key as keyof CandidateSearchItemResponse] || "").toString().localeCompare((a[key as keyof CandidateSearchItemResponse] || "").toString()))
        } else if (key === "Day Rate") {
          return value * parseInt((b['DayRate']?.Value || 1).toString()) - value * parseInt((a['DayRate']?.Value || 1).toString())
        } else if (Array.isArray(a[key as keyof CandidateSearchItemResponse]) && key === "Skills") {
          return value * ((b[key].join(',')).localeCompare(a[key].join(',')))
        } else if (key === "Availability") {
          return value === 1 ? new Date(b[key]['Date']) > new Date(a[key]['Date']) ? 1 : -1 : new Date(a[key]['Date']) > new Date(b[key]['Date']) ? 1 : -1
        }
        return 1;
      });
    }
    return sortedUsers;
  }

  const onSortClick = (data: Map<string, number>) => {
    let sortedUsers = [...(usersRef.current)];
    sortedUsers = getSortedUsers(sortedUsers, data);
    setTableSortFilter(data);
    setUsers(() => [...sortedUsers])
  }

  const onUpdateShortlist = async () => {
    if (isShortlistSaving) return;
    setIsShortlistSaving(true);
    try {
      await updateShortlist(selectedShortlist?.id, shortListName);
      setNotification({
        severity: 'success',
        message: 'Sucessfully updated a shortlist',
        open: true
      });
      setShortlistUpdateMode(false);
      getExistingShortlists();
    } catch (error: any) {
      onCatchError(error);
    } finally {
      setIsShortlistSaving(false);
    }
  }

  const onDeleteAnyShortlist = (shortlist: Shortlist) => {
    populateSelectedShortlist(shortlist);
    setTimeout(() => {
      setShortlistDeleteMode(true);
    }, 300)
  }

  const onEditShortlist = (shortlist: Shortlist) => {
    populateSelectedShortlist(shortlist);
    setTimeout(() => {
      setShortlistUpdateMode(true);
      setShortlistName(shortlist?.name);
    }, 300)
  }

  const onDeleteShortlist = async () => {
    setIsShortlistSaving(true);
    try {
      await deleteShortlist(selectedShortlist?.id);
      setNotification({
        severity: 'success',
        message: 'Sucessfully removed a shortlist',
        open: true
      });
      setShortlistDeleteMode(false);
      getExistingShortlists();
    } catch (error: any) {
      onCatchError(error);
    } finally {
      setIsShortlistSaving(false);
    }
  }

  const onCatchError = (error: any) => {
    setNotification({
      severity: 'error',
      message: error?.message,
      open: true
    });
  }

  const onRenameShortlist = () => {
    setShortlistUpdateMode(true);
    setShortlistName(selectedShortlist?.name);
  }

  const onCloseModal = () => {
    setShortlistDeleteMode(false);
  }

  const onClearLocationFilters = () => {
    setLocationsFilter([]);
  }

  const onRemoveLocationFilter = (locationFilter: string) => {
    const newFilters = locationsFilter.filter(i => i !== locationFilter);
    setLocationsFilter(newFilters);
  }

  const onRemoveUsersFromShortlist = async () => {
    if (isShortlistSaving) return;
    setIsShortlistSaving(true);
    try {
      const userIndexes = [...selected];
      const currentUsers = populateShortlistCandidates();
      const userIds = currentUsers.filter((i, index) => userIndexes.includes(index)).map(i => i.CandidateId);
      await Promise.all(userIds.map(async (userId) => {
        return await updateUsersFromShortlist(selectedShortlist.id, userId);
      }))
      setNotification({
        severity: 'success',
        message: 'Sucessfully updated shortlist',
        open: true
      });
      getExistingShortlists();
    } catch (error) {
      onCatchError(error);
    } finally {
      setIsShortlistSaving(false);
    }
  }

  const onSaveShortlist = async (props: any) => {
    const { shortlistName, shortlistId } = props;
    setIsShortlistSaving(true);
    const userIndexes = [...selected];
    const userIds = users.filter((i, index) => userIndexes.includes(index)).map(i => i.CandidateId);
    if (userIds.length === 0 || selectedUser) {
      if (selectedUser?.CandidateId) {
        userIds.push(selectedUser?.CandidateId);
      }
    }
    try {
      if (shortlistName) {
        const res = await createShortlist(shortlistName)
        if (userIds.length > 0) {
          await addUsersToShortlist(res.id, userIds);
        }
        setNotification({
          severity: 'success',
          message: 'Sucessfully created a shortlist',
          open: true
        });
      } else if (shortlistId && userIds.length > 0) {
        await addUsersToShortlist(shortlistId, userIds);
        setNotification({
          severity: 'success',
          message: 'Sucessfully added to shortlist',
          open: true
        })
      }
    } catch (error: any) {
      setNotification({
        severity: 'error',
        message: error?.message || 'Failed to update shortlist',
        open: true
      })
    } finally {
      setIsShortlistSaving(false);
      handleClose();
      getExistingShortlists();
    }
  }

  function renderFilterDrawer() {
    return (
      <div className="flex flex-col bg-white justify-between p-3 pt-6 w-[256px] h-full grow">
        <div className="flex flex-col bg-white gap-y-6">
          <div className="flex flex-row items-center gap-x-3">
            <FilterIcon />
            <p className="text-sm font-inter font-semibold text-[#191C25]">Filter your results</p>
          </div>
          <RadioGroup
            items={availabilityFilters}
            label="Availability"
            value={availabilityFilter}
            onChange={setAvailabilityFilter}
          />
          <div>
            <p className="text-xs font-inter font-semibold text-label-black pb-3">Day Rate (GBP)</p>
            <Slider
              getAriaLabel={() => 'Minimum Rate'}
              value={dayRateFilter}
              onChange={handleDayRateChange}
              valueLabelDisplay="auto"
              getAriaValueText={() => dayRateFilter.toString()}
              disableSwap
              min={5}
              step={1}
              max={2000}
              classes={{ rail: '!text-[#D7DBE6] !opacity-100 !h-[6px]' }}
              className="!text-primary-blue"
            />
            <div className='flex flex-row gap-4 justify-between'>
              <div className='flex flex-col gap-y-2'>
                <label className="text-[10px] font-inter font-medium text-label-black">From</label>
                <input
                  type={'number'}
                  value={dayRateFilter[0]}
                  onChange={onChangeFrom}
                  className="focus:outline-none w-[108px] text-xs font-inter font-medium text-[#191C25] h-[34px] rounded px-3 py-2 border border-[#B3B7C2]"
                  style={{ border: filterError.indexOf('lower') > -1 ? '1px solid #CC1414' : '1px solid #B3B7C2', color: filterError ? '#CC1414' : '#191C25' }}
                />
              </div>
              <div className='flex flex-col gap-y-2'>
                <label className="text-[10px] font-inter font-medium text-label-black">To</label>
                <input
                  type={'number'}
                  value={dayRateFilter[1]}
                  onChange={onChangeTo}
                  className="focus:outline-none w-[108px] text-xs font-inter font-medium text-[#191C25] h-[34px] rounded px-3 py-2 border border-[#B3B7C2]"
                  style={{ border: filterError.indexOf('higher') > -1 ? '1px solid #CC1414' : '1px solid #B3B7C2', color: filterError ? '#CC1414' : '#191C25' }}
                />
              </div>
            </div>
            {filterError && <p className='font-inter text-[#CC1414] text-[14px] font-medium mt-2'>{filterError}</p>}
          </div>
          <div>
            <p className="text-xs font-inter font-semibold text-label-black pb-3">Location</p>
            <MultipleSelectCheckmarks
              items={locations}
              width={200}
              label={'Select Locations'}
              value={locationsFilter}
              multiple
              accordion
              onChange={(selections) => onSelectLocation(selections)}
            />
            <div className='flex flex-wrap gap-2 mt-3'>
              {locationsFilter.filter((i, index) => index < 10).map((locationFilter, index) => {
                return (
                  <span key={index} className='px-2 rounded cursor-pointer py-1 gap-1 flex flex-row bg-cool-50 border border-cool-200 text-cool-700 text-[12px] leading-4 font-inter'>
                    {locationFilter}
                    <CloseIcon1 onClick={() => onRemoveLocationFilter(locationFilter)} />
                  </span>
                )
              })}
              {locationsFilter.length > 0 ? (
                <span
                  onClick={onClearLocationFilters}
                  className='px-2 py-1 cursor-pointer rounded bg-cool-50 border border-cool-200 text-marine-400 text-[12px] leading-4 font-inter'>
                  Clear All
                </span>
              ) : null}
            </div>
          </div>
          <CheckboxGroup
            items={communityStatusFilters}
            label="Community Status"
            value={communityStatusFilter}
            onChange={setCommunityStatusFilter}
          />
        </div>
        {hasFilters && (
          <Chip
            className="bg-white h-10 rounded-lg text-center border-label-[#e5e7eb] font-semibold text-label-black text-[12px]"
            text={'Clear Filters'}
            icon='close'
            isFilter={false}
            onClick={onClearFilters}
          />
        )}
      </div>
    )
  }

  function renderSearchTab() {
    return (
      <div className='p-6'>
        <p className="text-label-black font-medium font-inter text-[12px] mb-2">Search by name, email or submit a Boolean search</p>
        <div className='flex justify-between'>
          {Form}
          <div className='ml-8'>
            <DisabledItem>
              <Button
                variant="contained"
                classes={{ root: '!shadow-none' }}
                startIcon={<SaveIcon />}
                disabled
                sx={{
                  color: 'white !important',
                  "&:hover": { backgroundColor: "transparent" },
                  borderRadius: '4px',
                }}>Save Search</Button>
            </DisabledItem>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 items-center justify-between mt-6">
          <div className="flex flex-wrap gap-4 items-center justify-around">
            <div className="flex gap-1 items-baseline">
              <Heading
                text={`${users.length.toLocaleString('en-US')}`}
                size="small"
                className="text-primary-pink text-[16px]"
              />
              <Heading
                text={` Results`}
                size="small"
                className="text-label-black text-[12px]"
              />
            </div>
          </div>
          <Button
            variant="contained"
            color="secondary"
            sx={{ background: '#E6EAF6 !important', borderRadius: '4px' }}
            classes={{ root: '!shadow-none' }}
            startIcon={<AddIcon />}
            disabled={selected.size === 0}
            onClick={addToShortlist}
          >Add to Shortlist</Button>
        </div>
      </div>
    )
  }

  const isEmptyShortlist = shortlists.length === 0;
  const hasShortlistData = tableData.length > 0;

  if (isLoading) {
    return (
      <div className='p-6 ml-1'>
        <TableLoader />
      </div>
    )
  }
  return (
    <div className="flex flex-col w-full mr-1 mt-1 overflow-auto">
      <Tabs.StyledTabs value={tabValue} variant="fullWidth" onChange={handleChange} aria-label="Elastic Talant Finder">
        <Tabs.StyledTab label="Boolean Search" />
        <Tabs.StyledTab label={`Shortlists(${shortlists.length})`} />
        <span className='absolute bottom-0 left-0 w-full border-b-2 border-[rgba(0, 0, 0, 0.12)]' />
      </Tabs.StyledTabs>
      <div className='flex flex-row gap-1' style={{ minHeight: 'calc(100vh - 44px)' }}>
        <div>
          {tabValue === tabs.boolean_search ? renderFilterDrawer() : null}
          {tabValue === tabs.shortlists ? (
            <ShortlistsDrawer
              shortlists={shortlists}
              selectedShortlist={selectedShortlist}
              onAdd={addToShortlist}
              onSelect={populateSelectedShortlist}
              onEdit={onEditShortlist}
              onDelete={onDeleteAnyShortlist}
            />
          ) : null}
        </div>
        <div className='w-full grow bg-white '>
          <Tabs.TabPanel value={tabValue} index={tabs.shortlists}>
            <div className='p-6'>
              {selectedShortlist ? (
                <ShortlistStatus
                  onRemoveSelection={onRemoveSelection}
                  isUpdateMode={shortlistUpdateMode}
                  shortListName={shortListName}
                  onSetUpdateMode={() => setShortlistUpdateMode(false)}
                  onSave={onUpdateShortlist}
                  onRemove={onRemoveUsersFromShortlist}
                  onChange={setShortlistName}
                  onDelete={() => setShortlistDeleteMode(true)}
                  onRename={onRenameShortlist}
                  selectedShortlist={selectedShortlist}
                  selected={selected}
                  isLoading={isShortlistSaving}
                />
              ) : null}

              {(isEmptyShortlist && !isShortlistLoading) ? <EmptyShortlist /> : null}
            </div>
            {hasShortlistData ? (
              <div className="overflow-auto w-full bg-white" id="eftShortlistInfiniteScrollLoader" style={{ height: 'calc(100vh - 226px)' }}>
                <Table
                  toggleSelection={updateSelection}
                  parentId="eftShortlistInfiniteScrollLoader"
                  selected={selected}
                  headings={tableHeader}
                  data={tableData}
                  onEntryClick={onClickRow}
                  onSortClick={onSortClick}
                />
              </div>
            ) : null}
            {isShortlistLoading ?
              (<div className='flex justify-center w-full mt-24'>
                <CircularProgress color="primary" size={40} />
              </div>) : null}
          </Tabs.TabPanel>
          <Tabs.TabPanel value={tabValue} index={tabs.boolean_search}>
            {renderSearchTab()}
            <div className="overflow-auto w-full bg-white" id="eftInfiniteScrollLoader" style={{ height: 'calc(100vh - 226px)' }}>
              <Table
                onHoverChangeColor
                parentId="eftInfiniteScrollLoader"
                fetchMoreData={onScrollTable}
                toggleSelection={updateSelection}
                selected={selected}
                headings={tableHeader.filter(i => Boolean(i.title))}
                data={tableData}
                onEntryClick={onClickRow}
                onSortClick={onSortClick}
                isLoading={isFilterLoading || isLoading}
              />
            </div>
          </Tabs.TabPanel>
        </div>
      </div>
      <UserProfileDrawer
        open={openFilterDrawer}
        selectedUser={selectedUser}
        onClose={onCloseFilter}
        onOpenShortlist={onOpenShortlist}
        setNotification={setNotification}
        onAddToShortlist={addToShortlist}
      />
      <ShortlistsPopup
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        onSave={onSaveShortlist}
        isLoading={isShortlistSaving}
        shortlists={shortlists}
        createMode={shortlistCreateMode}
        onAddShortlist={() => setShortlistCreateMode(true)}
      />
      <ShortlistsDeleteModal
        isOpen={shortlistDeleteMode}
        onClose={onCloseModal}
        onDelete={onDeleteShortlist}
        selected={selected}
        selectedShortlist={selectedShortlist}
        isLoading={isShortlistSaving}
      />
      <Notification
        onClose={onCloseNotfication}
        {...notification}
      />
    </div>
  );
}
