import React from 'react';
import Menu from '@mui/material/Menu';
import classnames from 'classnames';
import { EditIcon, DeleteIcon, DuplicateIcon, CloseCircleIcon } from 'assets/icons';

export interface MenuPopupProps {
    open: boolean;
    isDeprecated: boolean;
    handleClose: () => void;
    anchorEl: any;
    onEdit: () => void;
    onDuplicate: () => void;
    onDeprecate: () => void;
    onDelete: () => void;
}

export default function MenuPopup(props: MenuPopupProps) {
    const { onDelete, onDuplicate, onDeprecate, onEdit} = props;

    const actions: {
        name: string;
        icon: React.ReactElement;
        callback: () => void;
        primary?: boolean;
        divider?: boolean;
      }[] = [
        {
          name: 'Edit',
          icon: <EditIcon color='#707A89' />,
          callback: () => {onEdit();},
        },
        {
          name: 'Duplicate',
          icon: <DuplicateIcon />,
          callback: () => {onDuplicate();},
          divider: true
        },
        {
          name: 'Deprecate',
          icon: <CloseCircleIcon />,
          callback: () => {onDeprecate();},
          divider: true
        },
    
        {
          name: 'Delete Assessment',
          icon: <DeleteIcon />,
          primary: true,
          callback: () => {
            onDelete();
          },
        },
    ];

    if (props.isDeprecated) {
        delete actions[2];
    }
    
    return (
        <Menu
            id="basic-menu"
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.handleClose}
            classes={{
                root: '!zIndex-100',
                paper: '!p-0 !min-w-[160px] !cursor-pointer',
            }}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
           <div className="flex flex-col">
                {actions.map((action, actionIndex) => (
                    <div key={actionIndex}>
                        <button
                            className={`${classnames({
                                'text-primary': action.primary,
                            })} flex flex-row w-full items-center gap-2 px-4 py-3 hover:bg-background text-sm`}
                            onClick={action.callback}
                        >
                            <span className="flex">
                                {action.icon}
                            </span>
                            <span className="flex font-inter font-normal text-[14px] leading-5 text-cool-700">{action.name}</span>
                        </button>
                        {action.divider ? (
                            <span className='block w-full border border-grey-200' />
                        ) : null}
                    </div>
                ))}
            </div>
        </Menu>
    );
}