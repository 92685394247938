import { ISkillItem, ISkillList, TSkill } from 'interfaces';
import { UpdateSkillsRequest } from '../models/skill';
import { fetch } from '../helpers/cachedAxios';

export async function fetchSkills(
  options: any = { limit: 99, page: 0, search: null, categoryIds: null, isCore: null, isVisible: null }
): Promise<ISkillList> {
  return fetch('/engineer/skills', 'get', {
    params: { ...options },
  });
}

export async function updateSkills(skills: UpdateSkillsRequest): Promise<ISkillItem[]> {
  return fetch('/engineer/update-skills', 'put', skills);
}

export async function fetchTechRadarSkills(): Promise<TSkill[]> {
  return fetch('/engineer/tech-radar', 'get');
}
