import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ButtonComponent from 'new-app/components/button';
import { Shortlist } from 'new-app/models/profile';
import { CloseIcon2, FailureIcon } from 'assets/icons';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 3,
};

export interface ShortlistsDeleteModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onDelete: () => void;
  selectedShortlist: Shortlist;
  selected: any;
}


export default function ShortlistsDeleteModal(props: ShortlistsDeleteModalProps) {
  const { isOpen, isLoading, selectedShortlist, selected, onClose, onDelete } = props;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={modalStyle}>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row items-center gap-[14px]'>
            <FailureIcon />
            <p className="text-cool-900 font-semibold leading-5 font-poppins text-[18px]">Delete shortlist</p>
          </div>
          <span className='-mt-2 -mr-2 cursor-pointer' onClick={onClose}><CloseIcon2 /></span>
        </div>
        <div className='pt-4'>
          <p className="text-cool-600 font-normal leading-5 font-poppins text-[12px]">
            Are you sure you want to delete this shortlist?
          </p>
          <p className="text-cool-700 font-semibold leading-5 font-poppins text-[14px]">
            Shortlist Name {selectedShortlist?.name}, {selectedShortlist?.userIds?.length || 0} {selectedShortlist?.userIds?.length === 1 ? 'Candidate' : 'Candidates'}
          </p>
        </div>
        <div className='flex flex-row justify-end gap-4 pt-6'>
          <ButtonComponent
            className='!bg-grey-100 hover:!bg-grey-200 active:!bg-grey-400 !text-[#666666] text-[14px] !leading-6 !px-6'
            label='Cancel'
            onClick={onClose}
          />
          <ButtonComponent
            className='!bg-error-500 hover:!bg-error-600 active:!bg-error-800 !text-[white] !text-[12px] !px-6'
            label='Delete'
            onClick={onDelete}
            isLoading={isLoading}
          />
        </div>
      </Box>
    </Modal>
  )
}