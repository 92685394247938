import { useEffect, useState } from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import CircularProgress from '@mui/material/CircularProgress';
import { Skeleton } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Rating from '@mui/material/Rating';
import JsFileDownloader from 'js-file-downloader';
import { toast } from 'react-toastify';
import { UserIcon } from 'assets/icons';
import { renderAvailabilityText } from 'new-app/features/talent/components/utils/renderAvailabilityText';
import { GetFullProfile, getCVSignedUrl, getCVDownloadUrl } from 'new-app/reducers/candidateSearch';
import DisabledItem from 'new-app/components/disabledItem';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { Shortlist } from 'new-app/models/profile';
import Tooltip from '@mui/material/Tooltip';
import StyledAccordion from 'new-app/components/accordion';
import {
  LogoIcon,
  CloseIcon,
  MessageIcon,
  ChatIcon,
  FacebookIcon,
  LinkedinIcon,
  InstagramIcon,
  ExperienceIcon,
  LocationIcon,
  PlusIcon,
  ShareIcon,
  EditIcon,
  SettingsIcon,
  SummaryIcon,
  DownloadIcon,
  SectionIcon,
  WorkExperienceIcon,
  ChevronRightIcon,
  ChevronBottomIcon,
} from 'assets/icons';
import { SkillType, TechnicalTestResultModel } from 'new-app/models/userTest';
import { EmploymentHistory } from 'new-app/models/employmentHistory';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const CustomDrawer = styled((props: DrawerProps) => (
  <Drawer {...props} />
))(({ theme }) => ({
  '& > .MuiPaper-root': {
    margin: 0,
    borderRadius: 0,
    height: '100vh',
    width: '560px',
    maxWidth: '100%',
  },
}));

export default function UserProfileDrawer(props: any) {
  const { anchor = 'right', open = false, selectedUser, onAddToShortlist, onOpenShortlist, onClose } = props;
  const navigation = useNavigate();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [profile, setProfile] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [isViewDownloading, setIsViewDownloading] = useState<boolean>(false)

  const [expanded, setExpanded] = React.useState<string | false>('panel0');

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.stopPropagation();
      setExpanded(newExpanded ? panel : false);
    };


  useEffect(() => {
    if (selectedUser) {
      setLoading(true);
      getUserProfile(selectedUser?.CandidateId);
    }
  }, [selectedUser])

  const onCloseDrawer = () => {
    setProfile(null);
    onClose();
  }

  const getUserProfile = async (CandidateId: string) => {
    try {
      setProfile(null);
      const userProfile = await GetFullProfile(CandidateId);
      if (typeof userProfile === "string") {
        props.setNotification({
          message: 'Failed to retrieve user profile',
          open: true,
          severity: 'error'
        });
        onCloseDrawer();
        return setLoading(false)
      }
      setProfile(userProfile);
      setLoading(false)
    } catch (error) {
      props.setNotification({
        message: 'Failed to retrieve user profile',
        open: true,
        severity: 'error'
      });
      onCloseDrawer();
    } finally {
      setLoading(false)
    }
  }

  const getCvUrl = async (CandidateId: string) => {
    try {
      const cvUrl = await getCVSignedUrl(CandidateId);
      return cvUrl
    } catch (error) {
      return null;
    }
  }

  const Loader = () => {
    return (
      <div className="flex flex-col w-[100%]">
        <div className='profile-header flex justify-between items-center py-3'>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%', borderRadius: '8px' }} />
        </div>
        <div className='flex flex-row gap-x-4 items-center w-[100%]'>
          <Skeleton variant="circular" width={64} height={64} />
          <div className='flex flex-col gap-y-2 w-[200px]'>
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '40%' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '80%' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
          </div>
        </div>
        <div className='flex flex-row justify-between mt-4'>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '40%', borderRadius: '8px' }} />
          <div className='flex flex-row gap-x-2'>
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="circular" width={20} height={20} />
          </div>
        </div>
        <div className='flex flex-col w-[100%]  mt-4'>
          <div className='flex flex-row justify-between'>
            <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100px', height: '40px', borderRadius: '8px' }} />
            <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100px', height: '40px', borderRadius: '8px' }} />
            <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100px', height: '40px', borderRadius: '8px' }} />
            <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: '100px', height: '40px', borderRadius: '8px' }} />
          </div>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '40%', marginTop: '1rem' }} />
          <div className='flex flex-row justify-between w-[100%] mt-2'>
            <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '20%' }} />
            <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '20%' }} />
            <Skeleton variant="text" sx={{ fontSize: '1.3rem', width: '20%' }} />
          </div>
          {Array(3).fill('').map((item, index) => (
            <div className="flex flex-col w-[100%] mt-6" key={index}>
              <div className='flex flex-row gap-x-4 items-center w-[100%]'>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '50%' }} />
              </div>
              <div className='flex flex-col w-[100%] pl-10'>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '40%', borderRadius: '8px' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%', borderRadius: '8px' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%', borderRadius: '8px' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%', borderRadius: '8px' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '80%', borderRadius: '8px' }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  if (!profile || loading)
    return (
      <div>
        <React.Fragment key={anchor || 'left'}>
          <CustomDrawer
            anchor={anchor}
            open={open}
            onClose={onCloseDrawer}
            sx={{ margin: 0 }}
            classes={{ root: '!p-0 !m-0' }}
          >
            <div className="flex justify-start items-center h-[100%] flex-col p-8 pt-2 gap-y-4">
              <Loader />
            </div>
          </CustomDrawer>
        </React.Fragment>
      </div>
    );
  const {
    name,
    availableDate,
    communityStatus,
    dayRate,
    personalEmail,
    distributedEmail,
    locationDetails,
    phone,
    preferredRateCurrency,
    preferredContractLength,
    cvUpdatedDate,
    preferredHoursPerWeek,
    recentRole,
    nationality,
    summary,
    photo,
    skills,
    shortlists,
    userLanguageTestResult,
    technicalTestResults,
    employmentHistory,
    acceptedCommunityMemberAgreement,
  } = profile;

  const onSendMessage = () => {
    if (personalEmail) {
      const link = `mailto:${personalEmail}`
      window.open(link)
    }
  };

  const onShareProfile = async () => {
    return;
    const data = {
      url: 'https://distributed.com',
      text: 'Distributed',
      title: 'Elastic Profile',
    }
    await navigator.share(data)
  };

  const onEditProfile = () => {
    navigation(`/elastic_talent_board_plus/${selectedUser?.CandidateId}/edit`, { state: profile });
  }

  const getSkillExcellence = (level: number) => {
    if (level > 66) return 'Expert';
    if (level > 33) return 'Intermediate';
    return 'Beginner'
  }

  const getSkillBackground = (level: number) => {
    if (level > 66) return '#FF007A';
    if (level > 33) return '#7F2FE5';
    return '#585B65'
  }

  const downloadFile = async (url: string) => {
    try {
      await new JsFileDownloader({ url });
      toast.success('CV file downloaded.');
      setIsDownloading(false);
    } catch (error: any) {
      window.open(url, '_blank');
      // toast.warn(error.message);
      setIsDownloading(false);
    }
  }

  const onDownloadCV = () => {
    if (selectedUser) {
      setIsDownloading(true)
      getCVDownloadUrl(selectedUser?.CandidateId).then(res => {
        if (res) {
          downloadFile(res);
        } else {
          toast.error('No CV found for this user.');
          setIsDownloading(false);
        }
      }).catch(() => {
        toast.error('No CV found for this user.');
        setIsDownloading(false);
      })
    }
  };

  const onViewCV = () => {
    if (selectedUser) {
      setIsViewDownloading(true);
      getCvUrl(selectedUser?.CandidateId).then(link => {
        if (link) {
          window.open(link, '_blank');
        } else {
          toast.error('No CV found for this user.');
        }
      }).finally(() => {
        setIsViewDownloading(false)
      });
    }
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const distWorkExperiences = [
    {
      shortName: 'Capita',
      projectName: 'Capita BBC TVL',
      role: 'Business Analysis',
      projectLogo: '',
      period: {
        start: new Date('01/03/2023'),
        end: new Date('02/10/2023'),
      },
      clientFeedback: 'XXXYYY',
      peerFeedback: 'XXXYYY',
      distributedFeedback: 'XXXYYY',
    },
    {
      shortName: 'BT',
      projectName: 'Capita BBC TVL',
      role: 'Graphic Designer',
      projectLogo: '',
      period: {
        start: new Date('01/03/2023'),
        end: new Date('02/10/2023'),
      },
      clientFeedback: 'XXXYYY',
      peerFeedback: 'XXXYYY',
      distributedFeedback: 'XXXYYY',
    },
    {
      shortName: 'BT',
      projectName: 'Capita BBC TVL',
      role: 'Engineer',
      projectLogo: '',
      period: {
        start: new Date('01/03/2023'),
        end: new Date('02/10/2023'),
      },
      clientFeedback: 'XXXYYY',
      peerFeedback: 'XXXYYY',
      distributedFeedback: 'XXXYYY',
    },
  ]

  const list = (anchor: Anchor): any => (
    <Box
      sx={{ maxWidth: '100%', margin: 0 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className='profile-header flex justify-between items-center px-6 py-3'>
        <div className='flex justify-center items-center gap-x-2'>
          <LogoIcon />
          <p className='font-inter font-medium text-[12px] text-primary-blue'>Elastic Team Profile</p>
        </div>
        <span onClick={onCloseDrawer}><CloseIcon className='cursor-pointer' /></span>

      </div>

      <div className='profile-body flex flex-col px-6 py-4'>
        <div className='flex flex-row justify-between pb-[14px]'>
          <div className='flex flex-row gap-x-3 items-base'>
            <DisabledItem className={'h-[64px]'}>
              {photo ? <img src={photo} className='rounded-full' width={64} height={64} /> : <UserIcon width={64} height={64} />}
            </DisabledItem>
            <div className='flex flex-col gap-y-2 justify-start'>
              <div className='flex flex-col gap-y-1 justify-start'>
                <div className='flex flex-row gap-2'>
                  <p className='font-inter font-medium text-[14px] text-[#191C25]'>{name}</p>
                  {acceptedCommunityMemberAgreement === true ?
                    <Tooltip title={"Community Member Agreement Accepted"}>
                      <span className='flex items-center font-inter font-normal text-[10px] border border-success-300 text-success-700 leading-[14px] bg-success-50 px-2 py-[2px] rounded-full'>CMA</span>
                    </Tooltip>
                    : null}
                  {acceptedCommunityMemberAgreement === false ?
                    <Tooltip title={"Community Member Agreement Rejected"}>
                      <span className='flex items-center font-inter font-normal text-[10px] border border-error-300 text-error-700 leading-[14px] bg-error-50 px-2 py-[2px] rounded-full'>CMA</span>
                    </Tooltip>
                    : null}
                  {acceptedCommunityMemberAgreement === null ?
                    <Tooltip title={"Pending Community Member Agreement"}>
                      <span className='flex items-center font-inter font-normal text-[10px] border border-cool-300 text-grey-700 leading-[14px] bg-grey-50 px-2 py-[2px] rounded-full'>CMA</span>
                    </Tooltip>
                    : null}
                </div>
                <p className='font-inter font-medium text-[12px] text-[#191C25]'>{recentRole || 'Recent Role N/A'}</p>
              </div>
              <div className='flex flex-row items-center gap-x-2'>
                <div className='bg-primary-pink rounded-full px-2 py-1'>
                  <p className='font-inter font-medium text-[12px] text-white'>{communityStatus}</p>
                </div>
                <DisabledItem>
                  <Rating name="size-medium" defaultValue={0} precision={0.5} readOnly />
                </DisabledItem>
              </div>
              <div className='flex flex-col gap-y-1 justify-start'>
                <p className='font-inter font-medium text-[12px] text-[#191C25] leading-4'>{personalEmail || 'Personal Email N/A'}</p>
                <p className='font-inter font-medium text-[12px] text-[#191C25] leading-4'>{distributedEmail || 'Internal Email N/A'}</p>
                <p className='font-inter font-medium text-[12px] text-[#191C25] leading-4'>{phone || 'Phone number N/A'}</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-y-2 text-right'>
            <p className='font-inter font-medium text-[14px] text-[#191C25]'>SFIA Level N/A</p>
            <p className='font-inter font-medium text-[14px] text-[#191C25]'>{dayRate || 'N/A'} {(dayRate && preferredRateCurrency) ? preferredRateCurrency : ''} per day</p>
            <p className='font-inter font-medium text-[14px] text-[#191C25]'>{preferredHoursPerWeek || 'N/A'} Hours per week</p>
            <p className='font-inter font-medium text-[14px] text-[#191C25]'>{preferredContractLength || 'N/A'} Months Contract</p>
            <p className='font-inter font-medium text-[14px] text-[#191C25]' style={{ color: availableDate ? '#37B837' : '#191C25' }}>{availableDate ? renderAvailabilityText([{ endDate: availableDate ?? null }]) : 'Availability N/A'}</p>
          </div>
        </div>

        <div className='flex flex-row justify-between items-center py-[14px]'>
          <div className='flex flex-row gap-x-3 items-center'>
            <p className='font-inter font-medium text-[12px] text-[#393D46]'>{nationality || 'Nationality N/A'}</p>
            <LocationIcon />
            <p className='font-inter font-medium text-[12px] text-[#393D46]'>{locationDetails?.countryName || 'Location N/A'}</p>
          </div>
          <DisabledItem>
            <div className='flex flex-row items-center gap-x-3'>
              <a><LinkedinIcon className='cursor-pointer' /></a>
              <a><FacebookIcon className='cursor-pointer' /></a>
              <a><InstagramIcon className='cursor-pointer' /></a>
            </div>
          </DisabledItem>
        </div>
        <div className='flex flex-col pb-6 gap-4 pt-[14px]'>
          <div className='flex flex-row gap-x-3 justify-between'>
            <div className='flex flex-row gap-x-2 px-4 py-3 bg-primary-blue rounded items-center cursor-pointer h-10' onClick={onSendMessage}>
              <MessageIcon />
              <p className='font-inter font-medium text-[12px] text-white'>Message</p>
            </div>
            <div className='flex relative flex-row gap-x-2 px-4 py-3 border border-[#6C6F79] rounded items-center cursor-pointer h-10' onClick={onAddToShortlist}>
              <PlusIcon />
              <p className='font-inter font-medium text-[12px] text-[#393D46]'>Shortlist</p>
            </div>
            <DisabledItem>
              <div className='flex flex-row gap-x-2 px-4 py-3 border border-[#6C6F79] rounded items-center h-10' onClick={onShareProfile}>
                <ShareIcon />
                <p className='font-inter font-medium text-[12px] text-[#393D46]'>Share Profile</p>
              </div>
            </DisabledItem>
            <div className='flex flex-row gap-x-2 px-4 py-3 border border-[#6C6F79] cursor-pointer rounded items-center h-10' onClick={onEditProfile}>
              <EditIcon />
              <p className='font-inter font-medium text-[12px] text-[#393D46]'>Edit Profile</p>
            </div>
          </div>
          {shortlists && shortlists.length > 0 ? (
            <div className='flex flex-row flex-wrap items-center gap-2'>
              <p className='text-cool-800 text-[12px] font-medium font-inter leading-4'>Shortlists:</p>
              {shortlists.map((item: Shortlist) => {
                return (
                  <div className='bg-cool-50 border border-cool-200 px-2 py-1 rounded cursor-pointer' key={item?.id} onClick={() => onOpenShortlist(item)}>
                    <p className='text-cool-700 text-[12px] font-normal font-inter leading-4'>{item?.name}</p>
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>

        <hr className='bg-[#B3B7C2]' />
        <div className='flex flex-col gap-y-[14px] py-6'>
          <div className='flex flex-row items-center gap-x-2'>
            <SettingsIcon />
            <p className='font-inter font-medium text-[14px] text-[#191C25]'>Skills</p>
          </div>
          {skills && skills.sort((a: SkillType, b: SkillType) => b.level - a.level)
            .filter((skill: SkillType, index: number) => index < 10)
            .map((skill: SkillType, index: number) => {
              const { name, level } = skill;
              return (
                <div className='flex flex-row justify-between items-center' key={index}>
                  <p className='font-inter font-medium text-[12px] text-[#191C25]'>{name}</p>
                  <div className='flex flex-row justify-between'>
                    <div
                      className='bg-primary-pink rounded-full px-2 py-1 flex justify-center items-center'
                      style={{ background: getSkillBackground(level) }}
                    >
                      <p className='font-inter font-medium text-[10px] text-white'>{getSkillExcellence(level)}</p>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>

        {process.env.REACT_APP_DSTBTD_PROJECT_EXPERIENCE_ENABLED === 'true' && (
          <>
            <hr className='bg-[#B3B7C2]' />
            <div className='flex flex-col gap-y-[14px] py-6'>
              <div className='flex flex-row items-center gap-x-2'>
                <WorkExperienceIcon />
                <p className='font-inter font-medium text-[14px] text-[#191C25]'>Distributed Work Experience</p>
              </div>


              {distWorkExperiences && distWorkExperiences.map((workExperience: any, index: number) => {
                const { shortName, projectName, role, projectLogo, period, clientFeedback, peerFeedback, distributedFeedback } = workExperience;
                return (
                  <div className='flex flex-col' key={index}>
                    <StyledAccordion.Accordion
                      expanded={expanded === `panel${index}`}
                      onChange={handleAccordionChange(`panel${index}`)}
                    >
                      <StyledAccordion.AccordionSummary className='!pl-0' expandIcon={expanded === `panel${index}` ? <ChevronBottomIcon /> : <ChevronRightIcon />}>
                        <div className='flex flex-row justify-between w-full pl-2'>
                          <div className='flex flex-row gap-6 w-[65%]'>
                            <div className='flex flex-row gap-1 w-[170px] min-w-[170px]'>
                              <p className='text-cool-900 font-inter font-semibold text-[12px] leading-4'>{shortName}</p>
                              <span className='bg-gray-200 w-[1px] h-full' />
                              <p className='text-cool-900 font-inter font-normal text-[12px] leading-4 text-ellipsis overflow-hidden'>{projectName}</p>
                            </div>
                            <p className='text-cool-900 font-inter font-normal text-[12px] w-[120px] leading-4 text-ellipsis overflow-hidden'>{role}</p>
                          </div>
                          <div>
                            <p className='text-cool-900 font-inter font-normal text-[12px] leading-4'>
                              {`${period?.start ? moment(period?.start).utc().format('MM/DD/YY') : 'N/A'} - ${period?.end ? moment(period?.end).utc().format('MM/DD/YY') : 'N/A'}`}
                            </p>
                          </div>
                        </div>
                      </StyledAccordion.AccordionSummary>
                      <StyledAccordion.AccordionDetails className='!ml-[29px] !mt-3'>
                        <div className='flex flex-col gap-4 px-5 py-4 border border-[#B3B7C2] rounded'>
                          <div className='flex flex-row justify-between items-start'>
                            {projectLogo ? <img src={projectLogo} alt='project logo' className='h-10' /> : (
                              <div className='flex items-center justify-center w-10 h-10 font-inter font-semibold uppercase border-2 border-[#5514B4] text-[#5514B4] rounded-full text-[16px]'>
                                {shortName?.slice(0, 2)}
                              </div>
                            )}
                            <p className='text-cool-900 font-inter font-normal text-[12px] leading-4'>
                              {`${period?.start ? moment(period?.start).utc().format('MM/DD/YY') : 'N/A'} - ${period?.end ? moment(period?.end).utc().format('MM/DD/YY') : 'N/A'}`}
                            </p>
                          </div>
                          <div className='flex flex-col gap-1'>
                            <p className='text-cool-900 font-inter font-semibold text-[14px] leading-5'>{projectName}</p>
                            <p className='text-cool-900 font-inter font-normal text-[12px] leading-4'>{role}</p>
                          </div>
                          <div className='flex flex-col gap-1'>
                            <p className='text-cool-900 font-inter font-semibold text-[14px] leading-5'>Client Feedback</p>
                            <p className='text-cool-900 font-inter font-normal text-[12px] leading-4'>{clientFeedback || 'N/A'}</p>
                          </div>
                          <div className='flex flex-col gap-1'>
                            <p className='text-cool-900 font-inter font-semibold text-[14px] leading-5'>Peer Feedback</p>
                            <p className='text-cool-900 font-inter font-normal text-[12px] leading-4'>{peerFeedback || 'N/A'}</p>
                          </div>
                          <div className='flex flex-col gap-1'>
                            <p className='text-cool-900 font-inter font-semibold text-[14px] leading-5'>Distributed Feedback</p>
                            <p className='text-cool-900 font-inter font-normal text-[12px] leading-4'>{distributedFeedback || 'N/A'}</p>
                          </div>
                        </div>
                      </StyledAccordion.AccordionDetails>
                    </StyledAccordion.Accordion>

                  </div>
                )
              })}
            </div>
          </>
        )}

        <>
          <hr className='bg-[#B3B7C2]' />
          <div className='flex justify-between flex-row py-6'>
            <div className='flex flex-col gap-y-4 w-[300px]'>
              <div className='flex flex-row items-center gap-x-2'>
                <ChatIcon />
                <p className='font-inter font-medium text-[14px] text-[#191C25]'>{userLanguageTestResult?.testName ? userLanguageTestResult?.testName.replace('unknown', 'English Language') : 'English Language'}</p>
              </div>
              <BorderLinearProgress variant="determinate" color='secondary' value={userLanguageTestResult?.trueNorthScore ? (userLanguageTestResult?.trueNorthScore * 100) / 10 : 0} />
            </div>
            <div className='flex flex-row gap-x-8'>
              <div className='flex flex-col gap-y-4'>
                <p className='font-inter font-medium text-[14px] text-[#191C25]'>True North</p>
                <p className='font-inter font-medium text-[12px] text-[#191C25] text-center'>{userLanguageTestResult?.trueNorthScore || 'N/A'}</p>
              </div>
              <div className='flex flex-col gap-y-4'>
                <p className='font-inter font-medium text-[14px] text-[#191C25]'>CEFR</p>
                <p className='font-inter font-medium text-[12px] text-[#191C25] text-center'>{userLanguageTestResult?.cefrScore || 'N/A'}</p>
              </div>
            </div>
          </div>
        </>
        <>
          <hr className='bg-[#B3B7C2]' />
          <div className='flex justify-between flex-col py-6 gap-y-4'>
            <div className='flex flex-row items-center gap-x-2'>
              <SectionIcon />
              <p className='font-inter font-medium text-[14px] text-[#191C25]'>Technical Test Results</p>
            </div>
            <div className='flex justify-between flex-col gap-y-[22px]'>
              {technicalTestResults && technicalTestResults.length > 0 ? technicalTestResults.map((technicalTest: TechnicalTestResultModel, index: number) => {
                const { testName, score, date } = technicalTest;
                return (
                  <div className='flex flex-row  justify-between text-ellipsis overflow-hidden' key={index}>
                    <div className='flex flex-row justify-between gap-x-4 w-[65%]'>
                      <p className='font-inter font-medium text-[12px] leading-4 text-[#191C25] max-w-[220px] w-[220px] text-ellipsis overflow-hidden'>{testName || 'N/A'}</p>
                      <p className='font-inter font-medium text-[12px] leading-4 text-[#191C25] text-left text-ellipsis overflow-hidden'>{date ? moment(date).utc().format('MMMM DD YYYY') : 'N/A'}</p>
                    </div>
                    <p className='font-inter font-medium text-[12px] leading-4 text-[#191C25] text-right text-ellipsis'>{score || 'N/A'}</p>
                  </div>
                )
              }) : (
                <p className='font-inter font-medium text-[12px] text-[#191C25]'>N/A</p>
              )}
            </div>
          </div>
        </>
        <>
          <hr className='bg-[#B3B7C2]' />
          <div className='flex flex-col py-6'>
            <div className='flex flex-row items-center gap-x-2'>
              <SummaryIcon />
              <p className='font-inter font-medium text-[14px] text-[#191C25]'>Summary</p>
            </div>
            <p className='font-inter font-medium text-[12px] text-[#393D46] mt-3 whitespace-pre-line'>
              {summary || 'N/A'}
            </p>
            <div className='flex flex-row items-center gap-x-3 mt-9'>
              {!!cvUpdatedDate ? (
                <div
                  className='flex flex-row gap-x-[10px] px-5 py-3 border border-[#6C6F79] rounded items-center cursor-pointer h-10'
                  onClick={onDownloadCV}
                >
                  <DownloadIcon />
                  <p className='font-inter font-medium text-[12px] text-[#393D46]'>Download CV</p>
                  {isDownloading && <CircularProgress color="inherit" size={20} />}
                </div>
              ) : (
                <DisabledItem title={'No CV available for this candidate'}>
                  <div
                    className='flex flex-row gap-x-[10px] px-5 py-3 border border-[#6C6F79] rounded items-center cursor-pointer h-10'
                  >
                    <DownloadIcon />
                    <p className='font-inter font-medium text-[12px] text-[#393D46]'>Download CV</p>
                  </div>
                </DisabledItem>
              )}
              {!!cvUpdatedDate ? (
                <div className='flex flex-row gap-x-[10px] px-5 py-3 border border-[#6C6F79] rounded items-center cursor-pointer h-10' onClick={onViewCV}>
                  <span className="flex material-icons material-symbols-outlined text-[#585B65] text-[17px]">
                    visibility
                  </span>
                  <p className='font-inter font-medium text-[12px] text-[#393D46]'>View CV</p>
                  {isViewDownloading && <CircularProgress color="inherit" size={20} />}
                </div>
              ) : null}
              <p className='font-inter font-medium text-[12px] text-[#585B65]'>Uploaded: {cvUpdatedDate ? moment(cvUpdatedDate).utc().format('hh:mm DD MMMM YYYY') : 'N/A'}</p>
            </div>
          </div>
        </>
        <>
          <hr className='bg-[#B3B7C2]' />
          <div className='flex flex-col gap-y-4 py-6'>
            <div className='flex flex-row items-center gap-x-2'>
              <ExperienceIcon />
              <p className='font-inter font-medium text-[14px] text-[#191C25]'>Previous Experience</p>
            </div>
            {employmentHistory && employmentHistory.length > 0 ? employmentHistory.map((history: EmploymentHistory, index: number) => {
              const { jobTitle, employerName, dates } = history;
              return (
                <div className='flex flex-row justify-between text-ellipsis overflow-hidden' key={index}>
                  <div className='flex flex-row justify-between gap-x-8 w-[75%]'>
                    <p className='font-inter font-medium text-[12px] text-[#191C25] text-ellipsis overflow-hidden'>{jobTitle || 'N/A'}</p>
                    <p className='font-inter font-medium text-[12px] text-[#191C25] min-w-[160px] max-w-[160px] text-left text-ellipsis overflow-hidden'>{employerName || 'N/A'}</p>
                  </div>
                  <p className='font-inter font-medium text-[12px] text-[#191C25] min-w-[85px] text-right text-ellipsis'>{dates || 'N/A'}</p>
                </div>
              )
            }) : (
              <p className='font-inter font-medium text-[12px] text-[#191C25]'>N/A</p>
            )}
          </div>
        </>
      </div>
    </Box>
  );
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#FF007A' : '#308fe8',
    },
  }));
  return (
    <div>
      <React.Fragment key={anchor || 'left'}>
        <CustomDrawer
          anchor={anchor}
          open={open}
          onClose={onCloseDrawer}
          sx={{ margin: 0 }}
          classes={{ root: '!p-0 !m-0' }}
        >
          {list(anchor)}
        </CustomDrawer>
      </React.Fragment>
    </div>
  );
}